exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css"), "");
// Module
exports.push([module.id, ".src-components-Tutorial-Tutorial__overlay--1Y8gh{position:absolute;top:0;bottom:0;left:0;right:0;z-index:3}.src-components-Tutorial-Tutorial__content--3eO5F{background-color:#fff;padding:18rem}.src-components-Tutorial-Tutorial__screen--74AbO{position:absolute;width:240rem}.src-components-Tutorial-Tutorial__screenOne--1xhlq{right:290rem;bottom:25rem}.src-components-Tutorial-Tutorial__screen--74AbO:after{content:\"\";position:absolute;width:0;height:0}.src-components-Tutorial-Tutorial__screenOne--1xhlq:after{right:-19rem;bottom:20rem;border-top:20rem solid transparent;border-bottom:20rem solid transparent;border-left:20rem solid #fff}.src-components-Tutorial-Tutorial__content--3eO5F p:last-child{margin-bottom:0}.src-components-Tutorial-Tutorial__checkboxWrap--j45ac{margin-top:40rem}.src-components-Tutorial-Tutorial__checkbox--2FdBe{position:relative;top:1rem;margin-top:20rem}.src-components-Tutorial-Tutorial__checkbox--2FdBe:before{content:\"\";display:inline-block;width:15rem;height:15rem;border:2rem solid #000;cursor:pointer;vertical-align:bottom;margin-right:8rem}input[type=checkbox]:checked~.src-components-Tutorial-Tutorial__checkbox--2FdBe:after{content:\"\\2714\";position:absolute;left:4rem;top:0}.src-components-Tutorial-Tutorial__hideCheckbox--17_7o{position:absolute;opacity:0}.src-components-Tutorial-Tutorial__buttonWrap--X1TaQ{float:right}.src-components-Tutorial-Tutorial__button--3fjbH{margin-top:20rem}", ""]);
// Exports
exports.locals = {
	"buttonPrimary": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["buttonPrimary"] + "",
	"overlay": "src-components-Tutorial-Tutorial__overlay--1Y8gh",
	"content": "src-components-Tutorial-Tutorial__content--3eO5F",
	"screen": "src-components-Tutorial-Tutorial__screen--74AbO",
	"screenOne": "src-components-Tutorial-Tutorial__screenOne--1xhlq",
	"checkboxWrap": "src-components-Tutorial-Tutorial__checkboxWrap--j45ac",
	"checkbox": "src-components-Tutorial-Tutorial__checkbox--2FdBe",
	"hideCheckbox": "src-components-Tutorial-Tutorial__hideCheckbox--17_7o",
	"buttonWrap": "src-components-Tutorial-Tutorial__buttonWrap--X1TaQ",
	"button": "src-components-Tutorial-Tutorial__button--3fjbH " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["buttonPrimary"] + ""
};