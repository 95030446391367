// @flow
import { combineReducers } from 'redux';
import { reducer as login, LOGOUT } from './login';
import { reducer as project } from './project';
import { reducer as game } from './game';
import { reducer as questionOverview } from './questionOverview';
import { reducer as progress } from './progress';

const reducer = combineReducers({
    login,
    project,
    game,
    questionOverview,
    progress
});

export default (state: any, action: any) => {
    if(action.type === LOGOUT) {
        state = undefined;
    }

    return reducer(state, action);
};