//@flow

// state def

export type BalloonState = {
    showNoteBalloon: boolean,
    showChallengeBalloon: boolean
};

const defaultState: BalloonState = {
    showNoteBalloon: false,
    showChallengeBalloon: false
};

// actions

export const SHOW_NOTES = 'SHOW_NOTES';
export const SHOW_CHALLENGES = 'SHOW_CHALLENGES';

// reducer

export const reducer = (state: BalloonState = defaultState, action: any) => {
    switch(action.type) {
        case SHOW_NOTES:
        case SHOW_CHALLENGES:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

// action creators

export const showNoteBalloon = (show: boolean = true) => ({
    type: SHOW_NOTES,
    payload: {
        showNoteBalloon: show
    }
});

export const showChallengeBalloon = (show: boolean = true) => ({
    type: SHOW_CHALLENGES,
    payload: {
        showChallengeBalloon: show
    }
});
