// @flow

import React, { Component } from 'react';
import styles from './GameOptions.css';
import { connect } from 'react-redux';
import { saveProject, duplicateProject, cleanUp } from '../../redux/project';
import { closeDomain, openTutorial } from '../../redux/game';
import type { Project, ProjectState } from '../../redux/project';
import type { Domain } from '../../types';
import { logout } from '../../redux/login';
import { browserHistory } from 'react-router';
import Game from '../Game/Game';
import { exportUrl, pdfUrl } from '../../constants/apiUrls';
import { Motion, spring } from 'react-motion';

type GameOptionsProps = {
    onSave: (project: Project) => void,
    onDuplicate: Function,
    onLogout: Function,
    onLeave: Function,
    project: Project,
    projectState: ProjectState,
    demoMode: boolean,
    onReport: () => void,
    onFingerprint: () => void,
};

const exportData = (project: Project) => {

    if(!project.content.answers){
        return '';
    }

    const data: Array<Domain> = project.toolContent.data;

    const hasChallenges = (answer) => !!answer.challenges;

    //const getImplicationValue = (challenge, key) => challenge.implications[key] ? challenge.implications[key].explanation : '';
    const getImplicationValue = (challenge, key) => challenge.implications[key] ? 'x' : '';

    const getColumns = (domain, question, domainIndex, questionIndex, answer, challenge) => {
        return [
            domain.domain,
            questionIndex+1,
            question.name,
            answer.name,
            challenge.challenge,
            getImplicationValue(challenge, answer.implications[0].title),
            getImplicationValue(challenge, answer.implications[1].title),
            getImplicationValue(challenge, answer.implications[2].title),
            getImplicationValue(challenge, answer.implications[3].title),
            getImplicationValue(challenge, answer.implications[4].title),
            getImplicationValue(challenge, answer.implications[5].title),
            getImplicationValue(challenge, answer.implications[6].title),
            getImplicationValue(challenge, answer.implications[7].title),
            getImplicationValue(challenge, answer.implications[8].title),
            getImplicationValue(challenge, answer.implications[9].title)
        ]
    };

    const isSelectedAnswer = (domainIndex, questionIndex, answerIndex) => {
        return project.content.answers[domainIndex][questionIndex] === answerIndex;
    };

    const getChallenges = (rows, domain, domainIndex) =>
        rows.concat(domain.options.reduce((rows, question, questionIndex) => (
            rows.concat(question.answers
                .filter((answer, answerIndex) => isSelectedAnswer(domainIndex, questionIndex, answerIndex))
                .filter(hasChallenges)
                .reduce((rows, answer) => (
                    rows.concat(answer.challenges.map(
                        challenge => getColumns(domain, question, domainIndex, questionIndex, answer, challenge)
                    ))
                ), [])
            )
        ), []));

    const getAnswer = (question, domainIndex, questionIndex) => {
        return project.content && project.content.answers[domainIndex][questionIndex] > -1 ? question.answers[project.content.answers[domainIndex][questionIndex]] : null;
    };

    let exportData = [
        {
            "trialName": project.title,
            "identifier": project.identifier,
            "acronym": project.acronym,
            "email_contact_person": project.email_contact_person,
            "name_contact_person": project.name_contact_person,
            "hypothesis": project.hypothesis,
            "team_members": project.team_members,
            "prerequisites": project.prerequisites,
            "protocol_version_number":project.protocol_version_number,
            "sponsor": project.sponsor,
        },
        data.map((domain, domainIndex) => (
            domain.options.map((question, questionIndex) => {
                let answer = getAnswer(question, domainIndex, questionIndex);

                const noteLabels = {
                    1: 'Note',
                    2: 'Action'
                };

                return [
                    domain.domain,
                    questionIndex+1,
                    question.name,
                    answer ? answer.name : '',
                    answer ? answer.implications[0].implication : '',
                    answer ? answer.implications[1].implication : '',
                    answer ? answer.implications[2].implication : '',
                    answer ? answer.implications[3].implication : '',
                    answer ? answer.implications[4].implication : '',
                    answer ? answer.implications[5].implication : '',
                    answer ? answer.implications[6].implication : '',
                    answer ? answer.implications[7].implication : '',
                    answer ? answer.implications[8].implication : '',
                    answer ? answer.implications[9].implication : '',
                    answer && answer.challenges ? 'x' : '',
                    project.content && project.content.notes && project.content.notes[domainIndex] && project.content.notes[domainIndex][questionIndex] && project.content.notes[domainIndex][questionIndex].label !== undefined ? noteLabels[project.content.notes[domainIndex][questionIndex].label]+ ': ' + project.content.notes[domainIndex][questionIndex].note : ''
                ]
            })
        )),
        data.reduce(getChallenges, [])
    ];

    return JSON.stringify(exportData);
};

class GameOptions extends Component {
    static OPTION_HEIGHT = 40;
    static MOTION_SPRING = {stiffness: 240, damping: 20};

    state = {
        // saveOptionsOpen: false,
        // exitOptionsOpen: false
        isOpen: [] // maps open state to options by index
    };

    props: GameOptionsProps;

    toggleOpen(index) {
        const isOpen = [...this.state.isOpen];
        isOpen[index] = !this.state.isOpen[index];
        this.setState({isOpen});
    }

    render(){
        let curY = 0;

        const options = [this.props.demoMode ?
            {
                label: 'CANNOT SAVE IN DEMOMODUS',
                className: styles.demoButton
            } :
            {
                label: 'SAVE',
                children: [{
                    label: 'SAVE',
                    onClick: () => {this.props.onSave(this.props.project); this.toggleOpen(0)}
                }, {
                    label: 'SAVE AS DUPLICATE',
                    onClick: () => {this.props.onDuplicate(this.props.project); this.toggleOpen(0)}
                }]
            },
            this.props.demoMode ? {
                label: 'EXIT',
                children: [{
                    label: 'LEAVE DEMO',
                    onClick: () => this.props.onLeaveDemo()
                }]
            } : {
            label: 'EXIT',
            children: [{
                label: 'LEAVE TRIAL DESIGN',
                onClick: () => this.props.onLeave(this.props.projectState.isDirty)
            }, {
                label: 'LOGOUT',
                onClick: () => this.props.onLogout(this.props.projectState.isDirty)
            }]
        }, {
            label: 'EXPORT',
            children: [{
                label: 'EXCEL',
                onClick: () => {
                    // fill data
                    this.refs.reportFormData.value = exportData(this.props.project);
                    this.refs.reportForm.submit();
                    if (this.props.onReport) {
                        this.props.onReport();
                    }
                }
            }
                ,{
                    label: 'FINGERPRINT',
                    onClick: () => {
                        // fill data
                        this.refs.pdfFormData.value = exportData(this.props.project);
                        this.refs.pdfForm.submit();
                        if (this.props.onFingerprint) {
                            this.props.onFingerprint();
                        }
                    }
                }
            ]
        }];

        return (

            <div className={this.props.demoMode ? styles.demoMode : undefined}>
                <form action={exportUrl()} method="post" target="_blank" ref="reportForm">
                    <input name="token" type="hidden" value='token' />
                    <input name="data" type="hidden" ref="reportFormData" />
                </form>

                <form action={pdfUrl()} method="post" target="_blank" ref="pdfForm">
                    <input name="token" type="hidden" value='token' />
                    <input name="data" type="hidden" ref="pdfFormData" />
                </form>

                <ul className={styles.options}>
                    {options.map((option, index) => {
                        const y = curY;
                        curY += GameOptions.OPTION_HEIGHT;
                        const style = {
                            y: spring(y, GameOptions.MOTION_SPRING)
                        };

                        return (
                            [
                                <Motion style={style} key={index}>
                                    {({y}) => {
                                        const enabled = !! option.children;
                                        let clickHandler = undefined;
                                        if (enabled) {
                                            clickHandler = option.onClick ? () => option.onClick() : () => this.toggleOpen(index);
                                        }
                                        return (
                                        <li
                                            className={(this.state.isOpen[index] ? styles.active : '') + ' ' + styles.button + (option.className ? ' ' +  option.className : '') + (enabled ? '' : ' ' + styles.disabled)}
                                            onClick={clickHandler}
                                            style={{
                                                transform: `translate3d(0, ${y}rem, 0)`,
                                                zIndex: 1
                                            }}
                                        >
                                            <p>{option.label}</p>
                                        </li>
                                    )}}
                                </Motion>,

                                option.children && (
                                    <li>
                                        <ul className={styles.children}>
                                            {option.children.map((option, childIndex) => {
                                                const shouldRender = !!this.state.isOpen[index];
                                                const y = curY - (shouldRender ? 0 : GameOptions.OPTION_HEIGHT);
                                                const style = {
                                                    y: spring(y, GameOptions.MOTION_SPRING),
                                                    opacity: spring(shouldRender ? 1 : 0)
                                                };

                                                if(shouldRender) {
                                                    curY += GameOptions.OPTION_HEIGHT;
                                                }

                                                return (
                                                    <Motion style={style} key={childIndex}>
                                                        {({y, opacity}) => (
                                                            <li
                                                                className={styles.button}
                                                                onClick={() => option.onClick()}
                                                                style={{
                                                                    transform: `translate3d(0, ${y}rem, 0)`,
                                                                    opacity: opacity
                                                                }}
                                                            >
                                                                <p>{option.label}</p>
                                                            </li>
                                                        )}
                                                    </Motion>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                )
                            ]
                        )
                    })}
                </ul>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    projectState: state.project
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (project: Project) => {
            dispatch(saveProject(project));
        },
        onDuplicate: (project: Project) => {
            dispatch(duplicateProject(project, true));
        },
        onLeave: (isDirty: boolean) => {
            if(!isDirty || confirm(Game.LEAVE_CONFIRM_MESSAGE)) {
                dispatch(openTutorial());
                dispatch(cleanUp());
                dispatch(closeDomain());
                setTimeout(() => {
                    browserHistory.push('/projects');
                });
            }
        },
        onLeaveDemo: () => {
            dispatch(openTutorial());
            dispatch(cleanUp());
            dispatch(closeDomain());
            setTimeout(() => {
                browserHistory.push('/');
                location.reload();
            });
        },
        onLogout: (isDirty: boolean) => {
            if(!isDirty || confirm(Game.LEAVE_CONFIRM_MESSAGE)) {
                dispatch(openTutorial());
                dispatch(cleanUp());
                dispatch(closeDomain());
                setTimeout(() => {
                    dispatch(logout());
                    browserHistory.push('/')
                });
            }
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GameOptions);