exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../MainContentView/MainContentView.css"), "");
// Module
exports.push([module.id, ".src-components-Terminology-Terminology__container--7UTFz,.src-components-Terminology-Terminology__content--14tKJ{height:100%}.src-components-Terminology-Terminology__content--14tKJ{background:#fff;padding:18rem}.src-components-Terminology-Terminology__content--14tKJ,.src-components-Terminology-Terminology__header--2VqvH{box-sizing:border-box}.src-components-Terminology-Terminology__header--2VqvH{background-color:#000;overflow:auto;margin-bottom:2rem;padding-left:18rem;position:absolute;top:0;left:0;height:36rem;width:100%;line-height:36rem;color:#fff}.src-components-Terminology-Terminology__title--1PUgv{margin:0}.src-components-Terminology-Terminology__subtitle--23j-3{margin-top:20rem;margin-bottom:5rem}.src-components-Terminology-Terminology__smallsubtitle--3Tpep{margin-top:0;margin-bottom:5rem}.src-components-Terminology-Terminology__textblock--32Vgi p{border-bottom:1px solid #ccc;margin-right:32rem;padding-bottom:4rem}.src-components-Terminology-Terminology__tag--1V-pD{display:inline-block;background:#000;color:#fff;padding-left:4rem;padding-right:4rem;margin-top:8rem;margin-bottom:5rem}.src-components-Terminology-Terminology__tag--1V-pD+p{margin-top:5rem}.src-components-Terminology-Terminology__col--FYPl1{width:50%;float:left;box-sizing:border-box;padding-right:50rem}", ""]);
// Exports
exports.locals = {
	"contentBlock": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../MainContentView/MainContentView.css").locals["contentBlock"] + "",
	"container": "src-components-Terminology-Terminology__container--7UTFz",
	"content": "src-components-Terminology-Terminology__content--14tKJ",
	"header": "src-components-Terminology-Terminology__header--2VqvH",
	"title": "src-components-Terminology-Terminology__title--1PUgv",
	"subtitle": "src-components-Terminology-Terminology__subtitle--23j-3",
	"smallsubtitle": "src-components-Terminology-Terminology__smallsubtitle--3Tpep",
	"textblock": "src-components-Terminology-Terminology__textblock--32Vgi",
	"tag": "src-components-Terminology-Terminology__tag--1V-pD",
	"col": "src-components-Terminology-Terminology__col--FYPl1"
};