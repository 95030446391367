// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { Domain } from '../../types';
import type { Project } from '../../redux/project';
import { selectQuestion, closeDomain, closeNote } from '../../redux/game';
import type { GameState } from '../../redux/game';
import { ImplicationsSmall, Notes, DomainNavigation } from '../';
import styles from './QuestionOverview.css';
import { Icon } from '../';
import challengeIcon from '../../assets/operational_challenge_icon.svg';
import { highlightChallenges } from '../../redux/questionOverview';

type QuestionOverviewProps = {
    onEnter: () => void, //initialize state
    onSelect: (questionIndex: number) => void,
    onClose: Function,
    onChallengeHoverStateChange: (isHover: boolean) => void,
    project: Project,
    gameState: GameState
};

const hasChallenge = (data: Array<Domain>, project: Project, selectedDomain: number, selectedQuestion: number) => {
    return project.content.answers[selectedDomain][selectedQuestion] !== -1 && data[selectedDomain].options[selectedQuestion].answers[project.content.answers[selectedDomain][selectedQuestion]].challenges;
};

class QuestionOverview extends Component {
    props: QuestionOverviewProps;

    componentWillMount() {
        this.props.onEnter();
    }

    shouldComponentUpdate(nextProps) {
        //skip update when domain gets deselected. This component should not be rendered but might still be visible in
        //leave animation.
        return nextProps.gameState.selectedDomainIndex !== -1;
    }

    render() {
        const props = this.props;
        const { gameState } = props;
        const data = props.project.toolContent.data;

        if(gameState.selectedDomainIndex === -1) {
            return null;
        }

        const domain = data[gameState.selectedDomainIndex],
            selectedDomain = gameState.selectedDomainIndex,
            selectedImplication = gameState.selectedImplicationIndex;

        const questionTitles = {
            'Participant': 'Participant selection, recruitment and attrition',
            'Site': 'Site selection and recruitment',
            'Comparator': 'Randomisation, comparator choice and treatment strategies',
            'Outcome': 'Outcome selection and measurement',
            'Data': 'Data collection',
            'Safety': 'Safety monitoring',
            'Quality': 'Monitoring of trial conduct and data quality',
        };

        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    {questionTitles[domain.domain]}
                    {selectedImplication > -1 && (
                        <div className={styles.implication}>
                            {data[0].options[0].answers[0].implications[selectedImplication].title}
                        </div>
                    )}
                </div>
                {domain.options.map((question, index) =>
                    <div key={index} className={styles.row}>
                        <div className={styles.questionRow}>
                            <div className={styles.block}>{index + 1}</div>
                            <div className={styles.question} onClick={() => props.onSelect(index)}>{question.name}</div>
                            <div className={styles.iconCell}>
                                {hasChallenge(data, props.project, selectedDomain, index) && (
                                    <div
                                        onMouseEnter={() => props.onChallengeHoverStateChange(true)}
                                        onMouseLeave={() => props.onChallengeHoverStateChange(false)}
                                    >
                                        <Icon glyph={challengeIcon} height={36} width={26} className={styles.challengeIcon} />
                                    </div>
                                )}
                            </div>
                            <div className={styles.iconCell}>
                                {props.project.content.notes[selectedDomain][index] !== -1 && props.project.content.notes[selectedDomain][index].note !== '' && (
                                    <Notes project={props.project} domainIndex={selectedDomain} questionIndex={index} label={false} />
                                )}
                            </div>
                            <div className={styles.panel}>
                                <ImplicationsSmall answer={question.answers[props.project.content.answers[selectedDomain][index]]} />
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.filler}></div>
                <DomainNavigation project={props.project} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    gameState: state.game
});

const mapDispatchToProps = dispatch => ({
    onEnter: () => dispatch(closeNote()), //this component uses Notes, but notes may never be open inside this component.
    onSelect: (questionIndex: number) => {
        dispatch(selectQuestion(questionIndex));
        dispatch(closeNote());
    },
    onClose: (project: Project) => {
        dispatch(closeDomain());
        dispatch(closeNote());
    },
    onChallengeHoverStateChange: (isHover: boolean) => dispatch(highlightChallenges(isHover))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionOverview);