// @flow

import React, { Component } from 'react';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';
import { Provider } from 'react-redux';
import { configureStore } from '../store';
import { Login, ProjectOverview, Secure, Projects, CreateProject, Game } from './';
import { request } from '../services';
import Demo from "./Demo/Demo";
import {setShowSmallScreenDetected, showSmallScreenDetected} from "../redux/game";
import styles from './App.css';
import connect from "react-redux/lib/components/connect";

const store = configureStore();
request.setStore(store);

browserHistory.listen((location) => {
    gtag('config', GA_MEASUREMENT_ID, {
        anonymize_ip: true,
        page_path: location.pathname,
        send_page_view: true,
        cookie_flags: 'SameSite=None;Secure'
    });
});

class ScreenSizeWarningImpl extends Component {
    render(): React$Node {
        console.log('render', this.props.showSmallScreenDetected);
        if (this.props.showSmallScreenDetected) {
            return <div className={styles.smallScreenWrap}>

                <div className={styles.dialogContent}>
                    <h2 className={styles.screentitle}>Notice</h2>
                    <p>
                        This tool has been designed for larger screens. Use can be suboptimal on a smaller screen.
                    </p>

                    <div className={styles.buttonWrap}>
                        <button onClick={() => store.dispatch(setShowSmallScreenDetected(false))} className={styles.button}>Close</button>
                    </div>
                    <div style={{clear: 'both'}}></div>
                </div>
            </div>;
        } else {
            return null;
        }
    }
}

const ScreenSizeWarning = connect(function (state) {
    return { showSmallScreenDetected: state.game.showSmallScreenDetected }
})(ScreenSizeWarningImpl)

class App extends Component {
    static DEFAULT_VIEW_WIDTH = 1280;
    static DEFAULT_VIEW_HEIGHT = 760;

    componentWillMount() {
        window.addEventListener('resize', this.updateAppScale);
        this.updateAppScale();
        const MIN_SIZE = 600;
        console.log(window.innerWidth, window.innerHeight);
        if (window.innerHeight < MIN_SIZE || window.innerWidth < MIN_SIZE) {
            store.dispatch(setShowSmallScreenDetected(true))
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateAppScale);
    }

    updateAppScale() {
        //scale the app to fit inside the window (contained)
        const heightRatio = window.innerHeight / App.DEFAULT_VIEW_HEIGHT;
        const widthRatio = window.innerWidth / App.DEFAULT_VIEW_WIDTH;

        document.documentElement.style.fontSize = Math.min(heightRatio, widthRatio) + 'px';
    }

    checkAuth(replace: Function) {
        if(!store.getState().login.authToken) {
            replace('/');
        }
    }

    goToIndex(replace: Function) {
        if(store.getState().login.authToken) {
            replace('/projects');
        }
    }

    render() {
        return (
            <Provider store={store}>
                <div>
                    <Router history={browserHistory}>
                        <Route path="demo" component={Demo} />
                        <Route path="login" onEnter={(nextState, replace) => this.goToIndex(replace)} component={Login} />
                        <Route onEnter={(nextState, replace) => this.checkAuth(replace)} component={Secure}>
                            <Route path="projects" component={Projects}>
                                <IndexRoute component={ProjectOverview} />
                                <Route path="create" component={CreateProject} />
                            </Route>
                            <Route path="game/:projectId" component={Game} />
                        </Route>
                    </Router>
                    <ScreenSizeWarning />
                </div>
            </Provider>
        );
    }
}

export default App;
