// @flow

import React, { Component } from 'react';
import styles from './ProjectOptions.css';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { deleteProject, getProjectAndDuplicate } from '../../../redux/project';
import type { Project } from '../../../redux/project';
import { Icon } from '../../';
import dots from '../../../assets/wit_icon_drie_dots.svg';


type ProjectOptionsProps = {
    onDelete: (project: Project) => void,
    onDuplicate: Function,
    start: Function,
    project: Project
};

class ProjectOptions extends Component {

    state = {
        isOpen: false
    };

    props: ProjectOptionsProps;

    render(){
        return (
            <div className={styles.options}>
                {this.state.isOpen && (
                    <div style={{display: 'flex'}}>
                        {/*<Link to={'/projects/edit/' + this.props.project.id} className={styles.buttonSecondary}>*/}
                            {/*Edit*/}
                        {/*</Link>*/}
                        <button className={styles.buttonSecondary} onClick={() => this.props.onDelete(this.props.project)}>Remove</button>
                        <button className={styles.buttonSecondary} onClick={() => {this.props.onDuplicate(this.props.project); this.setState({isOpen: false})}}>Duplicate</button>
                        <Link className={styles.buttonPrimary} to={'/game/'+this.props.project.id}>Open</Link>
                    </div>
                )}
                <button
                    className={this.state.isOpen ? styles.expandButtonOpen : styles.expandButtonClosed}
                    onClick={() => this.setState({isOpen: !this.state.isOpen})}>
                    <Icon glyph={dots} width={36} height={36} />
                </button>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onDelete: (project: Project) => {
            if(confirm(`Are you sure you want to delete project "${project.title}"`)) {
                dispatch(deleteProject(project.id));
            }
        },
        onDuplicate: (project: Project) => {
            dispatch(getProjectAndDuplicate(project, false));
        },
        start: () => {}
    }
};

export default connect(null, mapDispatchToProps)(ProjectOptions);