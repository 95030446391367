//@flow

// state def

export type QuestionOverviewState = {
    highlightChallenges: boolean
};

const defaultState: QuestionOverviewState = {
    highlightChallenges: false
};

// actions

export const HIGHLIGHT_CHALLENGES = 'HIGHLIGHT_CHALLENGES';

// reducer

export const reducer = (state: QuestionOverviewState = defaultState, action: any) => {
    switch(action.type) {
        case HIGHLIGHT_CHALLENGES:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

// action creators

export const highlightChallenges = (highlight: boolean = true) => ({
    type: HIGHLIGHT_CHALLENGES,
    payload: {
        highlightChallenges: highlight
    }
});
