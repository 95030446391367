// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import type {Domain, Question} from '../../types';
import type { Project } from '../../redux/project';
import { NavigationSlider, ImplicationLabels, Notes } from '../';
import AnswerRow from './AnswerRow';
import { closeQuestion, closeImplication, closeDomain, selectQuestion, closeChallenge, closeNote } from '../../redux/game';
import navigationSliderStyles from '../NavigationSlider/NavigationSlider.css';
import styles from './AnswerSelection.css';
import { Icon } from '../';
import arrowLeft from '../../assets/wit_icon_pijl_links.svg';
import infoIcon from '../../assets/info_url.svg';
import { selectAnswer } from '../../redux/project';

type AnswerSelectionProps = {
    domains: Array<Domain>,
    onBack: (implicationIndex: number) => void,
    selectedAnswerIndex: number,
    domainIndex: number,
    questionIndex: number,
    answerIndex: number,
    implicationIndex: number,
    showChallenges: number,
    project: Project,
    onSelectToggle: (project: Project, domainIndex: number, questionIndex: number, answerIndex: number) => void,
    onClose: () => void,
    selectQuestion: (questionIndex: number) => void,
    // numberOfQuestions: number
};

class AnswerSelection extends Component {
    props: AnswerSelectionProps;

    shouldComponentUpdate(nextProps: AnswerSelectionProps) {
        //skip update when domain gets deselected. This component should not be rendered but might still be visible in
        //leave animation.
        return nextProps.domainIndex !== -1;
    }

    render() {
        const props = this.props;
        const data = props.domains;

        const question: Question = data[props.domainIndex].options[props.questionIndex];
        const numberOfQuestions = props.domainIndex !== -1 ? data[props.domainIndex].options.length : -1;
        const implications = data[0].options[0].answers[0].implications;

        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <button className={navigationSliderStyles.button} onClick={() => props.onBack(props.implicationIndex)}>
                        <Icon glyph={arrowLeft} height={36} width={36} />
                    </button>
                    <div className={styles.abcTitle}>{props.questionIndex + 1}</div>
                    <span className={styles.title}>{question.name}</span>
                    {!!question.info &&
                        <a className={styles.infoUrl}
                           href={question.info} target="_blank">
                            <Icon glyph={infoIcon} height={18} width={18} />
                        </a>
                    }
                    <div className={styles.addNote}>
                        <Notes project={props.project} domainIndex={props.domainIndex} questionIndex={props.questionIndex} label={true} />
                    </div>
                </div>

                <div>
                    <div className={styles.implicationsTable}>
                        <div className={styles.checkBoxHeader}></div>
                        <div className={styles.abcHeader}></div>
                        <div className={styles.answerHeader}></div>
                        <div className={styles.implicationsHeader}>
                            <ImplicationLabels implications={implications}/>
                        </div>
                    </div>

                    <div style={{position: 'relative'}}>
                        {props.project.content.answers && question.answers.map((answer, index) => (
                            props.answerIndex < 0 || props.answerIndex === index) && (
                                <AnswerRow
                                    key={index}
                                    index={index}
                                    answer={answer}
                                    showChallenges={props.showChallenges && question.answers[props.answerIndex] === answer}
                                    isSelected={props.selectedAnswerIndex === index}
                                    onSelectToggle={(prevValue) => props.onSelectToggle(props.project, props.domainIndex, props.questionIndex, !prevValue ? index: -1)}
                                />
                            )
                        )}
                    </div>

                </div>

                <div className={styles.filler}></div>

                <NavigationSlider
                    selectIndex={(questionIndex) => props.selectQuestion(questionIndex) }
                    onClose={() => props.onClose(props.project)}
                    numberOfItems={numberOfQuestions}
                    itemIndex={props.questionIndex}
                />

            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    domainIndex: state.game.selectedDomainIndex,
    implicationIndex: state.game.selectedImplicationIndex,
    showChallenges: state.game.showChallenges,
    questionIndex: state.game.selectedQuestionIndex,
    answerIndex: state.game.selectedAnswerIndex,
    selectedAnswerIndex: state.game.selectedDomainIndex !== -1 ?
        ownProps.project.content.answers[state.game.selectedDomainIndex][state.game.selectedQuestionIndex] :
        -1
});

const mapDispatchToProps = dispatch => ({
    onBack: (implicationIndex: number) => {
        if(implicationIndex > -1){
            dispatch(closeImplication());
        } else {
            dispatch(closeQuestion());
        }
    },
    onSelectToggle: (project: Project, domainIndex: number, questionIndex: number, answerIndex: number) => {
        dispatch(selectAnswer(project, domainIndex, questionIndex, answerIndex));
    },
    selectQuestion: (questionIndex: number) => {
        dispatch(closeNote());
        dispatch(closeChallenge());
        dispatch(selectQuestion(questionIndex));
    },
    onClose: (project: Project) => {
        dispatch(closeNote());
        dispatch(closeDomain());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AnswerSelection);