// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { Project } from '../../redux/project';
import { hideTutorial } from '../../redux/project';
import classnames from 'classnames';
import styles from './Tutorial.css';
import { closeTutorial, setHideTutorial } from '../../redux/game';

type TutorialProps = {
    onClose: () => void,
    onSelectToggle: () => void,
    project: Project,
    isHideTutorial: boolean
};

const mapStateToProps = state => ({
    isHideTutorial: state.game.hideTutorial
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClose: (isHideTutorial: boolean) => {
        if(isHideTutorial){
            dispatch(hideTutorial(ownProps.project));
        }
        dispatch(closeTutorial());
    },
    onSelectToggle: (isSelected: boolean) => {
        dispatch(setHideTutorial(isSelected));
    },
});


const Tutorial = (props: TutorialProps) => (
    <div className={styles.overlay}>

        <section className={classnames(styles.screen, styles.content, styles.screenOne)}>
            <h2 className={styles.screentitle}>Design your trial</h2>
            <p>Start by selecting a domain.</p>

            <div className={styles.buttonWrap}>
                <button onClick={() => props.onClose(props.isHideTutorial)} className={styles.button}>Close</button>
            </div>

            <div className={styles.checkboxWrap}>
                <input
                    type="checkbox"
                    name="disable_tutorial"
                    id="disable_tutorial"
                    className={styles.hideCheckbox}
                    checked={props.isHideTutorial}
                    onChange={() => props.onSelectToggle(!props.isHideTutorial)}
                />
                <label className={styles.checkbox} htmlFor={'disable_tutorial'}>
                    Don't show again
                </label>
            </div>
        </section>
    </div>
);


export default connect(mapStateToProps, mapDispatchToProps)(Tutorial);