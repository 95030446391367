//@flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import R from 'ramda';
import type { LoginState } from '../../redux/login';
import { login } from '../../redux/login';
import { Spinner, TextInput } from '../';
import { registerUrl, resetUrl } from '../../constants/apiUrls';
import styles from './Login.css';
import projectsStyles from '../Projects/Projects.css';
import styleGuide from '../../styleGuide.css';
import Feedback from '../Feedback/Feedback';

type LoginProps = {
    onSubmit: Function,
    loginState: LoginState
};

const getErrorMessage = statusCode => {
    switch (statusCode) {
        case 401:
            return `Login failed.`;
        case 400:
            return `Login failed: incorrect login credentials.`;
        case -1:
            return `Login failed: can't connect to server.`;
        default:
            return `Login failed. Please try again. (code: ${statusCode})`;
    }
};

class Login extends Component {
    props: LoginProps;
    state = {
        email: '',
        password: '',
        showInputError: false
    };

    onSubmit(event: Event) {
        event.preventDefault();
        //object -> boolean
        const hasEmptyFields = R.compose(R.any(R.isEmpty), R.values, R.pick(['email', 'password']));

        if(hasEmptyFields(this.state)) {
            this.setState({showInputError: true});
        } else {
            this.setState({showInputError: false});
            this.props.onSubmit(this.state.email, this.state.password);
        }
    }

    render() {
        const { loginState } = this.props;

        return (
            <div>
                <div className={projectsStyles.header}>
                    <div className={projectsStyles.headerContent}>
                        {/*<Icon glyph={logo} height={60} width={370} />*/}
                        {/*<div className={projectsStyles.headerText}>*/}
                        {/*    <br />*/}
                        {/*    DECISION SUPPORT TOOL<br />*/}
                        {/*    FOR PRAGMATIC TRIAL DESIGN*/}
                        {/*</div>*/}
                        GetReal Trial Tool - Navigating RWE options in clinical trials
                    </div>
                </div>

                <form onSubmit={event => this.onSubmit(event)} className={projectsStyles.wrap}>
                    <div className={styles.titleBlock}>
                        <h1>Login</h1>
                    </div>

                    <div className={styles.formBlock}>
                        <div className={styles.row}>
                            <div className={styles.leftColumn}>
                                <TextInput value={this.state.email} onChange={event => this.setState({email: event.target.value})} placeholder="E-mail address or username" />
                                <TextInput value={this.state.password} type="password" onChange={event => this.setState({password: event.target.value})} placeholder="Password" />
                                <a href={resetUrl()}>Forgot password?</a>
                                <br />
                                <a href="https://www.getreal-initiative.eu/pragmagic-disclaimer-and-privacy/" target="_new">Disclaimer &amp; privacy</a>
                            </div>
                            <div className={styles.column}>
                                <div className={styles.columnBody}></div>
                                <div className={styles.formFooter}>
                                    <div className={styles.errorMessage}>
                                        {[0, 200].indexOf(loginState.statusCode) === -1 && (
                                            <div>{getErrorMessage(loginState.statusCode)}</div>
                                        )}

                                        {this.state.showInputError && (
                                            <div>Please enter all fields</div>
                                        )}
                                    </div>
                                    <div className={styles.buttons}>
                                        {loginState.isFetching && (
                                            <div className={styles.spinner}><Spinner /></div>
                                        )}
                                        {/* registration disabled during pilot. Also check routing config in back-end when re-enabling */}
                                        <a href={registerUrl()} className={styles.registerButton}>Register</a>
                                        <button disabled={loginState.isFetching} className={styleGuide.buttonPrimary}>Log in</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Feedback />
            </div>

        );
    }
}

const mapStateToProps = state => ({
    loginState: state.login
});

const mapDispatchToProps = dispatch => ({
    onSubmit: (username: string, password: string) => dispatch(login(username, password))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);