import React, { Component } from 'react';
import { connect} from 'react-redux';
import { Link } from 'react-router';

class Secure extends Component {

    render() {
        return this.props.authToken ? this.props.children : <div>Login required <Link to="/login">Login</Link></div>;
    }
}

const mapStateToProps = state => ({
    authToken: state.login.authToken
});

export default connect(mapStateToProps)(Secure);