import React from 'react';
import { connect } from 'react-redux';
import type { Answer, Implication } from '../../types';
import navigationSliderStyles from '../NavigationSlider/NavigationSlider.css';
import styles from './AnswerSelection.css';
import { Implications } from '../';
import { Icon } from '../';
import challengeIcon from '../../assets/operational_challenge_icon_wit_full.svg';
import Sources from '../Sources/Sources';

type AnswerRowProps = {
    index: number,
    answer: Answer,
    isSelected: boolean,
    showChallenges: boolean,
    selectedImplicationIndex: number,
    onSelectToggle: () => void,
    style?: any
};

const AnswerRow = (props: AnswerRowProps) => {
    const { index, answer, showChallenges, isSelected } = props;
    const selectedImplication: Implication = props.selectedImplicationIndex > -1 ?
        answer.implications[props.selectedImplicationIndex] : null;

    return (
        <div style={props.style}>

            <div className={styles.tableRow} style={{position: 'relative', zIndex: 1}}>
                <div className={navigationSliderStyles.button + ' ' +styles.checkBox}>
                    <input
                        disabled={showChallenges}
                        checked={isSelected}
                        type="radio"
                        name="answer"
                        value={index}
                        // onChange={() => {console.log('onChange');}}
                        onClick={() => {props.onSelectToggle(isSelected)}}
                        id={'toggle-'+index}
                    />
                    <label className={styles.checkbox} htmlFor={'toggle-'+index}> </label>
                </div>
                <div className={styles.abc}>
                    {String.fromCharCode(65 + index)}
                </div>
                <div className={styles.answer}>
                    {answer.name}
                </div>
                <Implications answer={answer} answerIndex={index} />

            </div>

            {selectedImplication && answer.challenges && showChallenges && (
                <div className={styles.challenges}>
                    <div className={styles.challengeHeaderTable}>
                        <div className={styles.checkBoxHeaderTop}></div>
                        <div className={styles.challengeHeader1Top}>
                            <Icon glyph={challengeIcon} height={36} width={26} />
                            A challenge for trial execution may be:
                        </div>
                        <div className={styles.challengeHeader2Top}>For <span style={{textTransform: 'lowercase'}}>{selectedImplication.title}</span> this implies:</div>
                    </div>

                    {answer.challenges.map((challenge, index) => (
                        <div key={index} className={styles.challengeRow}>
                            <div className={styles.checkBoxHeader}></div>

                            <div className={index === 0 ? styles.challengeTopShadow : styles.challenge}>
                                <span className={styles.challengeNumber}>{index+1}. </span>
                                <span className={styles.challengeText}>
                                    {challenge.challenge}
                                </span>
                            </div>
                            <div className={index === 0 ? styles.explanationTopShadow : styles.explanation}>
                                {challenge.implications[selectedImplication.title] ?
                                    <div>
                                        {challenge.implications[selectedImplication.title].explanation}
                                        <Sources sources={challenge.implications[selectedImplication.title].source} />
                                    </div> :
                                    <div>No impact expected.</div>
                                }
                            </div>
                        </div>
                    ))}

                    <div className={styles.bottomTable}>
                        <div></div>
                    </div>
                </div>
            )}
        </div>
    );
};

AnswerRow.DEFAULT_HEIGHT = 76;

const mapStateToProps = state => ({
    domainIndex: state.game.selectedDomainIndex,
    selectedImplicationIndex: state.game.selectedImplicationIndex
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AnswerRow);