import React from 'react';
import styles from './ProgressCircle.css';

type ProgressCircleProps = {
    /**
     * 0 - 1
     */
    progress: number,
    style?: Object,
};

const progressRadius = 22.5;
const progressCircumference = 2 * Math.PI * progressRadius;

const getDashOffset = (circumference, progress) => {
    const offset = -1 * circumference - Math.min(progress, 99) * circumference;
    //Safari fix: offset needs to get round down on zero progress
    //Otherwise round down to nearest integer
    return progress === 0 ? Math.floor(offset) : Math.ceil(offset);
};

const ProgressCircle = (props: ProgressCircleProps) => {

    return (
        <svg version="1.1" width="82rem" height="82rem" viewBox="0 0 70.9 72.7">
            <g>
                {/*achtergrond cirkel*/}
                {/*<circle style={props.style} className={styles.circle} cx="35.5" cy="36.3" r="31.6"/>*/}

                <circle
                    className={styles.circleBg}
                    cx="35.5"
                    cy="36.3"
                    r={progressRadius}
                />

                <circle className={styles.innerCircle} cx="35.5" cy="36.3" r="24.4" />
                <circle className={styles.innerCircle} cx="35.5" cy="36.3" r="20.6" />

                <circle
                    className={styles.progressFill}
                    cx="35.5"
                    cy="36.3"
                    r={progressRadius}
                    strokeDasharray={progressCircumference}
                    strokeDashoffset={getDashOffset(progressCircumference, props.progress)}
                />
            </g>
        </svg>
    );
};

export default ProgressCircle;