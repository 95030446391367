// @flow

import React, { Component } from 'react';
import type { Project, ProjectState } from '../../../redux/project';
import { createProject } from '../../../redux/project';
import { connect } from 'react-redux';
import {Link} from 'react-router';
import moment from 'moment';
import R from 'ramda';
import { Spinner, TextInput } from '../../';
import type { Domain } from '../../../types';
import styles from './CreateProject.css';
import projectsStyles from '../Projects.css';
import styleGuide from '../../../styleGuide.css';
import Feedback from '../../Feedback/Feedback';
import { loadLatestToolContent } from '../../../redux/project';

type CreateProjectProps = {
    onSubmit: (project: Project) => void,
    projectState: ProjectState,
    dispatch: Function,
};

const getErrorMessage = statusCode => {
    switch (statusCode) {
        case -1:
            return `Can't connect to server. Check your connection and try again.`;
        default:
            return `Failed creating project. Please try again. (code: ${statusCode})`;
    }
};

export const createContentArray = (data: Array<Domain>) => {
    return data.map(domain => (
        domain.options.map(() => -1)
    ));
};

class CreateProject extends Component {
    props: CreateProjectProps;

    state = {
        title: '',
        acronym: '',
        identifier: '',
        protocol_version_number: '',
        hypothesis: '',
        prerequisites: '',
        sponsor: '',
        name_contact_person: '',
        email_contact_person: '',
        team_members: '',
        showInputError: '',
    };

    componentWillMount() {
        this.loadLatestToolContent();
    }
    loadLatestToolContent() {
        this.props.dispatch(loadLatestToolContent());
    }
    onSubmit(event: Event) {
        const hasEmptyRequiredFields = R.compose(R.any(R.isEmpty), R.values, R.pick(['title', 'acronym', 'protocol_version_number']));
        event.preventDefault();

        if(hasEmptyRequiredFields(this.state)) {
            this.setState({showInputError: true});
        } else {
            const project: Project = {
                id: 0,
                title: this.state.title,
                acronym: this.state.acronym,
                identifier: this.state.identifier,
                protocol_version_number: this.state.protocol_version_number,
                hypothesis: this.state.hypothesis,
                prerequisites: this.state.prerequisites,
                sponsor: this.state.sponsor,
                name_contact_person: this.state.name_contact_person,
                email_contact_person: this.state.email_contact_person,
                team_members: this.state.team_members,
                date: moment(),
                content: {
                    answers: createContentArray(this.props.projectState.latestToolContent.data),
                    notes: createContentArray(this.props.projectState.latestToolContent.data)
                },
                toolContent: this.props.projectState.latestToolContent.id
            };
            this.setState({showInputError: false});
            this.props.onSubmit(project);
        }
    }

    render() {
        const { projectState } = this.props;

        return (
            <form onSubmit={event => this.onSubmit(event)} className={projectsStyles.wrap}>
                <div className={styles.titleBlock}>
                    <h1>Trial information</h1>
                </div>
                <div className={styles.formBlock}>
                    <div className={styles.row}>
                        <div className={styles.leftColumn}>
                            <h2>Trial description</h2>
                            <TextInput onChange={event => this.setState({title: event.target.value})} placeholder="Trial name *" />
                            <TextInput onChange={event => this.setState({acronym: event.target.value})} placeholder="Trial acronym *" />
                            <TextInput onChange={event => this.setState({identifier: event.target.value})} placeholder="Trial identifier" />
                            <TextInput onChange={event => this.setState({protocol_version_number: event.target.value})} placeholder="Trial protocol version number *" />
                            <TextInput multiLine onChange={event => this.setState({hypothesis: event.target.value})} placeholder="Trial hypothesis/summary" />
                            <TextInput multiLine onChange={event => this.setState({prerequisites: event.target.value})} placeholder="Notes/prerequisites" />
                            <p>* Required field</p>
                        </div>
                        <div className={styles.column}>
                            <h2>Contact information</h2>
                            <TextInput onChange={event => this.setState({sponsor: event.target.value})} placeholder="Sponsor" />
                            <TextInput onChange={event => this.setState({name_contact_person: event.target.value})} placeholder="Name contact person" />
                            <TextInput onChange={event => this.setState({email_contact_person: event.target.value})} placeholder="E-mail address contact person" />
                            <TextInput multiLine onChange={event => this.setState({team_members: event.target.value})} placeholder="Team members" />

                            <div className={styles.toolContentVersion}>tool template version: {projectState.latestToolContent ? projectState.latestToolContent.versionLabel : '...'}</div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <div className={styles.columnBody}></div>
                            <div className={styles.formFooter}>
                                <div className={styles.errorMessage}>
                                    {[0, 200].indexOf(projectState.statusCode) === -1 && (
                                        <div>{getErrorMessage(projectState.statusCode)}</div>
                                    )}

                                    {this.state.showInputError && (
                                        <div>Please enter all required fields</div>
                                    )}
                                </div>
                                <div className={styles.buttons}>
                                    {projectState.isFetching && (
                                        <div className={styles.spinner}><Spinner /></div>
                                    )}
                                    <Link to="/projects" className={styles.cancelButton}>Cancel</Link>
                                    <button disabled={projectState.isFetching} className={styleGuide.buttonPrimary}>Start</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Feedback />
            </form>
        );
    }
}

const mapStateToProps = state => ({
    projectState: state.project
});

const mapDispatchToProps = dispatch => ({
    onSubmit: (project: Project) => { dispatch(createProject(project)) },
    dispatch: dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);