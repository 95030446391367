import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './redux/rootReducer';
import thunk from 'redux-thunk';
import {
    OPEN_TERMINOLOGY,
    OPEN_TRIAL_INFO,
    SELECT_DOMAIN,
    SELECT_IMPLICATION,
    SELECT_QUESTION,
    SHOW_CHALLENGES
} from "./redux/game";
import {CLEAN_UP, REQUEST_PROJECT, SELECT_ANSWER} from "./redux/project";
import {LOGOUT} from "./redux/login";

/**
 * Initialize the redux store and configure hot reloading of reducers.
 * This function should be called only once.
 * @returns {object} Store
 */
export function configureStore() {
    let middleware = [thunk];
    if(process.env.NODE_ENV === 'development') {
        // https://github.com/leoasis/redux-immutable-state-invariant#why
        // why a require call? so it doesn't get packaged with the project on production
        middleware.push(require('redux-immutable-state-invariant')());
    }
    if (GA_MEASUREMENT_ID) {
        const googleAnalytics = store => next => action => {
            // console.log('googleAnalytics', action);
            let analyticsPath = null;
            const currentState = store.getState();
            switch (action.type) {
                case OPEN_TRIAL_INFO:
                    analyticsPath = '/trialinfo';
                    break;
                case OPEN_TERMINOLOGY:
                    analyticsPath = '/terminology';
                    break;
                case SELECT_DOMAIN:
                    analyticsPath = '/domain_' + (action.payload.selectedDomainIndex + 1);
                    break;
                case SELECT_QUESTION:
                    analyticsPath = '/domain_' + (currentState.game.selectedDomainIndex + 1) + '/question_' + (action.payload.selectedQuestionIndex + 1);
                    break;
                case SELECT_ANSWER:
                    analyticsPath = '/domain_' + (action.domainIndex + 1) + '/question_' + (action.questionIndex + 1) + '/answer_' + (action.answerIndex + 1);
                    break;
                case SELECT_IMPLICATION:
                    analyticsPath = '/domain_' + (currentState.game.selectedDomainIndex + 1) + '/question_' + (currentState.game.selectedQuestionIndex + 1) + '/answer_' + (currentState.game.selectedAnswerIndex + 1) + '/implication_' + (action.payload.selectedImplicationIndex + 1);
                    break;
                case SHOW_CHALLENGES:
                    analyticsPath = '/domain_' + (currentState.game.selectedDomainIndex + 1) + '/question_' + (currentState.game.selectedQuestionIndex + 1) + '/answer_' + (currentState.game.selectedAnswerIndex + 1) + '/implication_' + (currentState.game.selectedImplicationIndex + 1) + '/challenges';
                    break;
                case REQUEST_PROJECT:
                    analyticsPath = '/request_save';
                    break;
                case LOGOUT:
                    analyticsPath = '/request_logout';
                    break;
                case CLEAN_UP:
                    analyticsPath = '/request_close';
                    break;
            }
            if (analyticsPath) {
                gtag('config', GA_MEASUREMENT_ID, {
                    'page_path': location.pathname + analyticsPath,
                    'send_page_view': true
                });
            }
            // hand the action down
            return next(action);
        }
        middleware.push(googleAnalytics);
    } else {
        console.error('no Google Analytics detected!');
    }

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));

    if (module.hot) {
        module.hot.accept(() => {
            const getRootReducer = require('./redux/rootReducer').default;
            store.replaceReducer(getRootReducer());
        });
    }

    return store;
}