exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css"), "");
// Module
exports.push([module.id, ".src-components-Icon-IconNoteButton__background--3GM4T{fill:" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["primaryColorMedium"] + "}.src-components-Icon-IconNoteButton__svg--3Yr2A:hover .src-components-Icon-IconNoteButton__background--3GM4T{fill:" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["primaryColorLight"] + "}", ""]);
// Exports
exports.locals = {
	"primaryColorMedium": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["primaryColorMedium"] + "",
	"primaryColorLight": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["primaryColorLight"] + "",
	"background": "src-components-Icon-IconNoteButton__background--3GM4T",
	"svg": "src-components-Icon-IconNoteButton__svg--3Yr2A"
};