// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
//import R from 'ramda';
import type { Project } from '../../redux/project';
import styles from './City.css';

type CityProps = {
    project: Project,
    selectedDomainIndex: number,
    dispatch: Function,
    onClick: () => void,
};

const contentLoaded = (project: Project): boolean => !!(project.content && project.content.answers);

//const shuffleList = R.sort(() => Math.min(1, Math.max(-1, Math.round(Math.random() * 3 - 1.5))));

// Fisher-Yates (aka Knuth) Shuffle
// https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
function shuffle(array, randomFn) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    var result = array.concat([]);

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(randomFn() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = result[currentIndex];
        result[currentIndex] = result[randomIndex];
        result[randomIndex] = temporaryValue;
    }

    return result;
}
// Pseudo Random using Linear Congruential Generator
// context: http://softwareengineering.stackexchange.com/questions/260969/original-source-of-seed-9301-49297-233280-random-algorithm
function createSeededRandomGenerator(seedInput) {
    var seed = seedInput;
    return function () {
        seed = (seed * 9301 + 49297) % 233280;
        return seed / 233280;
    }
}

const getTotalProgress = (project: Project) => {
    let answered = 0;
    let total = 0;

    for(let domain in project.content.answers) {
        if(project.content.answers.hasOwnProperty(domain)) {
            for(let question in project.content.answers[domain]) {
                if(project.content.answers[domain].hasOwnProperty(question)) {
                    if(project.content.answers[domain][question] > -1){
                        answered ++;
                    }
                    total ++;
                }
            }
        }
    }

    return (answered/total)*100;
};

const randomSeed1 = 434; // int!

const getWindowElements = (containerId: string) =>
    shuffle(Array.prototype.slice.call(document.querySelectorAll(`#${containerId} > *`)), createSeededRandomGenerator(randomSeed1));

const removeClass = (className: string, curClassName: string): string => curClassName.replace(className, '');

const addClass = (className: string, curClassName: string): string => removeClass(className, curClassName) + ' ' + className;

const updateWindows = (elementList, numberOn, cssClass) => {
    elementList.forEach((element, index) => {
        if(index < numberOn) {
            setTimeout(() => element.setAttribute('class', addClass(cssClass, element.getAttribute('class') || '')), Math.random() * 1300 + 300);
        } else {
            element.setAttribute('class', removeClass(cssClass, element.getAttribute('class') || ''));
        }
    });
};

class City extends Component {
    props: CityProps;
    state = {
        progress: 0
    };

    blockSize = 17;
    gutterSize = 8;

    blocksLayout = [
        [3, 41],
        [2, 42],
        [1, 43],
        [0, 44],
        [1, 45],
        [2, 46],
        [3, 47],
        [4, 48],
        [5, 49],
        [6, 50],

        [7, 51],
        [8, 51],
        [9, 50],
        [10, 49],
        [11, 48],
        [12, 47],
        [13, 47],
        [14, 48],
        [15, 49],
        [16, 50],

        [17, 51],
        [18, 52],
    ];
    blockGridStart = {x: -10, y: 70};
    blocks = [];
    randoms = [];

    timer1 = null;
    timer2 = null;
    timer3 = null;


    componentDidMount() {
        for (var i = 0; i < this.blocksLayout.length; i++) {
            for (var j = this.blocksLayout[i][0]; j < this.blocksLayout[i][1]; j++) {
                this.blocks.push({
                    key: j + '_' + i,
                    j: j,
                    i: i,
                })
            }
        }

        //
        // compute randoms
        //
        this.randoms = shuffle(Array.from(Array(this.blocks.length).keys()), createSeededRandomGenerator(randomSeed1));
        this.updateIllumination();
    }

    componentWillReceiveProps(nextProps: CityProps) {
        //update city when domain window closes
        //update city when project has loaded

        if(
            (nextProps.selectedDomainIndex === -1 && this.props.selectedDomainIndex !== -1) ||
            (!contentLoaded(this.props.project) && contentLoaded(nextProps.project))
        ) {
            this.updateIllumination();
        }

    }

    componentWillUnmount() {
        if (this.timer1) {
            clearTimeout(this.timer1);
            this.timer1 = null;
        }
        if (this.timer2) {
            clearTimeout(this.timer2);
            this.timer2 = null;
        }
        if (this.timer3) {
            clearTimeout(this.timer3);
            this.timer3 = null;
        }
    }

    updateIllumination() {
        // console.log('updating')
        const newProgress = getTotalProgress(this.props.project);
        const currentProgress = this.state.progress;
        const progresDelta = newProgress - currentProgress;
        this.timer1 = setTimeout(() => {this.setState({progress: currentProgress + (progresDelta / 3)});}, 300);
        this.timer2 = setTimeout(() => {this.setState({progress: currentProgress + (progresDelta * 2 / 3)});}, 600);
        this.timer3 = setTimeout(() => {this.setState({progress: currentProgress + progresDelta});}, 900);
    }

    render() {
        return (
            <div className={styles.city} onClick={(e) => {
                if (this.props.onClick) {
                    this.props.onClick(e)
                }
            }}>
                <svg viewBox="0 0 1280 760" className={styles.ignoreMouse}>
                    <style>
                        {`
                            .square {
                                /* fill:rgba(255,255,255,0.1); */
                                fill: transparent
                            }
                            .filled {
                                stroke-width:0;
                                fill:#FFFF94
                            }
                        `}
                    </style>
                    {
                        this.blocks.map((blockConfig, idx) => (<rect key={idx} width={this.blockSize} height={this.blockSize} transform='rotate(45)'
                                                                className={'square' + (this.randoms[idx] < (this.state.progress / 100) * this.randoms.length ? ' filled' : '')}
                                                                x={this.blockGridStart.x + (blockConfig.j * (this.blockSize + this.gutterSize))}
                                                                     y={this.blockGridStart.y + (-blockConfig.i * (this.blockSize + this.gutterSize))}/>))
                    }
                </svg>
                {
                    this.state.progress === 0 ?
                        <div className={styles.beforeProgressContainer + ' ' + styles.ignoreMouse} >
                            <div className={styles.beforeProgressLabel + ' ' + styles.ignoreMouse}>navigate your trial design</div>
                        </div>
                        :
                        null
                }
            </div>
        );
    };
}

const mapStateToProps = state => ({
    selectedDomainIndex: state.game.selectedDomainIndex
});

export default connect(mapStateToProps)(City);