// @flow
/* eslint no-console: 0 */

import { browserHistory } from 'react-router';
import { logout } from '../redux/login';

const __DEV__ = (process.env.NODE_ENV === 'development');

export type HandledResponse = {
    response: Response,
    body: Object
};

/**
 * The Request service is a wrapper around fetch that also handles things like
 * - setting cookies from the request
 * - parsing JSON
 *
 * !! Requires store to be set to be able to perform logout on auth fail. Will result in error if not set. :(
 */
class Request {
    static CODE_AUTH_FAILED = 401;

    authToken: string = '';
    store: any = null;

    setAuthToken(token: string) {
        this.authToken = token;
    }

    setStore(store: any) {
        this.store = store;
    }

    /**
     * Get the headers based on request properties, adds:
     * - Content Type json if a body is set
     * - Authorization if auth token is set
     * @param requestProperties
     * @returns {{}}
     */
    getHeaders(requestProperties: any) {
        const headers = requestProperties.headers || {};

        if ("body" in requestProperties) {
            headers['Content-Type'] = 'application/json';
        }

        if(this.authToken) {
            headers.Authorization = "Bearer " + this.authToken;
        }

        return headers;
    }

    /**
     * Execute a request. If idToken is set in the login state, it will be sent along in the request header. Body object
     * will be converted to JSON.
     *
     * @param url
     * @param requestProperties
     * @param logoutOnAuthFail
     * @returns {Promise<{}>} Resolves with response, response body (json parsed, if present).
     * @throws {Error} If useLoginToken is true, but no token is set.
     */
    execute(url: string, requestProperties: any = {}, logoutOnAuthFail: bool = true): Promise<HandledResponse> {
        if (__DEV__) {
            console.log('Executing request: ', url, requestProperties, logoutOnAuthFail);
        }

        requestProperties.headers = this.getHeaders(requestProperties);

        if(typeof requestProperties.body !== "string") {
            requestProperties.body = JSON.stringify(requestProperties.body);
        }

        return new Promise((resolve, reject) => {

            const onRequestSuccess = response => {
                if (__DEV__) {
                    console.log('Request successful: ' + url);
                }

                if(response.status === 204 || response.headers.get('content-length') === 0) {
                    //204: no content
                    resolve({
                        response,
                        body: {}
                    });
                } else {
                    response.json()
                        .then(
                            body => resolve({
                                response,
                                body
                            }),
                            () => reject({
                                response,
                                body: {}
                            })
                        );
                }
            };

            const onRequestError = response => {
                if (__DEV__) {
                    console.log(`Request failed: ${url} (${response.status})`);
                }

                // console.log(Request.CODE_AUTH_FAILED);
                //
                if (response.status === Request.CODE_AUTH_FAILED && logoutOnAuthFail) {
                //     //login needed
                    this.store.dispatch(logout());
                    browserHistory.push('/'); // TODO change to /login?
                } else {
                    //reject with api response
                    try {
                        response.json()
                            .then(
                                body => reject({
                                    response,
                                    body
                                }),
                                () => reject({
                                    response,
                                    body: {}
                                })
                            );
                    } catch(e) {
                        if(__DEV__) {
                            console.log(e);
                        }

                        reject({
                            response,
                            body: {}
                        });
                    }
                }
            };

            fetch(url, requestProperties).then(response => {
                if (response.ok) {
                    onRequestSuccess(response);
                } else {
                    onRequestError(response);
                }
            }, function(){
                onRequestError({status: 401});
            });
        });
    }
}

export default new Request();
