import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './base.css';
import { App } from './components/';
import "babel-polyfill";

ReactDOM.render((
    <App />
), document.getElementById('root'));
