// @flow

import React, { Component } from 'react';
import type { Project, ProjectState } from '../../redux/project';
import { saveProject } from '../../redux/project';
import { connect } from 'react-redux';
import R from 'ramda';
import { Spinner, TextInput } from '../';
import styles from '../Projects/CreateProject/CreateProject.css';
import projectsStyles from '../Projects/Projects.css';
import styleGuide from '../../styleGuide.css';
import { closeTrialInfo } from '../../redux/game';

type CreateProjectProps = {
    projectState: ProjectState,
    project: Project,
    onSave: (project: Project) => void,
    onClose: () => void,
    demoMode: boolean,
};

const getErrorMessage = statusCode => {
    switch (statusCode) {
        case -1:
            return `Can't connect to server. Check your connection and try again.`;
        default:
            return `Failed creating project. Please try again. (code: ${statusCode})`;
    }
};

const mapStateToProps = state => ({
    projectState: state.project
});

const mapDispatchToProps = dispatch => ({
    onSave: project => {
        console.log(project);
        dispatch(saveProject(project))
    },
    onClose: () => dispatch(closeTrialInfo())
});

class TrialInfo extends Component {
    props: CreateProjectProps;

    constructor(props): void {
        super(props);
        this.state = {
            title: props.project.title || '',
            acronym: props.project.acronym || '',
            identifier: props.project.identifier || '',
            protocol_version_number: props.project.protocol_version_number || '',
            hypothesis: props.project.hypothesis || '',
            prerequisites: props.project.prerequisites || '',
            sponsor: props.project.sponsor || '',
            name_contact_person: props.project.name_contact_person || '',
            email_contact_person: props.project.email_contact_person || '',
            team_members: props.project.team_members || '',
            showInputError: false,
        };
    }

    onSubmit(event: Event) {
        event.preventDefault();
        this.save();
    }

    save() {
        const hasEmptyRequiredFields = R.compose(R.any(R.isEmpty), R.values, R.pick(['title', 'acronym', 'protocol_version_number']));

        if(hasEmptyRequiredFields(this.state)) {
            this.setState({showInputError: true});
        } else {
            const project = this.props.project;
            const data = R.pick(Object.keys(project), this.state);

            this.setState({showInputError: false});
            this.props.onSave({...project, ...data});
        }
    }

    render() {
        const { projectState } = this.props;

        return (
            projectState.isFetching ? (
                <Spinner className={styles.spinner} />
            ) : [0, 200].indexOf(projectState.statusCode) === -1 ? (
                <div>
                    Error saving project.
                    <button onClick={() => this.save()}>Try again</button>
                </div>
            ) : this.props.project ? (
                <form onSubmit={event => this.onSubmit(event)} className={projectsStyles.wrap}>
                    <div className={styles.titleBlock}>
                        <h1>Trial information</h1>
                    </div>
                    <div className={styles.formBlock}>
                        <div className={styles.row} style={{height: '420rem'}}>
                            <div className={styles.leftColumn}>
                                <h2>Trial description</h2>
                                <TextInput value={this.state.title} onChange={event => this.setState({title: event.target.value})} placeholder="Trial name *" />
                                <TextInput value={this.state.acronym} onChange={event => this.setState({acronym: event.target.value})} placeholder="Trial acronym *" />
                                <TextInput value={this.state.identifier} onChange={event => this.setState({identifier: event.target.value})} placeholder="Trial identifier" />
                                <TextInput value={this.state.protocol_version_number} onChange={event => this.setState({protocol_version_number: event.target.value})} placeholder="Trial protocol version number *" />
                                <TextInput value={this.state.hypothesis} multiLine onChange={event => this.setState({hypothesis: event.target.value})} placeholder="Trial hypothesis/summary" />
                                <TextInput value={this.state.prerequisites} multiLine onChange={event => this.setState({prerequisites: event.target.value})} placeholder="Notes/prerequisites" />
                                <p>* Required field</p>
                            </div>
                            <div className={styles.column}>
                                <h2>Contact information</h2>
                                <TextInput value={this.state.sponsor} onChange={event => this.setState({sponsor: event.target.value})} placeholder="Sponsor" />
                                <TextInput value={this.state.name_contact_person} onChange={event => this.setState({name_contact_person: event.target.value})} placeholder="Name contact person" />
                                <TextInput value={this.state.email_contact_person} onChange={event => this.setState({email_contact_person: event.target.value})} placeholder="E-mail address contact person" />
                                <TextInput value={this.state.team_members} multiLine onChange={event => this.setState({team_members: event.target.value})} placeholder="Team members" />

                                <div className={styles.toolContentVersion}>tool template version: {this.props.project.toolContent ? this.props.project.toolContent.versionLabel : '...'}</div>
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.column}>
                                <div className={styles.columnBody}></div>
                                <div className={styles.formFooter}>
                                    <div className={styles.errorMessage}>
                                        {[0, 200].indexOf(projectState.statusCode) === -1 && (
                                            <div>{getErrorMessage(projectState.statusCode)}</div>
                                        )}

                                        {this.state.showInputError && (
                                            <div>Please enter all required fields</div>
                                        )}
                                    </div>
                                    <div className={styles.buttons}>
                                        {projectState.isFetching && (
                                            <div className={styles.spinner}><Spinner /></div>
                                        )}
                                        <button className={styles.cancelButton} onClick={e => {e.preventDefault(); this.props.onClose();}}>Cancel</button>
                                        {
                                            this.props.demoMode === true ?
                                                <button disabled={projectState.isFetching} className={styleGuide.buttonPrimary} disabled onClick={e => {e.preventDefault();}}>Cannot save in demo mode</button>
                                                :
                                                <button disabled={projectState.isFetching} className={styleGuide.buttonPrimary}>Save</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            ) : null
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialInfo);