exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../node_modules/postcss-loader/src/index.js!../styleGuide.css"), "");
// Module
exports.push([module.id, ".src-components-App__smallScreenWrap--2v7i6{position:absolute;top:0;width:100%;height:100%;background-color:rgba(0,0,0,.3);z-index:100;display:-webkit-box;display:flex;-webkit-box-pack:center;justify-content:center;-webkit-box-align:center;align-items:center}.src-components-App__buttonWrap--1KLPi{float:right}.src-components-App__button--mqW_U{margin-top:20rem;height:58rem;line-height:58rem}.src-components-App__dialogContent--2Pobw{position:relative;background-color:#fff;padding:32rem;font-size:32rem;width:500rem}.src-components-App__dialogContent--2Pobw h2{font-size:48rem}", ""]);
// Exports
exports.locals = {
	"buttonPrimary": "" + require("-!../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../node_modules/postcss-loader/src/index.js!../styleGuide.css").locals["buttonPrimary"] + "",
	"smallScreenWrap": "src-components-App__smallScreenWrap--2v7i6",
	"buttonWrap": "src-components-App__buttonWrap--1KLPi",
	"button": "src-components-App__button--mqW_U " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../node_modules/postcss-loader/src/index.js!../styleGuide.css").locals["buttonPrimary"] + "",
	"dialogContent": "src-components-App__dialogContent--2Pobw"
};