// @flow

import React  from 'react';
import styles from './Backdrop.css';

type MainContentViewProps = {
    children?: React.Element<any>,
    style?: any
};

const MainContentView = (props: MainContentViewProps) => (
    <div className={styles.backdrop} style={props.style}>
        {props.children}
    </div>
);

export default MainContentView;