exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css"), "");
// Module
exports.push([module.id, ".src-components-ProjectView-ProjectView__game--1g987{width:1280rem;height:760rem;-o-object-fit:contain;object-fit:contain;overflow:hidden;position:relative;background-color:#495edf;margin:auto}.src-components-ProjectView-ProjectView__panelHeading--2N9M4{background-color:" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["gameBackgroundColor"] + "}.src-components-ProjectView-ProjectView__panelBody--2W7j7{background-color:#3b2d44}.src-components-ProjectView-ProjectView__background--2YsLL{background-color:transparent;position:absolute;width:100%}.src-components-ProjectView-ProjectView__spinner--1rVFt{position:absolute;left:calc(50vw - 22rem);top:calc(50vh - 7rem)}.src-components-ProjectView-ProjectView__tutorialOverlay--3jtVM{position:absolute;top:0;bottom:0;left:0;right:0;z-index:1;background-color:rgba(19,17,38,.6)}", ""]);
// Exports
exports.locals = {
	"primaryColorLight": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["primaryColorLight"] + "",
	"gameBackgroundColor": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["gameBackgroundColor"] + "",
	"game": "src-components-ProjectView-ProjectView__game--1g987",
	"panelHeading": "src-components-ProjectView-ProjectView__panelHeading--2N9M4",
	"panelBody": "src-components-ProjectView-ProjectView__panelBody--2W7j7",
	"background": "src-components-ProjectView-ProjectView__background--2YsLL",
	"spinner": "src-components-ProjectView-ProjectView__spinner--1rVFt",
	"tutorialOverlay": "src-components-ProjectView-ProjectView__tutorialOverlay--3jtVM"
};