// @flow

import React  from 'react';
import styles from './MainContentView.css';

type MainContentViewProps = {
    children: React.Element<any>,
    style?: any
};

const MainContentView = (props: MainContentViewProps) => (
    <div className={styles.view} style={props.style}>
        {props.children}
    </div>

);

export default MainContentView;