//@flow

import React, { Component } from 'react';
import R from 'ramda';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import moment from 'moment';
import { Spinner } from '../../';
import { loadProjects } from '../../../redux/project';
import type { ProjectState, Project } from '../../../redux/project';
import { ProjectOptions, Icon } from '../../'
import styles from './ProjectOverview.css';
import plusIcon from '../../../assets/wit_icon_plus.svg';
import introductionDiagram from '../../../assets/introductionDiagram.svg';
import Feedback from '../../Feedback/Feedback';

type ProjectOverviewProps = {
    projectState: ProjectState,
    dispatch: Function
};

const sort = (function(a:Project, b:Project) {
    let nameA = a.title.toUpperCase(); // ignore upper and lowercase
    let nameB = b.title.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
});

class ProjectOverview extends Component {

    props: ProjectOverviewProps;

    componentWillMount() {
        this.props.dispatch(loadProjects());
    }

    render() {
        const { projectState } = this.props;

        return (
            <div className={styles.overview}>
                <div className={styles.introBlock}>
                    <h1>Welcome to the GetReal Trial Tool!</h1>
                    <div className={styles.introText}>
                        <div>
                            <p>The GetReal Trial Tool provides you with step by step guidance in evaluating the decisions and implications of Real World Evidence (RWE) choices in clinical trial designs. <a href="http://www.pragmagic.eu/Disclaimer-and-Privacy" target="_new">Disclaimer &amp; Privacy</a></p>
                            <p>Trials geared towards RWE aim to capture the true effect of a treatment strategy in the real world. As generalisability of trial results to routine clinical practice is considered the key aspect of RWE trials, generalisability plays a central role in this tool.</p>
                            <p>The tool gives insight into the possible consequences of more pragmatic trial design choices and operational challenges on generalisability, risk of bias, precision, stakeholder & ethical acceptability, cost and duration. The GetReal Trial Tool aims to increase the value and feasibility of RWE trials by informing stakeholders when designing and assessing clinical trials with RWE elements.</p>
                            <p>
                                This tool was developed as part of the<br />
                                <a href="https://www.imi-getreal.eu/" target="_blank">IMI GetReal project</a>.
                            </p>
                        </div>
                        <div>
                            <Icon glyph={introductionDiagram} width={395} height={430} className={styles.introImage} />
                        </div>
                    </div>
                </div>

                <div className={styles.header}>
                    <h1 className={styles.title}>Trial</h1>
                    {projectState.isFetching && (
                        <div><Spinner /></div>
                    )}
                    <Link to="/projects/create" className={styles.newProjectButton}>
                        <span>New trial</span>
                        <div className={styles.newProjectIcon}>
                            <Icon glyph={plusIcon} width={34} height={34} />
                        </div>
                    </Link>
                </div>

                {R.sort(sort, projectState.projects).map(project => (
                    <div key={project.id} className={styles.projectRow}>
                        <div className={styles.titleCell}><Link to={`/game/${project.id}`}>{project.acronym || project.title}</Link></div>
                        <div className={styles.dateCell}>{moment(project.date).format('DD-MM-YY')}</div>
                        <div className={styles.versionCell}>{project.toolContent ? `tool template version ${project.toolContent.versionLabel}` : ''}</div>
                        <div className={styles.optionsCell}><ProjectOptions project={project} /></div>
                    </div>
                ))}

                <Feedback />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    projectState: state.project
});

export default connect(mapStateToProps)(ProjectOverview);