//@flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import type { ProjectState, Project } from '../../redux/project';
import type { GameState } from '../../redux/game';
import { loadProject } from '../../redux/project';
import {
    Spinner,
} from '../';
import styles from './Game.css';
import ProjectView from "../ProjectView/ProjectView";

type GameProps = {
    projectState: ProjectState,
    gameState: GameState,
    params: {projectId: string},
    dispatch: Function,
    router: Object,
    route: Object
};

class Game extends Component {
    static LEAVE_CONFIRM_MESSAGE = 'There are unsaved changes. Are you sure you want to leave?';

    props: GameProps;
    project: Project | void;   //store find result for performance reasons

    componentWillMount() {
        this.loadProject();
    }

    componentDidMount() {
        window.onbeforeunload = () => this.confirmLeave();
        this.props.router.setRouteLeaveHook(this.props.route, () => this.confirmLeave());
    }

    confirmLeave() {
        if (this.props.projectState.isDirty) return Game.LEAVE_CONFIRM_MESSAGE;
    }

    loadProject() {
        this.props.dispatch(loadProject(+this.props.params.projectId));
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    render() {
        const { projectState, params } = this.props;

        this.project = projectState.projects.find(project => project.id === +params.projectId);

        return (
            projectState.isFetching && projectState.fetchType === 'get' ? (
                <Spinner className={styles.spinner} />
            ) : [0, 200].indexOf(projectState.statusCode) === -1 && projectState.fetchType === 'get' ? (
                <div>
                    Error loading project.
                    <button onClick={() => this.loadProject()}>Try again</button>
                </div>
            ) : this.project ? (
                <ProjectView project={this.project} demoMode={false}
                             onFingerprint={() => {
                                 if (GA_MEASUREMENT_ID) {
                                     gtag('config', GA_MEASUREMENT_ID, {
                                         'page_path': `/game/${this.project.id}/export_fingerprint`,
                                         'send_page_view': true
                                     });
                                 }
                             }}
                             onReport={() => {
                                 if (GA_MEASUREMENT_ID) {
                                     gtag('config', GA_MEASUREMENT_ID, {
                                         'page_path': `/game/${this.project.id}/export_excel`,
                                         'send_page_view': true
                                     });
                                 }
                             }}
                />
            ) : null
        );
    }
}

const mapStateToProps = state => ({
    projectState: state.project,
    gameState: state.game
});

export default connect(mapStateToProps)(withRouter(Game));