exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css"), "");
// Module
exports.push([module.id, ".src-components-Game-Game__game--2u0mZ{width:1280rem;height:760rem;-o-object-fit:contain;object-fit:contain;overflow:hidden;position:relative;background-color:#495edf}.src-components-Game-Game__panelHeading--1nQFg{background-color:" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["gameBackgroundColor"] + "}.src-components-Game-Game__panelBody--2M8lN{background-color:#3b2d44}.src-components-Game-Game__background--cgebm{background-color:transparent}.src-components-Game-Game__spinner--2VJ4M{position:absolute;left:calc(50vw - 22rem);top:calc(50vh - 7rem)}.src-components-Game-Game__tutorialOverlay--1p8XY{position:absolute;top:0;bottom:0;left:0;right:0;z-index:1;background-color:rgba(19,17,38,.6)}", ""]);
// Exports
exports.locals = {
	"primaryColorLight": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["primaryColorLight"] + "",
	"gameBackgroundColor": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["gameBackgroundColor"] + "",
	"game": "src-components-Game-Game__game--2u0mZ",
	"panelHeading": "src-components-Game-Game__panelHeading--1nQFg",
	"panelBody": "src-components-Game-Game__panelBody--2M8lN",
	"background": "src-components-Game-Game__background--cgebm",
	"spinner": "src-components-Game-Game__spinner--2VJ4M",
	"tutorialOverlay": "src-components-Game-Game__tutorialOverlay--1p8XY"
};