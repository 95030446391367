// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { Project, ProjectState } from '../../redux/project';
import styles from './Progress.css';
import { Icon } from '../';
import notesIcon from '../../assets/Progress_Notes_regular.svg';
import challengesIcon from '../../assets/Progress_operational_challenges_regular.svg';
import happyMessageIcon from '../../assets/happy-message.svg';
import ProgressCircle from './ProgressCircle';
import { showNoteBalloon, showChallengeBalloon } from '../../redux/progress';
import { Motion, spring } from 'react-motion';
import bg from "../../assets/progress_bg.svg";

type ProgressProps = {
    projectState: ProjectState,
    project: Project,
    selectedDomain: number,
    showChallengeBalloon: bool,
    showNoteBalloon: bool,
    onNotesHoverStateChange: (isHover: boolean) => void,
    onChallengesHoverStateChange: (isHover: boolean) => void,
    dispatch: Function,
};

class Progress extends Component {
    props: ProgressProps;

    getDomainChallenges = (project: Project, selectedDomain: number) => {
        const data = project.toolContent.data;
        let numberOfChallenges = 0;
        for(let question in project.content.answers[selectedDomain]){
            if(project.content.answers[selectedDomain].hasOwnProperty(question)){
                const answer = project.content.answers[selectedDomain][question];
                if(answer > -1){
                    const challenges = data[selectedDomain].options[question].answers[answer].challenges ? data[selectedDomain].options[question].answers[answer].challenges : [];
                    numberOfChallenges += challenges.length ? 1 : 0;
                }
            }
        }
        return numberOfChallenges;
    };

    getTotalChallenges = (project: Project, selectedDomain: number) => {
        // if(selectedDomain > -1){
        //     return getDomainChallenges(project, +selectedDomain);
        // }
        let numberOfChallenges = 0;
        for(let domain in project.content.notes){
            if(project.content.notes.hasOwnProperty(domain)){
                numberOfChallenges += this.getDomainChallenges(project, +domain);
            }
        }
        return numberOfChallenges;
    };

    getDomainNotes = (project: Project, selectedDomain: number) => {
        let numberOfNotes = 0;
        for(let question in project.content.notes[selectedDomain]) {
            if (project.content.notes[selectedDomain].hasOwnProperty(question)) {
                if (project.content.notes[selectedDomain][question] !== -1 && project.content.notes[selectedDomain][question].note !== '') {
                    numberOfNotes++;
                }
            }
        }
        return numberOfNotes;
    };

    getTotalNotes = (project: Project, selectedDomain: number) => {
        let numberOfNotes = 0;
        for(let domain in project.content.notes){
            if(project.content.notes.hasOwnProperty(domain)){
                numberOfNotes += this.getDomainNotes(project, +domain);
            }
        }
        return numberOfNotes;
    };

    onNotesHoverStateChange = (isHover: boolean) => this.props.dispatch(showNoteBalloon(isHover));
    onChallengesHoverStateChange = (isHover: boolean) => this.props.dispatch(showChallengeBalloon(isHover));

    getTotalProgress = (project: Project, domainIndex: number) => {
        let answered = 0;
        let total = 0;

        for(let domain in project.content.answers) {
            if(project.content.answers.hasOwnProperty(domain)) {
                for(let question in project.content.answers[domain]) {
                    if(project.content.answers[domain].hasOwnProperty(question)) {
                        if(project.content.answers[domain][question] > -1){
                            answered ++;
                        }
                        total ++;
                    }
                }
            }
        }

        return (answered/total)*100;
    };

    state = {
        progressChanged: 0,
        challengesChanged: 0,
        notesChanged: 0
    };

    componentWillReceiveProps(nextProps){
        const progressChanged = this.getTotalProgress(this.props.project) !== this.getTotalProgress(nextProps.project);
        const challengesChanged = this.getTotalChallenges(this.props.project) !== this.getTotalChallenges(nextProps.project);
        const notesChanged = this.getTotalNotes(this.props.project) !== this.getTotalNotes(nextProps.project);

        this.setState({
            progressChanged: progressChanged,
            challengesChanged: challengesChanged,
            notesChanged: notesChanged
        });

        if(progressChanged || challengesChanged || notesChanged){
            setTimeout(function() {
                this.setState({
                    progressChanged: 0,
                    challengesChanged: 0,
                    notesChanged: 0
                });
            }.bind(this), 100);
        }
    };

    render() {
        const props = this.props;
        const totalProgress = this.getTotalProgress(props.project, props.selectedDomain);
        const isDone = totalProgress === 100;
        const totalChallenges = this.getTotalChallenges(props.project, props.selectedDomain);
        const totalNotes = this.getTotalNotes(props.project, props.selectedDomain);

        return (
            <div className={styles.progressWrap}>
                {/*<Icon glyph={bg} className={styles.bg}/>*/}
            <div className={styles.progress}>
                <Motion
                    style={{
                            greyShade: spring(!this.state.progressChanged ? 128 : 255, {stiffness: 300, damping: 25}),
                        }}
                    onRest={this.handleAnimationEnd}
                >
                    {({greyShade}) => {

                        return (
                            <div className={styles.progressCircle}>
                                <ProgressCircle progress={totalProgress / 100} style={{
                                                stroke: `rgba(${Math.round(greyShade)}, ${Math.round(greyShade)}, ${Math.round(greyShade)}, 1)`
                                            }} />
                                <div className={styles.circleLabel} style={{
                                                color: `rgba(${Math.round(greyShade)}, ${Math.round(greyShade)}, ${Math.round(greyShade)}, 1)`
                                            }}>
                                    Progress <br />
                                    {totalProgress.toFixed(0)}%
                                </div>
                                {/*<Motion defaultStyle={{opacity: 0, scale: 0.5}}*/}
                                {/*        style={{*/}
                                {/*            opacity: spring(isDone ? 1 : 0),*/}
                                {/*            scale: spring(isDone ? 1 : 0.5, {stiffness: 182, damping: 11})*/}
                                {/*        }}>*/}
                                {/*    {({opacity, scale}) =>*/}
                                {/*        <div className={styles.happyMessage}*/}
                                {/*             style={{*/}
                                {/*                 opacity: opacity,*/}
                                {/*                 transform: `scale(${scale})`*/}
                                {/*             }}>*/}
                                {/*            <Icon glyph={happyMessageIcon} width={163} height={59} />*/}
                                {/*        </div>*/}
                                {/*    }*/}
                                {/*</Motion>*/}
                            </div>
                        )
                    }}
                </Motion>

                <div className={styles.iconRow}>
                    <Motion
                        style={{
                            greyShade: spring(!this.state.challengesChanged ? 128 : 255, {stiffness: 300, damping: 25}),
                        }}
                    >
                        {({greyShade}) => {
                            return (
                                <div>
                                    <div
                                        onMouseOver={() => this.onChallengesHoverStateChange(true)}
                                        onMouseLeave={() => this.onChallengesHoverStateChange(false)}
                                    >
                                        <Icon glyph={challengesIcon} width={45} height={40} style={{
                                                fill: `rgba(${Math.round(greyShade)}, ${Math.round(greyShade)}, ${Math.round(greyShade)}, 1)`
                                            }} />
                                        <div className={styles.numberLabelContainer} style={{
                                                color: `rgba(${Math.round(greyShade)}, ${Math.round(greyShade)}, ${Math.round(greyShade)}, 1)`
                                            }}>
                                            <span className={styles.number} style={{
                                                borderColor: `rgba(${Math.round(greyShade)}, ${Math.round(greyShade)}, ${Math.round(greyShade)}, 1)`
                                            }}>{totalChallenges}</span>
                                            <span className={styles.label}>Operational<br /> challenges</span>
                                        </div>
                                    </div>

                                    {props.showChallengeBalloon &&
                                    <div className={styles.balloon}>
                                        You have made {totalChallenges} design choices for which you may encounter operational
                                        challenges. To view these operational challenges, click on the domains in the navigation dock or export the Excel.
                                    </div>
                                    }
                                </div>
                            )
                        }}
                    </Motion>
                    <Motion
                        style={{
                                greyShade: spring(!this.state.notesChanged ? 128 : 255, {stiffness: 300, damping: 25}),
                            }}
                    >
                        {({greyShade}) => {
                            return (
                                <div>
                                    <div
                                        onMouseOver={() => this.onNotesHoverStateChange(true)}
                                        onMouseLeave={() => this.onNotesHoverStateChange(false)}
                                    >
                                        <Icon glyph={notesIcon} width={40} height={40} style={{
                                                stroke: `rgba(${Math.round(greyShade)}, ${Math.round(greyShade)}, ${Math.round(greyShade)}, 1)`
                                            }} />
                                        <div className={styles.numberLabelContainer} style={{
                                                color: `rgba(${Math.round(greyShade)}, ${Math.round(greyShade)}, ${Math.round(greyShade)}, 1)`
                                            }}>
                                            <span className={styles.number} style={{
                                                borderColor: `rgba(${Math.round(greyShade)}, ${Math.round(greyShade)}, ${Math.round(greyShade)}, 1)`
                                            }}>{totalNotes}</span>
                                            Notes
                                        </div>
                                    </div>

                                    {props.showNoteBalloon &&
                                    <div className={styles.balloon}>
                                        To access your notes, click on the domains in the navigation dock.<br />
                                        To create an overview of all your notes export as Excel.
                                    </div>
                                    }
                                </div>
                            )
                        }}
                    </Motion>
                </div>
            </div>
            </div>
        );
    };
};

const mapStateToProps = state => ({
    projectState: state.project,
    selectedDomain: state.game.selectedDomainIndex,
    showNoteBalloon: state.progress.showNoteBalloon,
    showChallengeBalloon: state.progress.showChallengeBalloon,
});

export default connect(mapStateToProps)(Progress);