exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-styleGuide__button--2ApN9{height:36rem;line-height:36rem;padding:0 18rem;box-sizing:border-box;text-decoration:none;display:inline-block;vertical-align:middle}.src-styleGuide__buttonPrimary--1L2tT{background-color:#000000;color:#fff}.src-styleGuide__buttonPrimary--1L2tT:hover{background-color:#cccccc}.src-styleGuide__buttonPrimary--1L2tT:disabled{background-color:#808080;cursor:default}.src-styleGuide__buttonSecondary--3UTNr{background-color:#fff;border:2rem solid #000000;color:#000000}.src-styleGuide__buttonSecondary--3UTNr:hover{color:#cccccc;border-color:#cccccc}.src-styleGuide__iconButton--UGU_i{width:36rem;padding:0;text-align:center}", ""]);
// Exports
exports.locals = {
	"primaryColorLight": "#cccccc",
	"primaryColorMedium": "#000000",
	"primaryColorDark": "#000000",
	"secondaryColor": "#15262a",
	"neutralColor": "#808080",
	"gutterSize": "18rem",
	"fontColorAlmostBlack": "#3c3c3c",
	"fontColorGrey": "#cccccc",
	"interfaceColorGrey1": "#464646",
	"interfaceColorGrey2": "#9D9D9D",
	"interfaceColorBlack": "#000000",
	"lightYellowColor": "#FFFF94",
	"gameBackgroundColor": "#495EDF",
	"colorMostOptimal": "#71CBA5",
	"colorImprovements": "#FF8D1F",
	"colorMoreImprovements": "#E87C8C",
	"colorNoImplications": "#E4E4E4",
	"noteBackground": "#E5E9FF",
	"demoBgDark": "#51B189",
	"demoBgMedium": "#71CBA5",
	"demoBgLight": "#CEF3E3",
	"button": "src-styleGuide__button--2ApN9",
	"buttonPrimary": "src-styleGuide__buttonPrimary--1L2tT src-styleGuide__button--2ApN9",
	"buttonSecondary": "src-styleGuide__buttonSecondary--3UTNr src-styleGuide__button--2ApN9",
	"iconButton": "src-styleGuide__iconButton--UGU_i src-styleGuide__buttonPrimary--1L2tT src-styleGuide__button--2ApN9"
};