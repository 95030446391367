exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css"), "");
// Module
exports.push([module.id, ".src-components-Projects-ProjectOptions-ProjectOptions__options--1RBO_{display:-webkit-box;display:flex;-webkit-box-pack:end;justify-content:flex-end;position:absolute;right:0;top:0}.src-components-Projects-ProjectOptions-ProjectOptions__expandButton--dujzI{font-size:25rem;letter-spacing:1rem;margin-left:2rem}.src-components-Projects-ProjectOptions-ProjectOptions__expandButtonOpen--1zQoX{background:" + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["primaryColorLight"] + "}.src-components-Projects-ProjectOptions-ProjectOptions__expandButtonClosed--qCaMZ{background:" + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["primaryColorMedium"] + "}." + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["buttonPrimary"] + ",." + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["buttonSecondary"] + "{height:28rem;margin:4rem 2rem;width:60rem;padding:0;text-align:center}." + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["buttonPrimary"] + "{line-height:28rem}." + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["buttonSecondary"] + "{line-height:24rem}", ""]);
// Exports
exports.locals = {
	"primaryColorMedium": "" + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["primaryColorMedium"] + "",
	"primaryColorLight": "" + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["primaryColorLight"] + "",
	"iconButton": "" + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["iconButton"] + "",
	"buttonPrimary": "" + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["buttonPrimary"] + "",
	"buttonSecondary": "" + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["buttonSecondary"] + "",
	"options": "src-components-Projects-ProjectOptions-ProjectOptions__options--1RBO_",
	"expandButton": "src-components-Projects-ProjectOptions-ProjectOptions__expandButton--dujzI " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["iconButton"] + "",
	"expandButtonOpen": "src-components-Projects-ProjectOptions-ProjectOptions__expandButtonOpen--1zQoX src-components-Projects-ProjectOptions-ProjectOptions__expandButton--dujzI " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["iconButton"] + "",
	"expandButtonClosed": "src-components-Projects-ProjectOptions-ProjectOptions__expandButtonClosed--qCaMZ src-components-Projects-ProjectOptions-ProjectOptions__expandButton--dujzI " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../../node_modules/postcss-loader/src/index.js!../../../styleGuide.css").locals["iconButton"] + ""
};