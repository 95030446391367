// @flow

import React from 'react';
import { connect } from 'react-redux';
import { closeDomain } from '../../redux/game';
import type { Project } from '../../redux/project';
import styles from './DomainNavigation.css';
import navigationSliderStyles from '../NavigationSlider/NavigationSlider.css';
import { Icon } from '../';
import cross from '../../assets/wit_icon_kruis.svg';

type DomainNavigationProps = {
    domainIndex: number,
    selectDomain: (domainIndex: number) => void,
    onClose: Function,
    numberOfDomains: number,
    project: Project
};

const DomainNavigation = (props: DomainNavigationProps) => (
    <div className={navigationSliderStyles.panelFooting}>
        <button className={navigationSliderStyles.button + ' ' + styles.closeButton} onClick={() => props.onClose(props.project)}>
            <Icon glyph={cross} height={36} width={36} />
        </button>
    </div>
);

const mapStateToProps = state => ({
    domainIndex: state.game.selectedDomainIndex
});

const mapDispatchToProps = dispatch => ({
    onClose: (project: Project) => {
        dispatch(closeDomain(project));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DomainNavigation);