// @flow

import type { Answer } from '../types';

export const hasChallenge = (implicationIndex: number, answer: Answer) => {
    const implication = answer.implications[implicationIndex];

    return implication && answer.challenges && answer.challenges.length > 0 && answer.challenges.find((challenge) =>
        implication.title in challenge.implications
    );
};