// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { Answer } from '../../types';
import { ImplicationBlockSmall } from '../';
import { closeImplication } from '../../redux/game';
import styles from './ImplicationsSmall.css';

type ImplicationsSmallProps = {
    answer: Answer,
    selectImplication: Function,
    close: Function
};

const ImplicationsSmall = (props: ImplicationsSmallProps) => (
    <div className={styles.implications} onMouseLeave={() => props.close()}>
        {props.answer && props.answer.implications.map((implication, index) => (
            <ImplicationBlockSmall key={'i'+index} index={index} answer={props.answer} implication={implication} />
        ))}
    </div>
);

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    close: () => {dispatch(closeImplication())}
});

export default connect(mapStateToProps, mapDispatchToProps)(ImplicationsSmall);