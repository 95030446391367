// @flow

import React from 'react';
import { connect } from 'react-redux';
import styles from './ImplicationBlockSmall.css';
import { selectImplication } from '../../redux/game';
import { windowColors } from '../../constants/colors';
import type { Implication } from '../../types';

type ImplicationBlockSmallProps = {
    implication: Implication,
    selectedImplicationIndex: number,
    index: number,
    selectImplication: Function,
    highlightChallenges: boolean
};

const ImplicationBlockSmall = (props: ImplicationBlockSmallProps) => (
    <div
        onMouseEnter={() => props.selectImplication(props.index)}
        style={{backgroundColor: windowColors[props.implication.implication]}}
        className={styles.implication}>
        {props.selectedImplicationIndex > -1 && props.selectedImplicationIndex !== props.index ? (
            <div className={styles.darken}></div>
        ) :
            <div>
                {props.highlightChallenges && (
                    <div>
                        {props.answer.challenges ? (
                            <div className={styles.challengeIndication}>{props.answer.challenges.map((challenge, index) => (
                                <div key={index}>
                                    {index === 0 && (
                                        <div>
                                            {(props.implication.title in challenge.implications) ? (
                                                <div className={styles.highlight}></div>
                                            ) :
                                                <div className={styles.darken}></div>
                                            }
                                        </div>
                                    )}
                                </div>
                            ))}</div>
                        ) : (
                            <div className={styles.darken}></div>
                        )}
                    </div>
                )}
            </div>
        }
    </div>
);

const mapStateToProps = state => ({
    selectedImplicationIndex: state.game.selectedImplicationIndex,
    highlightChallenges: state.questionOverview.highlightChallenges
});

const mapDispatchToProps = dispatch => ({
    selectImplication: (implicationIndex: number) => {dispatch(selectImplication(implicationIndex))}
});

export default connect(mapStateToProps, mapDispatchToProps)(ImplicationBlockSmall);