exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css"), "");
// Module
exports.push([module.id, ".src-components-NavigationSlider-NavigationSlider__navigationButton--3sknI{background-color:transparent;color:" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["interfaceColorGrey1"] + ";height:36rem;border:none;width:15rem}.src-components-NavigationSlider-NavigationSlider__navigationButton--3sknI:disabled{color:" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["interfaceColorGrey2"] + "!important}.src-components-NavigationSlider-NavigationSlider__closeButton--3Er4L{margin-left:36rem}.src-components-NavigationSlider-NavigationSlider__panelFooting--3ZMYP{position:absolute;width:100%;height:36rem;bottom:0;background-color:#000;text-align:right}.src-components-NavigationSlider-NavigationSlider__button--1Kl_-{background-color:" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["interfaceColorBlack"] + ";color:#fff;height:36rem;min-width:36rem;border:none;vertical-align:top}.src-components-NavigationSlider-NavigationSlider__button--1Kl_-:hover{background-color:" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["primaryColorLight"] + "}.src-components-NavigationSlider-NavigationSlider__button--1Kl_-:disabled{background-color:#231d31;color:grey;cursor:default}.src-components-NavigationSlider-NavigationSlider__controls--27EyT{display:inline-block}.src-components-NavigationSlider-NavigationSlider__leftControls--12uGD{float:left}", ""]);
// Exports
exports.locals = {
	"primaryColorLight": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["primaryColorLight"] + "",
	"interfaceColorGrey1": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["interfaceColorGrey1"] + "",
	"interfaceColorGrey2": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["interfaceColorGrey2"] + "",
	"interfaceColorBlack": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["interfaceColorBlack"] + "",
	"navigationButton": "src-components-NavigationSlider-NavigationSlider__navigationButton--3sknI",
	"closeButton": "src-components-NavigationSlider-NavigationSlider__closeButton--3Er4L",
	"panelFooting": "src-components-NavigationSlider-NavigationSlider__panelFooting--3ZMYP",
	"button": "src-components-NavigationSlider-NavigationSlider__button--1Kl_-",
	"controls": "src-components-NavigationSlider-NavigationSlider__controls--27EyT",
	"leftControls": "src-components-NavigationSlider-NavigationSlider__leftControls--12uGD"
};