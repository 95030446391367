//@flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './Projects.css';
import type { LoginState } from '../../redux/login';
import { logout } from '../../redux/login';
import { browserHistory } from 'react-router';

type ProjectProps = {
    onLogoutClick: () => void,
    loginState: LoginState
};

class Projects extends Component {

    props: ProjectProps;

    render() {
        return (
            <div className={styles.projects}>
                <div className={styles.header}>
                    <div className={styles.headerContent}>
                        {/*<Icon glyph={logo} height={60} width={370} />*/}
                        {/*<div className={styles.headerText}>*/}
                        {/*    <br />*/}
                        {/*    decision support tool<br />*/}
                        {/*    for pragmatic trial design*/}
                        {/*</div>*/}
                    </div>
                    <div className={styles.logout}>
                        <div>{this.props.loginState.email}</div>
                        <button onClick={() => this.props.onLogoutClick()}>logout</button>
                    </div>
                </div>
                <div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loginState: state.login
});

const mapDispatchToProps = (dispatch: Function) => ({
    onLogoutClick: () => {
        dispatch(logout());
        browserHistory.push('/');
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);