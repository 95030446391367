//@flow

import type { Answer, Domain, Question } from '../types';
import { PUT_PROJECTS_SUCCESS } from './project';

// state def

export type GameState = {
    selectedDomainIndex: number,
    selectedQuestionIndex: number,
    showChallenges: boolean,
    selectedAnswerIndex: number,
    hoverImplicationIndex: number,
    noteOpened: number,
    showTerminology: boolean,
    showTrialInfo: boolean,
    selectedScreenIndex: number,
    showTutorial: 0 | 1,
    hideTutorial: 0 | 1
};

const defaultState: GameState = {
    selectedDomainIndex: -1,
    selectedQuestionIndex: -1,
    showChallenges: false,
    selectedAnswerIndex: -1,
    hoverImplicationIndex: -1,
    noteOpened: 0,
    showTerminology: false,
    showTrialInfo: false,
    selectedScreenIndex: 0,
    showTutorial: 1,
    hideTutorial: 0,
    showSmallScreenDetected: false,
};

// reducer

export const SELECT_DOMAIN = 'SELECT_DOMAIN';
export const CLOSE_DOMAIN = 'CLOSE_DOMAIN';
export const SELECT_QUESTION = 'SELECT_QUESTION';
export const CLOSE_QUESTION = 'CLOSE_QUESTION';
export const SELECT_IMPLICATION = 'SELECT_IMPLICATION';
export const CLOSE_IMPLICATION = 'CLOSE_IMPLICATION';
export const HOVER_IMPLICATION = 'HOVER_IMPLICATION';
export const SHOW_CHALLENGES = 'SHOW_CHALLENGES';
export const CLOSE_CHALLENGE = 'CLOSE_CHALLENGE';
export const ISOLATE_ANSWER = 'ISOLATE_ANSWER';
export const DEISOLATE_ANSWER = 'CLOSE_ANSWER';
export const OPEN_NOTE = 'OPEN_NOTE';
export const CLOSE_NOTE = 'CLOSE_NOTE';
export const CLOSE_TERMINOLOGY = 'CLOSE_TERMINOLOGY';
export const OPEN_TERMINOLOGY = 'OPEN_TERMINOLOGY';
export const CLOSE_TRIAL_INFO = 'CLOSE_TRIAL_INFO';
export const OPEN_TRIAL_INFO = 'OPEN_TRIAL_INFO';
export const SELECT_SCREEN = 'SELECT_SCREEN';
export const CLOSE_TUTORIAL = 'CLOSE_TUTORIAL';
export const OPEN_TUTORIAL = 'OPEN_TUTORIAL';
export const SET_HIDE_TUTORIAL = 'SET_HIDE_TUTORIAL';
export const SHOW_SMALL_SCREEN_DETECTED = 'SHOW_SMALL_SCREEN_DETECTED';

export const reducer = (state: GameState = defaultState, action: any) => {
    switch(action.type) {
        case SELECT_DOMAIN:
        case SELECT_QUESTION:
        case SELECT_IMPLICATION:
        case SHOW_CHALLENGES:
        case SELECT_SCREEN:
        case ISOLATE_ANSWER:
        case HOVER_IMPLICATION:
        case OPEN_NOTE:
            return {
                ...state,
                ...action.payload
            };
        case CLOSE_DOMAIN:
            return {
                ...state,
                selectedDomainIndex: -1,
                selectedQuestionIndex: -1,
                selectedImplicationIndex: -1,
                showChallenges: false,
                selectedAnswerIndex: -1
            };
        case CLOSE_QUESTION:
            return {
                ...state,
                selectedQuestionIndex: -1,
                selectedImplicationIndex: -1,
                showChallenges: false,
                selectedAnswerIndex: -1
            };
        case CLOSE_IMPLICATION:
            return {
                ...state,
                selectedImplicationIndex: -1,
                showChallenges: false,
                selectedAnswerIndex: -1,
            };
        case CLOSE_CHALLENGE:
            return {
                ...state,
                showChallenges: false,
                selectedAnswerIndex: -1
            };
        case CLOSE_NOTE:
            return {
                ...state,
                noteOpened: 0
            };
        case OPEN_TERMINOLOGY:
            return {
                ...state,
                showTerminology: true,
                showTrialInfo: false,
                selectedDomainIndex: -1,
                selectedQuestionIndex: -1,
                selectedImplicationIndex: -1,
                showChallenges: false,
                selectedAnswerIndex: -1
            };
        case CLOSE_TERMINOLOGY:
            return {
                ...state,
                showTerminology: false
            };
        case OPEN_TRIAL_INFO:
            return {
                ...state,
                showTrialInfo: true,
                showTerminology: false,
                selectedDomainIndex: -1,
                selectedQuestionIndex: -1,
                selectedImplicationIndex: -1,
                showChallenges: false,
                selectedAnswerIndex: -1
            };
        case PUT_PROJECTS_SUCCESS:
        case CLOSE_TRIAL_INFO:
            return {
                ...state,
                showTrialInfo: false
            };
        case OPEN_TUTORIAL:
            return {
                ...state,
                showTutorial: 1,
                showTerminology: false,
                showTrialInfo: false,
                selectedDomainIndex: -1,
                selectedQuestionIndex: -1,
                selectedImplicationIndex: -1,
                showChallenges: false,
                selectedAnswerIndex: -1
            };
        case CLOSE_TUTORIAL:
            return {
                ...state,
                showTutorial: 0
            };
        case SET_HIDE_TUTORIAL:
            return {
                ...state,
                ...action.payload
            };
        case DEISOLATE_ANSWER:
            return {
                ...state,
                selectedAnswerIndex: -1
            };
        case SHOW_SMALL_SCREEN_DETECTED:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

// actions

export const selectDomain = (domainIndex: number) => ({
    type: SELECT_DOMAIN,
    payload: {selectedDomainIndex: domainIndex, selectedQuestionIndex: -1, selectedImplicationIndex: -1, showChallenges: false, showTerminology: false, showTrialInfo: false}
});

export const closeDomain = () => {
    return {
        type: CLOSE_DOMAIN
    }
};

export const openTerminology = () => {
    return {
        type: OPEN_TERMINOLOGY
    }
};

export const closeTerminology = () => {
    return {
        type: CLOSE_TERMINOLOGY
    }
};

export const openTrialInfo = () => {
    return {
        type: OPEN_TRIAL_INFO
    }
};

export const closeTrialInfo = () => {
    return {
        type: CLOSE_TRIAL_INFO
    }
};

export const openTutorial = () => {
    return {
        type: OPEN_TUTORIAL
    }
};

export const closeTutorial = () => {
    return {
        type: CLOSE_TUTORIAL
    }
};

export const setHideTutorial = (isSelected: boolean) => {
    return {
        type: SET_HIDE_TUTORIAL,
        payload: {hideTutorial: isSelected}
    }
};

export const selectScreen = (screenIndex: number) => ({
    type: SELECT_SCREEN,
    payload: {selectedScreenIndex: screenIndex}
});

export const selectQuestion = (questionIndex: number) => ({
    type: SELECT_QUESTION,
    payload: {selectedQuestionIndex: questionIndex, selectedImplicationIndex: -1, showChallenges: false}
});

export const closeQuestion = () => ({
    type: CLOSE_QUESTION
});

export const selectImplication = (implicationIndex: number) => ({
    type: SELECT_IMPLICATION,
    payload: {selectedImplicationIndex: implicationIndex}
});

export const closeImplication = () => ({
    type: CLOSE_IMPLICATION
});

export const hoverImplication = (type: 'enter' | 'leave', implicationIndex: number) => ({
    type: HOVER_IMPLICATION,
    payload: {
        hoverImplicationIndex: type === 'enter' ? implicationIndex : -1
    }
});

export const showChallenges = () => ({
    type: SHOW_CHALLENGES,
    payload: {showChallenges: true}
});

export const closeChallenge = () => ({
    type: CLOSE_CHALLENGE
});

export const isolateAnswer = (answerIndex: number) => ({
    type: ISOLATE_ANSWER,
    payload: {selectedAnswerIndex: answerIndex}
});

export const deIsolateAnswer = () => ({
    type: DEISOLATE_ANSWER
});

export const openNote = (domainIndex: number, questionIndex: number) => ({
    type: OPEN_NOTE,
    payload: {selectedDomainIndex: domainIndex, selectedQuestionIndex: questionIndex, noteOpened: 1}
});

export const closeNote = () => {
    return {
        type: CLOSE_NOTE
    }
};

export const setShowSmallScreenDetected = (show: boolean) => {
    return {
        type: SHOW_SMALL_SCREEN_DETECTED,
        payload: {showSmallScreenDetected: show}
    }
};

export const checkChanges = () => (dispatch: Function) => {
    return confirm(`Unsaved data. Are you sure?`);
};

// selectors

// export const getSelectedDomain = (gameState: GameState): Domain | void => {
//     return data[gameState.selectedDomainIndex];
// };

// export const getSelectedQuestion = (gameState: GameState): Question | void => {
//     const domain = getSelectedDomain(gameState);
//     return domain && domain.options[gameState.selectedQuestionIndex];
// };

// export const getSelectedAnswer = (gameState: GameState): Answer | void => {
//     const question = getSelectedQuestion(gameState);
//     return question && question.answers[gameState.selectedAnswerIndex];
// };