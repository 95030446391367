// @flow

import React from 'react';
import { connect } from 'react-redux';
import { selectImplication, showChallenges, closeChallenge, isolateAnswer, deIsolateAnswer } from '../../redux/game';
import type { Implication } from '../../types';
import styles from './Challenges.css';
import { Icon } from '../';
import arrowLeft from '../../assets/wit_icon_pijl_links.svg';
import challengeIcon from '../../assets/operational_challenge_icon.svg';
import whiteChallengeIcon from '../../assets/operational_challenge_icon.svg';

type ChallengesProps = {
    answerIndex: number,
    showChallenges: boolean,
    implication: Implication,
    selectChallenge: (implicationIndex: number, questionIndex: number) => void,
    closeChallenge: Function,
    implicationIndex: number
};

const Challenges = (props: ChallengesProps) => (
    <div className={styles.challengeIndication}>
        {!props.showChallenges ?
            <button onClick={() => props.selectChallenge(props.implicationIndex, props.answerIndex)}>
                {props.implication.implication === 0 ?
                    <Icon glyph={whiteChallengeIcon} height={36} width={26} /> :
                    <Icon glyph={challengeIcon} height={36} width={26} />
                }
            </button> :
            <button className={styles.expanded} onClick={() => props.closeChallenge()}>
                <Icon glyph={arrowLeft} height={36} width={36} style={{transform: 'rotate(90deg)'}} />
            </button>
        }
    </div>
);

const mapStateToProps = state => ({
    showChallenges: state.game.showChallenges
});

const mapDispatchToProps = dispatch => ({
    selectChallenge: (implicationIndex: number, answerIndex: number) => {
        dispatch(isolateAnswer(answerIndex));
        dispatch(selectImplication(implicationIndex));
        dispatch(showChallenges());
    },
    closeChallenge: () => {
        dispatch(deIsolateAnswer());
        dispatch(closeChallenge());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Challenges);