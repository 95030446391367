//@flow

import { browserHistory } from 'react-router';
import request from '../services/request';
import type { HandledResponse } from '../services/request';
import { loginUrl, meUrl, clientId, clientSecret } from '../constants/apiUrls';

// state def

export type LoginState = {
    isFetching: boolean,
    statusCode: number,
    authToken: string,
    email: string
};

// function because authToken default depends on local storage at the time of calling
const getDefaultState = (): LoginState => ({
    isFetching: false,
    statusCode: 0,
    authToken: localStorage.getItem('authToken') || '',
    email: localStorage.getItem('email') || ''
});

if(getDefaultState().authToken) {
    //keep request auth token in sync with state at all times
    request.setAuthToken(getDefaultState().authToken);
}

// reducer

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS';
export const REQUEST_LOGIN_ERROR = 'REQUEST_LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';

export const reducer = (state: LoginState = getDefaultState(), action: any) => {
    switch(action.type) {
        case REQUEST_LOGIN:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_LOGIN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                statusCode: 200,
                authToken: action.authToken,
                email: action.email
            };
        case REQUEST_LOGIN_ERROR:
            return {
                ...state,
                isFetching: false,
                statusCode: action.statusCode
            };
        default:
            return state;
    }
};

// actions

export const requestLogin = () => {
    return {
        type: REQUEST_LOGIN
    };
};

export const requestLoginSuccess = (token: string, email: string) => {
    request.setAuthToken(token);
    localStorage.setItem('authToken', token);
    localStorage.setItem('email', email);

    return {
        type: REQUEST_LOGIN_SUCCESS,
        authToken: token,
        email: email
    };
};

export const requestLoginError = (statusCode: number = -1) => {
    return {
        type: REQUEST_LOGIN_ERROR,
        statusCode
    };
};

export const login = (username: string, password: string) => (dispatch: Function) => {
    dispatch(requestLogin());

    request.execute(loginUrl(), {
        method: 'POST',
        body: {
            username,
            password,
            // grant_type: 'password',
            // client_id: clientId,
            // client_secret: clientSecret
        },
        credentials: 'include'
    }, false).then(
        (response: HandledResponse) => {
            request.setAuthToken(response.body.access_token);
            request.execute(meUrl(), {
                method: 'GET',
                credentials: 'include'
            }).then(
                (response2: HandledResponse) => {
                    dispatch(requestLoginSuccess(response.body.access_token, response2.body.data.email));
                    browserHistory.push('/projects');
                },
                (response2: HandledResponse) => {
                    dispatch(requestLoginError(response2.response.status));
                }
            )
        },
        (response: HandledResponse) => {
            dispatch(requestLoginError(response.response.status));
        }
    )
};

export const logout = () => {
    request.setAuthToken('');
    localStorage.setItem('authToken', '');
    localStorage.setItem('email', '');

    return {
        type: LOGOUT
    };
};