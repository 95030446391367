export const clientId = '1_3bcbxd9e24g0gk4swg0kwgcwg4o8k8g4g888kwc44gcc0gwwk4';
export const clientSecret = '4ok2x70rlfokc8g0wws8c8kwcokw80k44sg48goc0ok4w0so0k';

// clientId => id + '_' + random_id from oauth2_clients table
// clientSecret => secret from oauth2_clients table
// Simon local version:
// export const clientId = '1_4cvbv085x8o4gscsksk4ow0k88gkgc8owooccsskkg0wck0s04';
// export const clientSecret = '52fl4guoe6koso04gkkowoo48ogko84o00cowk0o40484oc4gg';

const getBaseUrl = (hostname) => {
    switch(hostname) {
        // // stage
        // case 'stage.pragmagic.helio.oberon.nl':
        //     return 'http://stage.pragmagic-api.helio.oberon.nl';
        // // tmp live
        // case 'pragmagic.helio.oberon.nl':
        //     return 'http://pragmagic-api.helio.oberon.nl';
        // // old live
        // case 'tool.pragmagic.eu':
        //     return 'http://api.pragmagic.eu';
        case 'getrealtrialtool.eu':
            return 'https://getrealtrialtool.eu';
        case 'trialtool.ijspreview.nl':
            return 'https://trialtool.ijspreview.nl';
        case 'pragmagic.ijspreview.nl':
            return 'https://pragmagic.ijspreview.nl';
        // dev / test
        default:
            // return 'https://getrealtrialtool.eu';
            return 'http://localhost:8010'
            // return 'http://localhost:8010/app_dev.php'
            // return 'http://test.pragmagic-api.helio.oberon.nl';
            // return 'http://api.pragmagic.eu';
            // return 'http://127.0.0.1:8000'
    }
};

// 'https://trialtool.ijspreview.nl';
const baseUrl = getBaseUrl(location.host);

export const loginUrl = () => baseUrl + '/jsonlogin';
export const registerUrl = () => baseUrl + '/register';
export const resetUrl = () => baseUrl + '/resetting/request';
export const projectUrl = (projectId?: number) => baseUrl + '/api/v1/projects' + (projectId ? '/' + projectId : '');
export const latestToolContentUrl = () => baseUrl + '/api/v1/toolcontent/latest';
export const exportUrl = () => baseUrl + '/excel';
export const pdfUrl = () => baseUrl + '/pdf';
export const meUrl = () => baseUrl + '/api/v1/me';