// @flow

import React from 'react';
import {connect} from 'react-redux';
import type {Project} from '../../redux/project';
import {closeTerminology, selectScreen} from '../../redux/game';
import styles from './Terminology.css';
import {NavigationSlider} from '../';

type TerminologyProps = {
    project: Project,
    email: string,
    screenIndex: number
};

const screenOne = () => (
    <div>
        <div className={styles.col}>
            <h2 className={styles.title}>Concepts within the GetReal Trial Tool</h2>
            <div className={styles.smallsubtitle}>A tool to navigate RWE options in clinical trials</div>

            <h4 className={styles.tag}>Clincal Trials with Real World Evidence (RWE) elements</h4>
            <p>Trials geared towards RWE aim to capture the true effect of a treatment strategy in routine clinical practice. This entails the comparison of patients randomised to the intervention and the control strategy, who are similar to the target group regarding the characteristics that modify drug response, in the setting where they would be treated in real life and the use of comparators and outcome measures that are relevant for treatment decisions in routine clinical practice. The strategies for data collection and safety and quality monitoring need to be taken into account because of possible influences on routine clinical practice.</p>

            <h4 className={styles.tag}>Navigate your trial design</h4>
            <p>The more progress you make in the tool by selecting your clinical trial design choices, the more yellow building blocks will appear on your home screen. Specific implications of design choices can be seen in the sub-menu of your design domain and choice. A general overview of the implications of your design choices can be exported using the fingerprint function.</p>
        </div>
        <div className={styles.col}>
            <h4 className={styles.tag}>Trial design element</h4>
            <p>A choice that can be made within your trial design, with a focus on choices that differentiate between a more pragmatic and more explanatory trial, e.g. whether to protocolise dosing in the trial or leave it up to the treating physician. All possible trial design elements have been captured in the questions under the 7 domains displayed in the navigation wheel (bottom right corner of screen).</p>

            <h4 className={styles.tag}>Operational challenge</h4>
            <p>A practical issue that may arise during the execution of the trial e.g. low participation of sites due to required training and study burden.</p>
        </div>
    </div>
);

const screenTwo = () => (
    <div>
        <div className={styles.col}>
            <h4 className={styles.tag}>Implication</h4>
            <p>
                The effect a design choice or operational challenge has on interpretation of the results (generalisability, validity, precision), ethical and stakeholder acceptability (patient, prescriber, regulatory and HTA body) or the required resources or study duration. For example, generalisability may increase by performing your trial in the setting where the drug would be prescribed in real life, e.g. GP practices (trial design element: selection of sites) but may decrease again if due to training requirements and study burden only few and selective sites recruit patients (operational challenge: participation of selected sites in the trial).
            </p>

            <h4 className={styles.tag}>Treatment</h4>
            <p>All interventions that can be used as treatment arm in clinical trials. For this tool we focus on drug trials but comparator treatment strategies can include ‘wait-and-see’, chirurgical procedures and different administration routes.</p>

            <h4 className={styles.tag}>Usual care</h4>
            <p>The treatment strategies available and used in routine clinical practice. This can also include a non-drug treatment strategy e.g. wait-and-see (see definition of “Treatment”)</p>
        </div>
        <div className={styles.col}>
            <h2 className={styles.title}>Definition of implications</h2>

            <h3 className={styles.subtitle}>Methodological</h3>

            <h4 className={styles.tag}>Generalisability</h4>
            <p>The extent to which the results of a study apply to the population of patients in a particular clinical setting.</p>

            <h4 className={styles.tag}>Risk of bias</h4>
            <p>If the result of a comparison is not true, but systematically (non-random) over- or under-estimates the effects of the treatment, such a result is biased.</p>

            <h4 className={styles.tag}>Precision</h4>
            <p>The confidence interval (CI) of the effect estimates of a study denotes the probabilistic boundaries for the true effect of a treatment. The smaller the CI, the higher the precision.</p>

            <h3 className={styles.subtitle}>Stakeholder acceptability</h3>

            <h4 className={styles.tag}>Acceptability by patients</h4>
            <p>The degree of acceptability of the trial results from a patient perspective.</p>
        </div>
    </div>
);

const screenThree = () => (
    <div>
        <div className={styles.col}>
            <h4 className={styles.tag}>Acceptability by prescribers</h4>
            <p>The degree of acceptability of the trial results from the perspective of the health care provider, as well as clinical guideline developers.</p>

            <h4 className={styles.tag}>Acceptability by regulators</h4>
            <p>The degree of acceptability of the trial design and execution from a regulatory perspective, with a focus on European regulations. This should be seen as separate from the acceptability of the evidence (study results) for regulatory decision making. Please note that most regulatory guidelines are specifically written for pre-launch explanatory trials and may not be applicable to more pragmatic trials. Early dialogue with regulatory authorities is advised.</p>

            <h4 className={styles.tag}>Acceptability by HTA bodies</h4>
            <p>The degree of acceptability of trial results by Health Technology Assessment (HTA) bodies, with a focus on European HTA perspectives.</p>

            <h4 className={styles.tag}>Ethical acceptability</h4>
            <p>The degree of ethical acceptability of the trial design as judged by international standards.</p>
        </div>
        <div className={styles.col}>
            <h3 className={styles.subtitle}>Feasibility</h3>

            <h4 className={styles.tag}>Cost</h4>
            <p>The costs associated with running the trial, from designing the protocol until the analysis of the results.</p>

            <h4 className={styles.tag}>Duration</h4>
            <p>The duration of the trial, from designing the protocol until the analysis of the results.</p>
        </div>
    </div>
);

const Terminology = (props: TerminologyProps) => (
    <div className={styles.container}>
        <div className={styles.header}>
            <h1>Terminology</h1>
        </div>
        <div className={styles.content}>
            {
                props.screenIndex === 0 ?
                    screenOne() :
                props.screenIndex === 1 ?
                    screenTwo() :
                props.screenIndex === 2 ?
                    screenThree() :
                    null
            }
        </div>
        <NavigationSlider
            selectIndex={(screenIndex) => props.selectScreen(screenIndex) }
            onClose={() => props.onClose()}
            numberOfItems={3}
            itemIndex={props.screenIndex}
        />
    </div>
);

const mapStateToProps = state => ({
    screenIndex: state.game.selectedScreenIndex
});

const mapDispatchToProps = dispatch => ({
    onClose: () => {
        dispatch(closeTerminology());
    },
    selectScreen: (screenIndex: number) => {
        dispatch(selectScreen(screenIndex));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Terminology);