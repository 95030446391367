// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { Answer } from '../../types';
import { ImplicationBlock } from '../';
import ImplicationLabels from '../ImplicationLabels/ImplicationLabels';
import styles from './Implications.css';
import { Motion, spring, presets } from 'react-motion';
import Sources from '../Sources/Sources';

type ImplicationsProps = {
    answer: Answer,
    answerIndex: number,
    selectedImplicationIndex: number
};

const getOpacity = (selectedIndex, index): number => {
    return selectedIndex === -1 || selectedIndex === index ? 1 : 0;
};

class Implications extends React.Component {
    props: ImplicationsProps;

    state = {
        height: 0,
        show: false
    };

    explanationEls: Array<HTMLElement> = [];

    //somehow using component mount and setting an unused state fixes the min height... :/
    componentDidMount() {
        this.setState({show: true})
    }

    render() {
        const props = this.props;

        return (
            <div className={styles.wrapper}
                 style={{
                     minHeight: props.selectedImplicationIndex === -1 || !this.explanationEls[props.selectedImplicationIndex] ? 0 :
                         this.explanationEls[props.selectedImplicationIndex].offsetHeight
                 }}>

                {props.answer && props.answer.implications.map((implication, index) => {

                    const style = {
                        x: ImplicationLabels.getOffset(index, props.selectedImplicationIndex),
                        opacity: getOpacity(props.selectedImplicationIndex, index),
                        explanationOpacity: spring(props.selectedImplicationIndex === index ? 1 : 0, presets.gentle)
                    };

                    return (
                        <Motion style={style} key={index}>
                            {({x, opacity, explanationOpacity}) => (
                                <div style={{display: 'flex'}}>

                                    <ImplicationBlock
                                        key={index}
                                        implicationIndex={index}
                                        answer={props.answer}
                                        answerIndex={props.answerIndex}
                                        implication={implication}
                                        style={{
                                            transform: `translate3d(${x}rem, 0, 0)`,
                                            opacity
                                        }}
                                    />

                                    <div
                                        ref={el => this.explanationEls[index] = el}
                                        className={styles.explanation}
                                        style={{
                                            opacity: explanationOpacity,
                                            zIndex: props.selectedImplicationIndex === index ? 2 : 0,
                                            //transform: `translate3d(${x}rem, 0, 0)`
                                        }}
                                    >
                                        {props.answer && props.answer.implications[index].explanation}
                                        {props.answer && props.answer.implications[index].source.length > 0 &&
                                        <Sources sources={props.answer.implications[index].source}/>
                                        }
                                    </div>

                                </div>
                            )}
                        </Motion>
                    );
                })}
            </div>

        );
    }
}


const mapStateToProps = state => ({
    selectedImplicationIndex: state.game.selectedImplicationIndex,
});

export default connect(mapStateToProps)(Implications);