// @flow

import React, { Component } from 'react';
import styles from './NavigationSlider.css';
import classnames from 'classnames';
import { Icon } from '../';
import arrowLeft from '../../assets/wit_icon_pijl_links.svg';
import arrowRight from '../../assets/wit_icon_pijl_rechts.svg';
import cross from '../../assets/wit_icon_kruis.svg';

type QuestionNavigationProps = {
    itemIndex: number,
    selectIndex: (itemIndex: number) => void,
    onClose: Function,
    numberOfItems: number,
    leftControls:boolean
};

export default class NavigationSlider extends Component {
    props: QuestionNavigationProps;

    shouldComponentUpdate(nextProps: QuestionNavigationProps) {
        //skip update when domain gets deselected. This component should not be rendered but might still be visible in
        //leave animation.
        return nextProps.numberOfItems !== -1;
    }

    render() {
        const props = this.props;

        return (
            <div className={styles.panelFooting}>
                {props.numberOfItems > 1 &&
                    <div className={classnames(styles.controls, {[styles.leftControls]: props.leftControls})}>
                        <button className={styles.button} disabled={props.itemIndex === 0}
                                onClick={() => props.selectIndex(props.itemIndex - 1)}>
                            <Icon glyph={arrowLeft} height={36} width={36}/>
                        </button>
                        {Array.apply(null, Array(props.numberOfItems)).map((item, i) =>
                            <button key={i} className={styles.navigationButton} disabled={props.itemIndex === i}
                                    onClick={() => props.selectIndex(i)}>&#9679;</button>
                        )}
                        <button className={styles.button} disabled={props.itemIndex === props.numberOfItems-1}
                                onClick={() => props.selectIndex(props.itemIndex + 1)}>
                            <Icon glyph={arrowRight} height={36} width={36}/>
                        </button>
                    </div>
                }
                <button className={styles.button + ' ' + styles.closeButton} onClick={() => props.onClose(props.project)}>
                    <Icon glyph={cross} height={36} width={36} />
                </button>
            </div>
        );
    }
}