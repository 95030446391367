// @flow

import React from 'react';
import { connect } from 'react-redux';
import styles from './ImplicationBlock.css';
import { Challenges } from '../';
import { selectImplication, closeImplication, hoverImplication } from '../../redux/game';
import type { Answer, Implication } from '../../types';
import { windowColors } from '../../constants/colors';
import { hasChallenge } from '../../services/dataSelectors';

type ImplicationBlockProps = {
    implicationIndex: number,
    selectedImplicationIndex: number,
    answer: Answer,
    answerIndex: number,
    implication: Implication,
    showChallenges: boolean,
    selectImplication: Function,
    close: Function,
    onMouseEnter: (implicationIndex: number) => void,
    onMouseLeave: (implicationIndex: number) => void,
    style?: any
};

const ImplicationBlock = (props: ImplicationBlockProps) => (
    <div className={styles.implicationWrapper} style={props.style}>
        <div
            onClick={() => (props.selectedImplicationIndex > -1 ? props.close() : props.selectImplication(props.implicationIndex))}
            onMouseEnter={() => {props.onMouseEnter(props.implicationIndex)}}
            onMouseLeave={() => {props.onMouseLeave(props.implicationIndex)}}
            className={styles.implication}
        >
            <div className={styles.implicationFill} style={{backgroundColor: windowColors[props.implication.implication]}} />
        </div>

        {(hasChallenge(props.implicationIndex, props.answer) || props.showChallenges) &&
            <Challenges implicationIndex={props.implicationIndex} implication={props.implication} answerIndex={props.answerIndex} />
        }

    </div>
);

const mapStateToProps = state => ({
    selectedImplicationIndex: state.game.selectedImplicationIndex,
    showChallenges: state.game.showChallenges
});

const mapDispatchToProps = dispatch => ({
    selectImplication: (implicationIndex: number) => {dispatch(selectImplication(implicationIndex))},
    close: () => {dispatch(closeImplication())},
    onMouseEnter: (index) => dispatch(hoverImplication('enter', index)),
    onMouseLeave: (index) => dispatch(hoverImplication('leave', index))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImplicationBlock);