// @flow

import React from 'react';

type IconProps = {
    width: number,
    height: number,
    glyph: string,
    style?: Object,
    className?: string
};

export default (props: IconProps) => (
    <svg
        style={{
            overflow: 'visible',
            width: props.width + 'rem',
            height: props.height + 'rem', ...props.style
        }}
        className={props.className}
    >
        <use xlinkHref={props.glyph} />
    </svg>
);