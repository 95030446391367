import React, { Component } from 'react';
import styles from './TextInput.css';

/**
 * - handle styling
 * - handle float label
 */
export default class TextInput extends Component {

    state = {
        value: this.props.value || this.props.defaultValue || ''
    };

    onChange(event) {
        this.setState({value: event.target.value});
        if(typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }

    componentWillReceiveProps(newProps) {
        if(newProps.value !== this.props.value) {
            this.setState({value: newProps.value});
        }
    }

    render() {
        const { multiLine, ...inputProps } = this.props;

        const props = {
            ...inputProps,
            className: (this.props.className || '') + styles.input,
            onChange: (event) => this.onChange(event)
        };

        return (
            <div className={styles.container}>
                <div className={styles.floatLabel}>{this.state.value && props.placeholder}&nbsp;</div>
                {multiLine
                    ? <textarea {...props} style={{resize: 'none'}} rows={6} />
                    : <input {...props} />
                }
            </div>
        );
    }
}