//@flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import type { Project } from '../../redux/project';
import type { GameState } from '../../redux/game';
import {
    DomainSelection,
    MainContentView,
    QuestionOverview,
    AnswerSelection,
    GameOptions,
    City,
    Progress,
    GameHeader,
    Terminology,
    Tutorial,
    TrialInfo,
    GameApiActivity
} from '../';
import styles from './ProjectView.css';
import Backdrop from '../Backdrop/Backdrop';
import { Motion, spring } from 'react-motion';
import Feedback from '../Feedback/Feedback';

type ProjectProps = {
    project: Project,
    gameState: GameState,
    params: {projectId: string},
    dispatch: Function,
    router: Object,
    route: Object,
    demoMode: boolean,
    onReport: () => void,
    onFingerprint: () => void,
    onClickBackground: () => void,
};

class ProjectView extends Component {

    props: ProjectProps;

    renderProject(project: Project) {
        const { gameState } = this.props;
        const showMain = gameState.selectedDomainIndex !== -1 || gameState.showTerminology || gameState.showTrialInfo;

        return (
            <div className={styles.background}>
                <div className={styles.game}>
                    <City project={project} onClick={(event) => {
                        if (this.props.onClickBackground) {
                            this.props.onClickBackground();
                        }
                    }}/>

                    <GameHeader project={project} legend={gameState.selectedDomainIndex !== -1} />
                    <GameApiActivity/>

                    <Motion
                        style={{
                            opacity: spring(showMain ? 1 : 0, {stiffness: 220, damping: 20}),
                            scale: spring(showMain ? 1 : 0.95, {stiffness: 140, damping: 18})
                        }}
                    >
                        {({ opacity, scale }) => {

                            return (
                                <div>
                                    <Backdrop style={{opacity}} />
                                    <MainContentView style={{
                                        transform: `scale3d(${scale}, ${scale}, 1)`,
                                        opacity,
                                        pointerEvents: showMain ? 'auto' : 'none',
                                        transformOrigin: 'bottom center'
                                    }}>

                                        {gameState.selectedQuestionIndex !== -1 && <AnswerSelection key="answers" project={project} domains={project.toolContent.data} />}
                                        {gameState.selectedDomainIndex !== -1 && gameState.selectedQuestionIndex === -1 && <QuestionOverview key="overview" project={project} />}

                                        {gameState.showTerminology &&
                                        <Terminology />
                                        }
                                        {gameState.showTrialInfo &&
                                        <TrialInfo project={project} demoMode={this.props.demoMode} />
                                        }
                                    </MainContentView>
                                </div>
                            )
                        }}
                    </Motion>

                    <DomainSelection hideTutorial={project.hideTutorial} domains={project.toolContent.data ? project.toolContent.data : []} />
                    <GameOptions project={project}
                                 demoMode={this.props.demoMode}
                                 onReport={this.props.onReport}
                                 onFingerprint={this.props.onFingerprint}
                    />
                    <Progress project={project}/>
                    { (!project.hideTutorial && gameState.showTutorial) ? <Tutorial project={project} /> : null}
                    { (!project.hideTutorial && gameState.showTutorial) ? <div className={styles.tutorialOverlay} /> : null }
                    {this.props.demoMode ? undefined : <Feedback positionType="game" />}
                </div>
            </div>
        )
    }

    render() {
        const { project } = this.props;
        return (
            this.renderProject(project)
        );
    }
}

const mapStateToProps = state => ({
    gameState: state.game
});

export default connect(mapStateToProps)(withRouter(ProjectView));