//@flow

import React, { Component } from 'react';
import Dotdotdot from 'react-dotdotdot'

type ReadMoreProps = {
    clamp: number,
};

class ReadMore extends Component {
    props: ReadMoreProps;

    state = {
        truncated: true
    };

    render() {
        return (
            <div onClick={() => this.setState({truncated: !this.state.truncated})} title="Read more...">
                <Dotdotdot clamp={this.state.truncated ? this.props.clamp : 100}>
                    {this.props.children}
                </Dotdotdot>
            </div>
        );
    }
}

export default ReadMore;