// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './ImplicationLabels.css';
import { selectImplication, closeImplication } from '../../redux/game';
import type { Implication } from '../../types';
import { Motion, spring, presets } from 'react-motion';

type ImplicationLabelsProps = {
    implications: Array<Implication>,
    selectedImplicationIndex: number,
    hoverImplicationIndex: number,
    selectImplication: (index: number) => void,
    closeImplication: () => void,
    expand: Function,
    shortLabels: Array
};

class ImplicationLabels extends Component {
    static SPACES = [3, 8]; // insert some space after this
    static SPACE_SIZE = 10;
    state = {
        isHover: false,
        selectedImplicationIndex: this.props.selectedImplicationIndex
    };

    props: ImplicationLabelsProps;

    static getOffset(index, selectedIndex = -1): number {
        const offset = index < selectedIndex ? 38 : 0;
        const spacing = ImplicationLabels.SPACES.reduce((spacing, spaceIndex) =>
            spacing + (index >= spaceIndex ? ImplicationLabels.SPACE_SIZE : 0)
        , 0);

        return index === selectedIndex ? 0 :
            index * 38 + offset + spacing;
    }

    getOpacity(index): number {
        const selectedIndex = this.props.selectedImplicationIndex;
        return selectedIndex === -1 || selectedIndex === index ? 1 :
            this.state.isHover ? 0.5 : 0;
    }

    selectImplication(index) {
        if(this.props.selectedImplicationIndex === index) {
            this.props.closeImplication();
        } else {
            this.props.selectImplication(index);
        }
    }

    hoverImplications() {
        this.setState({
            isHover: true,
            selectedImplicationIndex: -1
        })
    }

    hoverOffImplication() {
        this.setState({
            isHover: false,
            selectedImplicationIndex: this.props.selectedImplicationIndex
        })
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.selectedImplicationIndex === -1){
            this.setState({
                selectedImplicationIndex: -1
            })
        } else if(!this.state.isHover) {
            this.setState({
                selectedImplicationIndex: nextProps.selectedImplicationIndex
            })
        }
    }

    render(){

        return (
            <div
                className={styles.implicationWrapper}
                onMouseOver={() => this.hoverImplications()}
                onMouseLeave={() => this.hoverOffImplication()}
            >

                <div>
                    {this.props.implications.map((implication, index) => {
                        const style = {
                            x: spring(ImplicationLabels.getOffset(index, this.state.selectedImplicationIndex), {stiffness: 150, damping: 20}),
                            opacity: spring(this.getOpacity(index), presets.gentle)
                        };

                        const labelClass = this.props.hoverImplicationIndex === index ? styles.labelHover : styles.label;

                        return (
                            <Motion style={style} key={index}>
                                {({x, opacity}) => (
                                    <div
                                        className={styles.labelWrapper}
                                        style={{
                                            transform: `translate3d(${x}rem, 0, 0)`,
                                            opacity
                                        }}
                                    >
                                        <div className={styles.labelParent} onClick={() => this.selectImplication(index)}>
                                            <span className={labelClass}>{this.props.shortLabels[index]}</span>
                                        </div>
                                    </div>
                                )}
                            </Motion>
                        )
                    })}
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    // implications: data[0].options[0].answers[0].implications,
    selectedImplicationIndex: state.game.selectedImplicationIndex,
    hoverImplicationIndex: state.game.hoverImplicationIndex
});

const mapDispatchToProps = dispatch => ({
    selectImplication: (implicationIndex: number) => {
        dispatch(selectImplication(implicationIndex));
    },
    closeImplication: () => dispatch(closeImplication()),
    expand: () => {},
    shortLabels: [
        'Generalisability',
        'Risk of bias',
        'Precision',
        'Patients',
        'Prescriber',
        'Regulator',
        'HTA body',
        'Ethical',
        'Cost',
        'Duration',
    ]
});

export default connect(mapStateToProps, mapDispatchToProps)(ImplicationLabels);