// @flow

import React from 'react';
import styles from './Feedback.css';
import classNames from 'classnames';

type FeedbackProps = {
    positionType: 'game' | undefined
};

const Feedback = (props: FeedbackProps) => (
    <a className={classNames(styles.link, {
        [styles.game]: props.positionType === 'game',
    })}
       href="mailto:info@getreal-institute.org">Feedback</a>
);

export default Feedback;