exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css"), "");
// Module
exports.push([module.id, ".src-components-Projects-Projects__wrap--3a1l3{width:800rem;margin-left:auto;margin-right:auto;box-sizing:border-box}.src-components-Projects-Projects__header--JB5WB{background:" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["primaryColorDark"] + ";margin-top:28rem;margin-bottom:6rem;position:relative;padding:16rem 0;min-height:32rem}.src-components-Projects-Projects__headerContent--1YJ_j{color:#fff;display:-webkit-box;display:flex;-webkit-box-align:center;align-items:center}.src-components-Projects-Projects__headerText--3ZTL4{color:#fff;text-transform:uppercase;font-size:12rem;margin-left:8rem;line-height:1.2}.src-components-Projects-Projects__logout--2hXKE{position:absolute;right:40rem;top:-3rem;height:100%;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;-webkit-box-align:right;align-items:right;-webkit-box-pack:center;justify-content:center;font-size:14rem;text-decoration:underline;color:#fff;line-height:2.5}.src-components-Projects-Projects__logout--2hXKE,.src-components-Projects-Projects__logout--2hXKE button{text-align:right}", ""]);
// Exports
exports.locals = {
	"primaryColorDark": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["primaryColorDark"] + "",
	"primaryColorMedium": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["primaryColorMedium"] + "",
	"primaryColorLight": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["primaryColorLight"] + "",
	"gutterSize": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["gutterSize"] + "",
	"wrap": "src-components-Projects-Projects__wrap--3a1l3",
	"header": "src-components-Projects-Projects__header--JB5WB",
	"headerContent": "src-components-Projects-Projects__headerContent--1YJ_j src-components-Projects-Projects__wrap--3a1l3",
	"headerText": "src-components-Projects-Projects__headerText--3ZTL4",
	"logout": "src-components-Projects-Projects__logout--2hXKE"
};