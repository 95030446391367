import React, {Component} from 'react';
import {connect} from "react-redux";
import {browserHistory, withRouter} from "react-router";
import type {Project} from "../../redux/project";
import {cleanUp} from "../../redux/project";
import ProjectView from "../ProjectView/ProjectView";
import type {GameState} from "../../redux/game";
import moment from "moment";
import styles from "../Demo/Demo.css";
import Markdown from 'markdown-to-jsx';
import imageAssignmentArrow from "../../assets/demo/ui/assignment.svg";
import imageFeedbackCheck from "../../assets/demo/ui/checked.svg";
import imageDesignChoice from "../../assets/demo/design-choice.png";
import imageOperationalChallenges from "../../assets/introductionDiagram.svg";
import arrow from "../../assets/demo/ui/simplearrow.svg";
import imageExclamationMark from "../../assets/demo/exclamation-mark.png";
import imageCheckChecked from "../../assets/demo/ui/check-demolist.svg";
import arrowRight from "../../assets/wit_icon_pijl_rechts.svg";
import arrowLeft from "../../assets/wit_icon_pijl_links.svg";
import { createDemoProject } from '../../redux/project';
import {Spinner} from "../index";

type DemoProps = {
    gameState: GameState,
    dispatch: Function,
    router: Object,
    route: Object,
    createDemoProject: Function,
    debug: boolean
};

const DEMO_PROJECT_ID = -1;

const FEEDBACK_DELAY_SECONDS = 1.5;

const demoContent = [
    {
        group: '1',
        title: 'Tool & demo',
        items: [
            {
                type: 'read',
                id: '1.1',
                title: 'Welcome',
                content: "The GetReal Trial Tool offers you step by step guidance to evaluate the options for and implications of introducing real world elements in clinical trial design. More information on randomised clinical trials integrated into routine clinical practice (also called pragmatic trials) can be found [here](https://www.sciencedirect.com/journal/journal-of-clinical-epidemiology/special-issue/10X9857N20G). \n\n" +
                    "This demo leads you through the tool using a real trial example. \n\n" +
                    "* Step 1-6: introduction to the main elements and functionalities of the tool (5-10 min)\n" +
                    "* Step 7-9: in-depth practice and demonstration of export functionalities (5-10 min)\n" +
                    "* You can quit the demo at any time, but you can’t save your choices/input in this demo version\n" +
                    "* You can’t navigate back to a previous step\n",
                action: 'Let’s get started!'
            }
        ]
    },
    {
        group: '2',
        title: 'Trial info',
        items: [
            {
                type: 'system',
                id: '2.1',
                title: 'Trial information',
                content: "You are asked to lead a study team designing a post-launch clinical trial for a new single-pill combination of an antihypertensive agent and a statin.\n\n" +
                    "Research question:\n\n" +
                    "* Does this new single pill combination **in practice, in the general population**, have **better effectiveness** in reducing the cardiovascular risk profile of patients compared with the currently used strategy?",
                assignment: "Click on the trial name (GeTTT) in the left upper corner to access the ‘Trial information’ page, where you can find more detailed trial information.\n",
                checkDoneByPropsFn: (currentProps, nextProps) => {
                    // console.log('checkDoneByPropsFn', currentProps, nextProps);
                    const justOpened = nextProps.gameState.showTrialInfo && !currentProps.gameState.showTrialInfo;
                    return justOpened;
                },
                feedback: "When designing or reviewing your own trial you can use the ‘Trial information’ page to register the specifics of your trial.\n\n" +
                    "If you want to check out the page, you can hide this demo screen by clicking on the ‘>’.",
                action: 'Next'
            },
            {
                type: 'open',
                id: '2.2',
                title: 'Anticipate challenges',
                content: "On the ‘Trial information’ page you have received more information on the trial you are asked to design.\n\n" +
                    "* What do you see as the biggest challenge for designing your trial in such a way that the trial results are generalisable to the routine clinical practice setting of interest?",
                assignment: "Shortly describe the challenge in the field below.",
                doneAction: "Next",
                feedback: "Keep this challenge in mind while making design choices during this training.\n\n" +
                    "Some major (perceived) challenges in gearing trials towards generating Real World Evidence (RWE) include:\n\n" +
                    "* how to deal with the diversity of patients and treatments in routine clinical practice;\n" +
                    "* how to avoid changing the practice you want to study; \n" +
                    "* how to assure data quality.\n",
                action: 'Next'
            }
        ]
    },
    {
        group: '3',
        title: 'Navigation',
        items: [
            {
                type: 'system',
                id: '3.1',
                title: 'Trial design considerations',
                content: "The GetReal Trial Tool distinguishes seven domains pertaining to trial design – see the wheel at lower right hand corner.\n\n" +
                    "There is no specific order in which you need to walk through the domains. For this demo we start by looking at the study population for your trial.",
                assignment: "Use the navigation wheel to open the **participant** domain. ",
                checkDoneByPropsFn: (currentProps, nextProps) => {
                    // console.log('checkDoneByPropsFn', currentProps, nextProps);
                    const justOpened = nextProps.gameState.selectedDomainIndex === 0
                        && currentProps.gameState.selectedDomainIndex !== 0;
                    return justOpened;
                },
                feedback: "Great! In the participant domain you see five design questions, relating to trial participant selection, recruitment and attrition, which we can consider to introduce more real world elements in the trial.",
                // TODO wens: afbeelding
                action: 'Next'
            },
            {
                type: 'system',
                id: '3.2',
                title: 'Study population',
                content: "If the aim is to render information in routine clinical practice, different considerations apply to study population regarding:\n\n" +
                    "* selection;\n" +
                    "* recruitment;\n" +
                    "* attrition.\n\n" +
                    "We will now look at one of the questions in more detail.",
                assignment: "Click on the first question in this domain.",
                checkDoneByPropsFn: (currentProps, nextProps) => {
                    console.log('checkDoneByPropsFn', currentProps, nextProps);
                    const justOpened = nextProps.gameState.selectedQuestionIndex === 0
                        && currentProps.gameState.selectedQuestionIndex !== 0;
                    return justOpened;
                },
                feedback: "If you close the demo screen, you see the different design choices you can make regarding eligibility of trial participants and their possible implications.",
                action: 'Next'
            }
        ]
    },
    {
        group: '4',
        title: 'Implications',
        items: [
            {
                type: 'system',
                id: '4.1',
                title: 'Eligibility criteria',
                content: "Without paying too much attention to the implications, determine whether you would choose design option A or B for this trial.\n\n" +
                    "Keep in mind that this trial aims to demonstrate the effectiveness of a new single pill combination therapy compared with the currently used strategy, in practice.\n",
                assignment: "Select that option by checking the yellow box. You can hide this demo screen by clicking on the ‘>’.",
                checkDoneByPropsFn: (currentProps, nextProps) => {
                    // console.log('checkDoneByPropsFn', currentProps, nextProps);
                    // check if changed to 0 or 1. (maybe not check if this was the first change?)
                    // do we also want to check if the change had already been made before? (check for -1)
                    // const justOpened = nextProps.projectState.projects[0] && !currentProps.gameState.showTrialInfo;
                    // return nextProps.projectState.projects[0].content.answers[0][0] === 0
                    //     || nextProps.projectState.projects[0].content.answers[0][0] === 1;
                    return nextProps.projectState.projects[0].content.answers[0][0] !== -1;
                },
                feedback: "What was your main driver for choosing this answer option?" +
                    "\n\nWith the GetReal Trial Tool we try to help make these decision processes more explicit, taking into account possible methodological and operational consequences of each choice.",
                action: 'Next'
            },
            {
                type: 'read',
                id: '4.2',
                title: 'Implications of design choices',
                content: "Every design choice you make may have implications for:\n\n" +
                    "* **generalisability** of your trial results to the setting of interest;\n" +
                    "* **risk of bias**;\n" +
                    "* **precision**;\n" +
                    "* **stakeholder acceptability**;\n" +
                    "* **cost**;\n" +
                    "* **duration** of the trial.\n\n" +
                    "Definitions of these terms can be found under the ‘i’.\n\n" +
                    "The tool gives you insights in these possible implications. And the colour coding behind each design option shows you the possible impact in one glance.\n",
                imageSrc: imageDesignChoice,
                action: 'I get it'
            },
            {
                type: 'system',
                id: '4.3',
                title: 'Generalisability',
                content: "You can click on one of the possible implications, e.g. **‘Generalisability’** to:\n\n" +
                    "* find an explanation of the possible effects of either choice on this implication.\n" +
                    "* find a link to relevant scientific literature, where available.\n\n" +
                    "Have a look at the possible effects of your answer on generalisability.\n\n" +
                    "* Would you revise your design choice, now that you have taken into account the possible implications of your choice on generalisability?\n",
                assignment: "Add a note by clicking on ‘Add note’ - see upper right corner of the question screen.\n\n" +
                    "Describe in one sentence whether you would revise your design choice and why (not). Save your note.",
                checkDoneByPropsFn: (currentProps, nextProps) => {
                    // console.log('checkDoneByPropsFn', currentProps, nextProps);
                    // notes =
                    // console.log(currentProps.projectState.projects[0].content.notes[0][0], nextProps.projectState.projects[0].content.notes[0][0]);
                    return nextProps.projectState.projects[0].content.notes[0][0] !== -1;
                },
                feedback: "You can export the notes you make in the tool to an Excel file, so you can e.g. discuss them in a team meeting or add them to a protocol.\n" +
                    "Click on ‘Generalisability’ again to go back to the overview of implications.\n",
                action: 'Done'
            },
            {
                type: 'action',
                id: '4.4',
                title: 'Weighing different implications',
                content: "Besides generalisability, there are other possible implications of your design choice that we can take into account.\n\n" +
                    "Often the implications of a design choice need to be weighed against each other and the GetReal Trial Tool can help you do this more explicitly.",
                assignment: "Click on ‘Generalisability’ again to go back to the overview of implications. Next, click on ‘Precision’. \n\n" +
                    "Determine which implication – that for generalisability or that for precision – is more important for your research question. **Would you revise your design choice?**\n",
                doneAction: "I am ready",
                feedback: "It is important to explicitly consider all possible implications of design choices together.\n" +
                    "Sometimes generalisability will need to be compromised to a certain extent if otherwise the trial would e.g. be biased, under-powered or unfeasible.\n",
                action: 'Next'
            }
        ]
    },
    {
        group: '5',
        title: 'Operational challenges',
        items: [
            {
                type: 'read',
                id: '5.1',
                title: 'Operational challenges (1)',
                content: "In the steps above you looked at trial design elements and their possible **implications**.\n" +
                    "To complicate matters, design choices can also lead to specific **operational challenges**, which may have their own impact on generalisability, risk of bias etc.",
                imageSideBar: <div className={styles.demoPanelImageBlock}>
                    <div style={{width: '250rem', height: '284rem', position: 'relative'}}>
                        <img className={styles.demoPanelImage} style={{width: '250rem', height: '284rem'}} src={imageOperationalChallenges}/>
                        <img style={{
                            position: 'absolute',
                            width: '80rem',
                            height: '49rem',
                            left: '243rem',
                            top: '226rem',
                            transform: 'scaleX(-1)',
                        }} src={arrow}></img>
                    </div>

                </div>,
                action: 'I get it'
            },
            {
                type: 'action',
                id: '5.2',
                title: 'Operational challenges (2)',
                content: "When a design choice may lead to known operational challenges, this is shown with an exclamation mark in the tool. \n\n" +
                    "* Clicking on this symbol shows which operational challenges might occur and what their implications may be. \n\n" +
                    "It is up to the trial team to determine whether the operational challenge is likely to occur in their trial and if so, whether this could impact e.g. generalisability.\n",
                assignment: "Go back to the question 1 overview by clicking on the < next to the question. Click on one of the exclamation marks next to the answer you chose. \n\n" +
                    "Consider whether the described operational challenge(s) and their possible implications are applicable to your trial. \n",
                imageSideBar: <div className={styles.demoPanelImageBlock} style={{justifyContent: 'flex-start', flexDirection: 'column', width: '134rem'}}>
                    <img className={styles.demoPanelImage}  style={{marginTop: '30rem'}} src={imageExclamationMark}/>
                </div>,
                doneAction: "I am done",
                feedback: "The insights into possible operational challenges can help you take them into account in the early stage of trial design, e.g. re-designing the recruitment strategy to better ensure the inclusion of the envisioned broad patient population.",
                action: 'Next'
            }
        ]
    },
    {
        group: '6',
        title: 'Comparing',
        items: [
            {
                type: 'action',
                id: '6.1',
                title: 'Let’s speed up!',
                content: "We have now looked at all levels in the tool: the design question with the answer options and possible **implications** of each option, and the **operational challenges** with their own possible implications.",
                assignment: "Use the arrows at the bottom of the question screen to move to question 2 and answer question 2 taking into account the possible implications and operational challenges for each option.",
                // doneAction: "I am done",
                checkDoneByPropsFn: (currentProps, nextProps) => {
                    // console.log('checkDoneByPropsFn', currentProps, nextProps);
                    // check if changed to 0 or 1. (maybe not check if this was the first change?)
                    // do we also want to check if the change had already been made before? (check for -1)
                    // const justOpened = nextProps.projectState.projects[0] && !currentProps.gameState.showTrialInfo;
                    // return false;
                    // return nextProps.projectState.projects[0].content.answers[0][1] === 0
                    //     || nextProps.projectState.projects[0].content.answers[0][1] === 1;
                    return nextProps.projectState.projects[0].content.answers[0][1] !== -1;
                },
                feedback: "Great! You can now close the participant window by pressing the X at the bottom. \n\n" +
                    "You can see your progress through the design questions in the tool by looking at\n\n" +
                    "* the yellow shapes which appear in the background;\n" +
                    "* the progress wheel in the bottom left corner. \n" +
                    "* There are 43 questions in total.\n",
                action: 'Next'
            },
            {
                type: 'open',
                id: '6.2',
                title: 'Trial reference',
                content: "In this demo you thought about the study population for the described trial. At the left you can find a short summary of what the researchers did in the actual trial. \n\n" +
                    "* Would you agree with the selected population or would you make different choices, based on the information provided by the tool?\n",
                citation: "“Patient selection criteria were designed to ensure inclusion of individuals at moderate cardiovascular risk but to exclude high-risk subjects who would need more intensive treatment due to their past or current medical history.”",
                source: "[Zamorano et al. (2010) The CRUCIAL Study, Postgraduate Medicine, 122:2, 7-15](https://www.tandfonline.com/doi/abs/10.3810/pgm.2010.03.2117)",
                assignment: "Add your comment to the text box below.",
                doneAction: "Next",
                feedback: "In the CRUCIAL trial they excluded a high-risk population. \n\n" +
                    "* This might limit the learnings from this trial for that population (generalisability). \n" +
                    "* Yet, introducing a population with different expected treatment effects may decrease precision and complicate the interpretation of the trial. \n",
                action: 'I get it'
            },
            {
                type: 'read',
                id: '6.3',
                title: 'Ready to continue?',
                content: "You have now been introduced to the main elements and content levels of the tool. \n\n" +
                    "For a discovery of the export functionalities of the tool and more in-depth training you can walk through step 7 to 9 (5-10 minutes).\n\n" +
                    "Enthusiastic about the tool but no time to continue? Register for free on [www.GetRealTrialTool.eu](https://getrealtrialtool.eu/). For questions or remarks, please contact info@getreal-institute.org \n",
                action: 'Let’s continue!',
                quit: 'I have seen enough',
            }
        ]
    },
    {
        group: '7',
        title: 'Practice',
        optional: true,
        items: [
            {
                type: 'action',
                id: '7.1',
                title: 'Comparator domain',
                content: "We will now explore the comparator domain to answer the following questions: \n\n" +
                    "* How would you operationalise the intervention with the new single-pill combination of an antihypertensive agent and a statin?\n" +
                    "* What would you choose as the comparator, given that you want to compare the new intervention with the currently used strategy?\n",
                assignment: "Open the **comparator** domain (below on the right) and browse through the questions. " +
                    "Answer (a couple of) them and close the question window by pressing the X at the bottom.",
                // doneAction: "I am done",
                checkDoneByPropsFn: (currentProps, nextProps) => {
                    // console.log('checkDoneByPropsFn', currentProps, nextProps);

                    // selectedDomainIndex = 3 to -1
                    const comparatorDomainIndex = 3;
                    const comparatorClosed = nextProps.gameState.selectedDomainIndex === -1
                        && currentProps.gameState.selectedDomainIndex === comparatorDomainIndex;

                    // check at least one answer in the domain
                    let answersGiven = 0;
                    for (const answer of nextProps.projectState.projects[0].content.answers[comparatorDomainIndex]) {
                        // console.log(answer);
                        if (answer !== -1) {
                            answersGiven += 1;
                        }
                    }

                    return comparatorClosed && (answersGiven > 0);
                },
                feedback: "In general, to render RWE, the allocation and implementation of treatment strategies should resemble clinical practice as closely as possible. \n\n" +
                    "* In the [CRUCIAL trial](https://www.tandfonline.com/doi/abs/10.3810/pgm.2010.03.2117) they performed a cluster randomisation where investigators were randomised to either a single-pill treatment regime or to usual care in an open-label fashion.\n" +
                    "* Cluster randomisation may be considered if contamination from the intervention arm to usual care can be expected.\n" +
                    "* You can read more on this topic [here](https://www.sciencedirect.com/science/article/pii/S0895435617307497).\n",
                action: 'Next'
            }
        ]
    },
    {
        group: '8',
        title: 'Overview',
        optional: true,
        items: [
            {
                type: 'system',
                id: '8.1',
                title: 'Progress & overview',
                content: "You have now answered several questions in the tool. You can get a quick overview of the possible implications and challenges per domain by opening a domain and hoovering over the colours and symbols in the domain.",
                assignment: "Reopen the comparator domain and hoover over the colours and symbols. Seeing this overview of possible implications and operational challenges, **is there a question you would like to revisit?**" +
                    "\n\n" +
                    "Close the comparator domain by pressing the X at the bottom.\n",
                checkDoneByPropsFn: (currentProps, nextProps) => {
                    // console.log('checkDoneByPropsFn', currentProps, nextProps);
                    // trigger when comparator is closed?
                    // selectedDomainIndex = 3 to -1
                    const comparatorClosed = nextProps.gameState.selectedDomainIndex === -1
                        && currentProps.gameState.selectedDomainIndex === 3;
                    return comparatorClosed;
                },
                feedback: "In the left bottom corner, next to seeing your progress through the design questions, you can also see the number of operational challenges that may apply and the number of notes made.",
                action: 'Next'
            },
            {
                type: 'system',
                id: '8.2',
                title: 'Export functionalities',
                content: "At this point you may want to discuss your trial design with colleagues. There are two export options available for this. You can use the ‘Fingerprint’ for a graphical view of the possible implications of your design choices.",
                assignment: "Use the ‘Export’-button on the left to open a Fingerprint. It will open in a new tab. \n" +
                    "\n\n" +
                    "After looking at the fingerprint return to this demo.\n",
                continueOnEvent: 'fingerprint',
                feedback: "The fingerprint provides a visual insight into:\n\n" +
                    "* the possible implications of your design choices;\n" +
                    "* the number of design choices which may lead to operational challenges. \n" +
                    "* This can be used to compare trial designs and in presentations. \n",
                action: 'Next'
            },
            {
                type: 'system',
                id: '8.3',
                title: 'Complete overview',
                content: "The second option is to download an Excel file. The Excel file provides a complete overview of:\n\n" +
                    "* all your design choices;\n" +
                    "* their possible implications;\n" +
                    "* the possible operational challenges;\n" +
                    "* all notes you made.\n",
                assignment: "Click on Excel under the Export button and open the file. Look through the different tabs and return to the tool afterwards.",
                continueOnEvent: 'report',
                feedback: "You can use the Excel file for multiple purposes, e.g. to:\n\n" +
                    "* share and discuss tool results with colleagues;\n" +
                    "* review the possible operational challenges for your trial in one list;\n" +
                    "* copy the notes you made to other documents.\n",
                action: 'Next'
            }
        ]
    },
    {
        group: '9',
        title: 'Finish',
        optional: true,
        items: [
            {
                type: 'read',
                id: '9.1',
                title: 'Finish!',
                content: "Congratulations! You have now familiarised yourself with all possibilities of the GetReal Trial Tool.\n" +
                    "Enthusiastic about the tool? Register for free on [www.GetRealTrialTool.eu](https://getrealtrialtool.eu/). For questions or remarks contact info@getreal-institute.org \n",
                action: 'Close demo'
            }
        ]
    }
];


const TargetBlankLink = ({ children, ...props }) => (
    <a {...props} target='_blank'>{children}</a>
);

class Demo extends Component {
    static LEAVE_CONFIRM_MESSAGE = 'Do you want to stop the demo?';

    props: DemoProps;
    project: Project | void;   //store find result for performance reasons
    checkIntervalId: any;

    constructor(props) {
        super(props);
        this.state = {
            showDemoPanel: true,
            demoGroupIdx: 0,
            keepOpenDemoGroupIdx: -1,
            demoItemIdx: 0,
            demoItemStep: 0,
            openInput: '',
            showTip: true,
            debug: false
        };
    }

    componentWillMount() {
        const project: Project = {
            id: DEMO_PROJECT_ID,
            title: 'DEMO GetReal Trial Tool trial',
            acronym: 'GeTTT',
            identifier: '',
            protocol_version_number: '1.0',
            hypothesis: `Hypothesis: In routine clinical practice a single-pill combination of an antihypertensive agent and a statin will have better effectiveness in reducing the cardiovascular risk profile of patients compared with the currently used strategy.
Summary: Treating asymptomatic individuals with only modest elevated blood pressure and cholesterol with multiple drugs presents a challenge to primary care physicians. Studies have demonstrated that patients are more adherent when using a single-pill combination rather than two separate pills (taken concurrently) of the same drugs. The effect of using this single pill has only been tested against placebo in a strictly controlled trial, but has not been demonstrated in the real life setting.
`,
            prerequisites: '',
            sponsor: '',
            name_contact_person: 'GetReal Initiative',
            email_contact_person: 'info@getreal-institute.org',
            team_members: '',
            date: moment(),
        };
        const id = -1;
        this.props.createDemoProject(project)
        // dispatch(postProjectSuccess({
        //     ...project,
        //     id
        // }));
    }
    componentDidMount() {
        window.onbeforeunload = () => this.confirmLeave();
        this.props.router.setRouteLeaveHook(this.props.route, () => this.confirmLeave());

        if (GA_MEASUREMENT_ID) {
            gtag('config', GA_MEASUREMENT_ID, {
                'page_path': `/demo/step/${this.state.demoGroupIdx + 1}.${this.state.demoItemIdx + 1}.${this.state.demoItemStep + 1}`,
                'send_page_view': true
            });
        }

        // schedule checker
        // this.checkIntervalId = setInterval(() => {
        //     // TODO
        //     // if current question is type 'system' and in 'check' state -> do check.
        // }, 500);
    }
    componentWillUnmount() {
        // schedule checker
        // if (this.checkIntervalId) {
        //     clearInterval(this.checkIntervalId);
        // }
        window.onbeforeunload = null;
    }
    scheduleNextStep() {
        const indexesBeforeScheduling = {demoGroupIdx: this.state.demoGroupIdx, demoItemIdx: this.state.demoItemIdx};
        setTimeout(() => {
            if (indexesBeforeScheduling) {
                // check for accidental setting this twice
                if (this.state.demoGroupIdx === indexesBeforeScheduling.demoGroupIdx
                    && this.state.demoItemIdx === indexesBeforeScheduling.demoItemIdx) {
                    console.log('going to next step');
                    if (GA_MEASUREMENT_ID) {
                        gtag('config', GA_MEASUREMENT_ID, {
                            'page_path': `/demo/step/${indexesBeforeScheduling.demoGroupIdx + 1}.${indexesBeforeScheduling.demoItemIdx + 1}.2`,
                            'send_page_view': true
                        });
                    }
                    this.setState({demoItemStep: 1, showDemoPanel: true});
                } else {
                    console.log('prevent going to next step twice');
                }
            } else {
                console.error('unexpected end of the demo');
            }
        }, FEEDBACK_DELAY_SECONDS * 1000);
    }
    componentWillReceiveProps(nextProps) {
        // check if we are waiting for a prop change (system check)
        try {
            const currentItem = demoContent[this.state.demoGroupIdx].items[this.state.demoItemIdx];
            if (currentItem.checkDoneByPropsFn) {
                const done = currentItem.checkDoneByPropsFn(this.props, nextProps);
                if (done) {
                    // schedule delayed show phase 2 of item
                    this.scheduleNextStep()
                }
            }
        } catch (e) {
            // ignore, we don't care if something went wrong in the check
        }
    }
    checkContinueOnEvent(eventName) {
        try {
            const currentItem = demoContent[this.state.demoGroupIdx].items[this.state.demoItemIdx];
            if (currentItem.continueOnEvent) {
                const done = currentItem.continueOnEvent === eventName
                if (done) {
                    // schedule delayed show phase 2 of item
                    this.scheduleNextStep()
                }
            }
        } catch (e) {
            // ignore, we don't care if something went wrong in the check
        }
    }

    confirmLeave() {
        return null;
        // TODO weer activeren
        return Demo.LEAVE_CONFIRM_MESSAGE;
    }
    getNextItemIndexes() {
        const currentGroup = demoContent[this.state.demoGroupIdx];
        const nextInCurrentGroup = currentGroup.items[this.state.demoItemIdx + 1];
        if (nextInCurrentGroup !== undefined) {
            return {demoGroupIdx: this.state.demoGroupIdx, demoItemIdx: this.state.demoItemIdx + 1, demoItemStep: 0, openInput: ''}
        }
        const nextGroup = demoContent[this.state.demoGroupIdx + 1];
        if (nextGroup && nextGroup.items[0] !== undefined) {
            return {demoGroupIdx: this.state.demoGroupIdx + 1, demoItemIdx: 0, demoItemStep: 0, openInput: '', keepOpenDemoGroupIdx: this.state.demoGroupIdx}
        }
        return undefined;
    }
    quit() {
        this.props.exit();
    }
    handleNext() {
        const currentItem = demoContent[this.state.demoGroupIdx].items[this.state.demoItemIdx];
        const nextItemIndexes = this.getNextItemIndexes();
        let toNextItem = false;
        switch (currentItem.type) {
            case "read":
                // check if next
                toNextItem = true;
                break;
            case "system":
                // hide screen
                if (this.state.demoItemStep === 0) {
                    this.setState({showDemoPanel: false})
                } else {
                    toNextItem = true;
                }
                break;
            case "action":
            case "open":
                // hide screen
                if (this.state.demoItemStep === 0) {
                    if (GA_MEASUREMENT_ID) {
                        gtag('config', GA_MEASUREMENT_ID, {
                            'page_path': `/demo/step/${nextItemIndexes.demoGroupIdx + 1}.${nextItemIndexes.demoItemIdx + 1}.2`,
                            'send_page_view': true
                        });
                    }
                    this.setState({demoItemStep: 1})
                } else {
                    toNextItem = true;
                }
            default:
                break;
        }
        if (toNextItem) {
            if (nextItemIndexes) {
                if (GA_MEASUREMENT_ID) {
                    gtag('config', GA_MEASUREMENT_ID, {
                        'page_path': `/demo/step/${nextItemIndexes.demoGroupIdx + 1}.${nextItemIndexes.demoItemIdx + 1}.1`,
                        'send_page_view': true
                    });
                }
                this.setState(nextItemIndexes);
                // schedule closing the group that was kept open (if any) after 1 second
                setTimeout(() => {
                    this.setState({keepOpenDemoGroupIdx: -1});
                }, 5000);
            } else {
                // we're done!
                if (GA_MEASUREMENT_ID) {
                    gtag('config', GA_MEASUREMENT_ID, {
                        'page_path': `/demo/end`,
                        'send_page_view': true
                    });
                }
                this.props.exit();
            }
        }
    }
    renderDemoItem() {
        const currentGroup = demoContent[this.state.demoGroupIdx];
        const currentItem = demoContent[this.state.demoGroupIdx].items[this.state.demoItemIdx];

        //
        const initial = this.state.demoItemStep < 1;
        const markdownOverrides = {
            a: {
                component: TargetBlankLink,
            }
        }

        let mainItemContent;
        let contentParts = [];
        let assignmentPart;
        let openInputPart;
        let nextButtonText;
        if (initial) {
            switch (currentItem.type) {
                case "read":
                    nextButtonText = currentItem.action;
                    break;
                case "action":
                    nextButtonText = currentItem.doneAction;
                    break;
                // case "system":
                case "open":
                    nextButtonText = currentItem.doneAction;
                    openInputPart = <textarea key='open' className={styles.openInput} value={this.state.openInput} onChange={(event) => {
                        this.setState({openInput: event.target.value});
                    }}></textarea>;
                    break;
                default:
                    nextButtonText = undefined;
            }
            assignmentPart = currentItem.assignment ?
                <div className={styles.demoPanelAssignmentBlock} key='assignment'>
                    <div>
                        <img src={imageAssignmentArrow} className={styles.demoPanelAssignmentArrow}/>
                    </div>
                    <div className={styles.demoPanelAssignment}>
                        <div>Assignment:</div>
                        <Markdown options={{
                            overrides: markdownOverrides,
                            disableParsingRawHTML: true
                        }}>{currentItem.assignment}</Markdown>
                    </div>
                </div>
                :
                undefined
            contentParts = [assignmentPart, openInputPart];
        } else {
            // feedback
            assignmentPart = currentItem.feedback ?
                [<div className={styles.demoPanelFeedbackBlock}>
                    <img src={imageFeedbackCheck} className={styles.imageFeedbackCheck}/>
                </div>,
                <div className={styles.demoPanelFeedbackBlock} key='assignment'>
                    <div>
                        <img src={imageAssignmentArrow} className={styles.demoPanelAssignmentArrow}/>
                    </div>
                    <div className={styles.demoPanelFeedback}>
                        <div>Feedback:</div>
                        <Markdown options={{
                            overrides: markdownOverrides,
                            disableParsingRawHTML: true
                        }}>{currentItem.feedback}</Markdown>
                    </div>
                </div>]
                :
                undefined;
            switch (currentItem.type) {
                // case "read":
                // case "action":
                // case "system":
                case "open":
                    openInputPart = <div key='openfeedback' className={styles.openInputFeedback}>{this.state.openInput}</div>
                    contentParts = [openInputPart, assignmentPart];
                    break;
                default:
                    contentParts = [assignmentPart];
            }
            nextButtonText = currentItem.action;
        }

        mainItemContent = <div>
            <div className={styles.demoPanelContentBlocksWrap}>
                {currentItem.imageSideBar ?
                    currentItem.imageSideBar
                    :
                    currentItem.imageSrc ?
                        <div className={styles.demoPanelImageBlock}>
                            <img className={styles.demoPanelImage} src={currentItem.imageSrc}/>
                        </div>
                        :
                        undefined
                }
                <div>
                    <div className={styles.demoPanelContentBlock}>
                        <div className={styles.leftGreenBar}></div>
                        <Markdown options={{
                            overrides: {
                                a: {
                                    component: TargetBlankLink,
                                },
                            },
                            disableParsingRawHTML: true
                        }}>{currentItem.content}</Markdown>
                    </div>
                    {contentParts}
                </div>
            </div>


            <div className={styles.demoButtonContainer}>
                {nextButtonText ? <button className={styles.demoNextButton} onClick={() => {this.handleNext()}}>{nextButtonText}</button> : undefined}
                {currentItem.quit ? <button className={styles.demoNextButton} onClick={() => {this.quit()}}>{currentItem.quit}</button> : undefined}
            </div>
        </div>

        const citationPanel = currentItem.citation ?
            <div className={styles.citationPanel}>
                <div className={styles.citationPanelCitation}>Citation:<br/>{currentItem.citation}</div>
                <div className={styles.citationPanelSource}>Source:<br/><Markdown options={{
                    overrides: {
                        a: {
                            component: TargetBlankLink,
                        },
                    },
                    disableParsingRawHTML: true
                }}>{currentItem.source}</Markdown></div>
            </div>
            :
            <div className={styles.citationPanelPlaceHolder}>

            </div>

        return <div className ={styles.demoPanel}>
            <div className={styles.demoPanelHeading}>
                <div className={styles.demoPanelLeft}>
                    <div className={styles.groupNumber}>{currentGroup.group}</div>
                </div>
                <div className={styles.demoPanelTitle}>
                    <div className={styles.groupTitle}>{currentGroup.title}</div>
                </div>
            </div>
            <div className={styles.demoPanelBody}>
                {citationPanel}
                <div className={styles.demoPanelRight}>
                    <div className={styles.itemTitle}>
                        <span className={styles.itemTitleId}>{currentItem.id}</span>
                        {currentItem.title}
                        {/*({currentItem.type})*/}
                    </div>
                    {mainItemContent}
                </div>
            </div>
        </div>;
    }
    renderDemoMenu() {
        return <div className={styles.demoMenu} key='demo-item-overview'>
            {demoContent.map((demoGroup, groupIdx) => <div key={groupIdx} className={demoGroup.optional ? styles.demoGroupOptional : styles.demoGroup}>
                <div className={styles.demoMenuItem} onClick={() => {
                    if (this.state.debug) {
                        this.setState({demoGroupIdx: groupIdx, demoItemIdx: 0, demoItemStep: 0});
                    }
                }}>
                         {demoGroup.group}. {demoGroup.title} {
                    groupIdx < this.state.demoGroupIdx ?
                        <img src={imageCheckChecked}
                             className={styles.menuCheck}/>
                             :
                        undefined
                }
                </div>
                {
                    this.state.demoGroupIdx === groupIdx || this.state.keepOpenDemoGroupIdx === groupIdx ?
                        <div className={styles.demoMenuItemsContainer}>
                            {
                                demoGroup.items.map((demoItem, demoItemIdx) => <div key={demoItemIdx}>
                                    <div className={styles.demoMenuItem + ' ' + styles.demoMenuSubItem} onClick={() => {
                                        if (this.state.debug) {
                                            this.setState({demoItemIdx: demoItemIdx, demoItemStep: 0});
                                        }
                                    }}>
                                        <span className={(groupIdx === this.state.demoGroupIdx && demoItemIdx === this.state.demoItemIdx ? ' ' + styles.demoMenuItemActiveLabel : '')}>{demoItem.id} {demoItem.title}</span>
                                        {
                                            groupIdx <= this.state.demoGroupIdx && demoItemIdx < this.state.demoItemIdx
                                            || this.state.keepOpenDemoGroupIdx === groupIdx?
                                                <img src={imageCheckChecked} className={styles.menuCheck}/>
                                                :
                                                undefined
                                        }
                                    </div>
                                </div>)
                            }
                        </div>
                        :
                        undefined
                }
            </div>)}
        </div>
    }
    render() {
        const { projectState } = this.props;

        this.project = projectState.projects.find(project => project.id === -1);

        return projectState.isFetching && projectState.fetchType === 'get' ? (
            <Spinner className={styles.spinner} />
        ) : [0, 200].indexOf(projectState.statusCode) === -1 && projectState.fetchType === 'get' ? (
            <div>
                Error loading project.
                <button onClick={() => this.loadProject()}>Try again</button>
            </div>
        ) : this.project ?
            <div className={styles.demoWrapWrap}>
                <ProjectView project={this.project} demoMode={true}
                             onFingerprint={() => {
                                 if (GA_MEASUREMENT_ID) {
                                     gtag('config', GA_MEASUREMENT_ID, {
                                         'page_path': '/demo/export_fingerprint',
                                         'send_page_view': true
                                     });
                                 }
                                 this.checkContinueOnEvent('fingerprint');
                             }}
                             onReport={() => {
                                 if (GA_MEASUREMENT_ID) {
                                     gtag('config', GA_MEASUREMENT_ID, {
                                         'page_path': '/demo/export_excel',
                                         'send_page_view': true
                                     });
                                 }
                                 this.checkContinueOnEvent('report');
                             }}
                             onClickBackground={() => {
                                 console.log('user clicked background!')
                             }}
                />
                <div className={styles.demoWrap}>
                    {
                        this.state.showDemoPanel && this.state.demoItemIdx > -1 ?
                            this.renderDemoItem()
                            :
                            undefined
                    }
                    <div className={styles.demoMenuWrap + (this.state.showDemoPanel ? '' : " " + styles.demoMenuWrapHidden)}>
                        {
                            this.state.showDemoPanel ?
                                [
                                    <h1 key='demo-heading'>DEMO</h1>,
                                    this.renderDemoMenu()
                                ]
                                :
                                undefined
                        }
                    </div>
                    <button onClick={() => {this.togglePanel()}} className={styles.demoToggle + ' ' + (this.state.showDemoPanel ? styles.demoToggleLeft : '')}>
                        {
                            this.state.showDemoPanel ?
                                <img src={arrowRight} />
                                :
                                <img src={arrowLeft} />
                        }
                    </button>
                    {
                        this.state.showTip ?
                            <div className={styles.demoToggleTip}>
                                <div>Minimize your demo</div>
                                <button className={styles.demoToggleTipButton} onClick={() => {
                                    this.setState({showTip: false});
                                }}>close</button>
                            </div>
                            :
                            undefined
                    }
                </div>
                {
                    this.state.showTip ?
                        <div className={styles.demoTipOverlay}></div>
                        :
                        undefined
                }
            </div>
            :
            null;

        // if (this.project) {
        //     return <div className={styles.demoWrapWrap}>
        //         <ProjectView project={this.project} demoMode={true}
        //                      onFingerprint={() => {
        //                          if (GA_MEASUREMENT_ID) {
        //                              gtag('config', GA_MEASUREMENT_ID, {
        //                                  'page_path': '/demo/export_fingerprint',
        //                                  'send_page_view': true
        //                              });
        //                          }
        //                          this.checkContinueOnEvent('fingerprint');
        //                      }}
        //                      onReport={() => {
        //                          if (GA_MEASUREMENT_ID) {
        //                              gtag('config', GA_MEASUREMENT_ID, {
        //                                  'page_path': '/demo/export_excel',
        //                                  'send_page_view': true
        //                              });
        //                          }
        //                          this.checkContinueOnEvent('report');
        //                      }}
        //                      onClickBackground={() => {
        //                          console.log('user clicked background!')
        //                      }}
        //         />
        //         <div className={styles.demoWrap}>
        //             {
        //                 this.state.showDemoPanel && this.state.demoItemIdx > -1 ?
        //                     this.renderDemoItem()
        //                     :
        //                     undefined
        //             }
        //             <div className={styles.demoMenuWrap + (this.state.showDemoPanel ? '' : " " + styles.demoMenuWrapHidden)}>
        //                 {
        //                     this.state.showDemoPanel ?
        //                         [
        //                             <h1 key='demo-heading'>DEMO</h1>,
        //                             this.renderDemoMenu()
        //                         ]
        //                         :
        //                         undefined
        //                 }
        //             </div>
        //             <button onClick={() => {this.togglePanel()}} className={styles.demoToggle + ' ' + (this.state.showDemoPanel ? styles.demoToggleLeft : '')}>
        //                 {
        //                     this.state.showDemoPanel ?
        //                         <img src={arrowRight} />
        //                         :
        //                         <img src={arrowLeft} />
        //                 }
        //             </button>
        //             {
        //                 this.state.showTip ?
        //                     <div className={styles.demoToggleTip}>
        //                         <div>Minimize your demo</div>
        //                         <button className={styles.demoToggleTipButton} onClick={() => {
        //                             this.setState({showTip: false});
        //                         }}>close</button>
        //                     </div>
        //                     :
        //                     undefined
        //             }
        //         </div>
        //         {
        //             this.state.showTip ?
        //                 <div className={styles.demoTipOverlay}></div>
        //                 :
        //                 undefined
        //         }
        //     </div>
        // } else {
        //     return <div>loading</div>
        // }
    }
    togglePanel() {
        this.setState({showDemoPanel: !this.state.showDemoPanel})
    }
}

const mapDispatchToProps = dispatch => ({
    createDemoProject: (project: Project) => {
        dispatch(createDemoProject(project));
    },
    exit: () => {
        window.onbeforeunload = undefined;
        dispatch(cleanUp());
        setTimeout(() => {
            // TODO redirect in another way?
            browserHistory.push('/');
            location.reload();
        });
    }
});

const mapStateToProps = state => ({
    projectState: state.project,
    gameState: state.game
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Demo));