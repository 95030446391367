//@flow

import { browserHistory } from 'react-router';
import request from '../services/request';
import type { HandledResponse } from '../services/request';
import {latestToolContentUrl, projectUrl} from '../constants/apiUrls';
import moment from 'moment';
import {closeTutorial} from "./game";
import {createContentArray} from "../components/Projects/CreateProject/CreateProject";

const removeKey = (k, { [k]:_, ...o }) => o

// state def

export type Project = {
    id: number,
    title: string,
    acronym: string,
    identifier?: string,
    protocol_version_number: string,
    hypothesis?: string,
    prerequisites?: string,
    sponsor?: string,
    name_contact_person?: string,
    email_contact_person: string,
    team_members?: string,
    date: string,
    content: Object,
    hideTutorial: boolean,
    toolContent?: ToolContent,
};

export type ToolContent = {
    id: number,
    versionLabel: string,
    version: number,
    versionMinor: number,
    versionPatch: number,
    data: Object,
}

export type FetchType = 'get' | 'post' | 'put' | 'delete' | '';

export type ProjectState = {
    isFetching: boolean,
    fetchType: FetchType,
    isDirty: boolean,
    statusCode: number,
    projects: Array<Project>,
    latestToolContent: ToolContent
};

const defaultState: ProjectState = {
    isFetching: false,
    fetchType: '',
    isDirty: false,
    statusCode: 0,
    projects: [],
    latestToolContent: undefined
};

// reducer

export const REQUEST_PROJECT = 'REQUEST_PROJECT';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const POST_PROJECTS_SUCCESS = 'POST_PROJECTS_SUCCESS';
export const PUT_PROJECTS_SUCCESS = 'PUT_PROJECTS_SUCCESS';
export const DELETE_PROJECTS_SUCCESS = 'DELETE_PROJECTS_SUCCESS';
export const REQUEST_PROJECT_ERROR = 'REQUEST_PROJECT_ERROR';
export const SELECT_ANSWER = 'SELECT_ANSWER';
export const SAVE_NOTE = 'SAVE_NOTE';
export const CLEAN_UP = 'CLEAN_UP';
export const HIDE_TUTORIAL = 'HIDE_TUTORIAL';

export const REQUEST_LATEST_TOOL_CONTENT = 'REQUEST_LATEST_TOOL_CONTENT';
export const GET_LATEST_TOOL_CONTENT_SUCCESS = 'GET_LATEST_TOOL_CONTENT_SUCCESS';
export const REQUEST_LATEST_TOOL_CONTENT_ERROR = 'REQUEST_LATEST_TOOL_CONTENT_ERROR';

export const reducer = (state: ProjectState = defaultState, action: any) => {
    switch(action.type) {
        case REQUEST_PROJECT:
        case REQUEST_LATEST_TOOL_CONTENT:
            return {
                ...state,
                isFetching: true,
                isDirty: false,
                fetchType: action.fetchType
            };
        case GET_PROJECTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isDirty: false,
                statusCode: 200,
                projects: [...action.projects] || []
            };
        case GET_LATEST_TOOL_CONTENT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isDirty: false,
                statusCode: 200,
                latestToolContent: action.toolContent
            };
        case REQUEST_PROJECT_ERROR:
        case REQUEST_LATEST_TOOL_CONTENT_ERROR:
            return {
                ...state,
                isFetching: false,
                statusCode: action.statusCode
            };
        case GET_PROJECT_SUCCESS:
        case PUT_PROJECTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isDirty: false,
                statusCode: 200,
                projects: [
                    ...state.projects.filter(project => project.id !== action.project.id),
                    action.project
                ]
            };
        case POST_PROJECTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isDirty: false,
                statusCode: 200,
                projects: [
                    ...state.projects,
                    action.project
                ]
            };
        case DELETE_PROJECTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isDirty: false,
                statusCode: 200,
                projects: state.projects.filter(project => project.id !== action.projectId)
            };
        case SELECT_ANSWER: {
            let answers = JSON.parse(JSON.stringify(action.project.content.answers));
            answers[action.domainIndex][action.questionIndex] = action.answerIndex;
            let tempProject = JSON.parse(JSON.stringify(action.project));
            tempProject.content.answers = answers;

            return {
                ...state,
                isDirty: true,
                projects: [
                    ...state.projects.filter(project => project.id !== action.project.id),
                    tempProject
                ]
            };
        }
        case HIDE_TUTORIAL: {
            let tempProject = JSON.parse(JSON.stringify(action.project));
            tempProject.hideTutorial = 1;

            return {
                ...state,
                isDirty: true,
                projects: [
                    ...state.projects.filter(project => project.id !== action.project.id),
                    tempProject
                ]
            };
        }
        case SAVE_NOTE: {
            let notes = action.project.content.notes ? JSON.parse(JSON.stringify(action.project.content.notes)) : [];
            notes[action.domainIndex][action.questionIndex] = {
                label: action.label,
                note: action.note
            };
            let tempProject = JSON.parse(JSON.stringify(action.project));
            tempProject.content.notes = notes;

            return {
                ...state,
                isDirty: true,
                projects: [
                    ...state.projects.filter(project => project.id !== action.project.id),
                    tempProject
                ]
            };
        }
        case CLEAN_UP:
            return {
                ...state,
                isDirty: false
            };
        default:
            return state;
    }
};

// actions

export const requestProject = (fetchType: FetchType) => {
    return {
        type: REQUEST_PROJECT,
        fetchType
    };
};

export const requestToolContent = (fetchType: FetchType) => {
    return {
        type: REQUEST_LATEST_TOOL_CONTENT,
        fetchType
    };
};

export const getProjectsSuccess = (projects: Array<Project>) => {
    return {
        type: GET_PROJECTS_SUCCESS,
        projects
    };
};

export const getProjectSuccess = (project: Project) => {
    return {
        type: GET_PROJECT_SUCCESS,
        project
    };
};

export const loadLatestToolContentSuccess = (content: ToolContent) => {
    return {
        type: GET_LATEST_TOOL_CONTENT_SUCCESS,
        toolContent: content
    };
};


export const postProjectSuccess = (project: Project) => {
    return {
        type: POST_PROJECTS_SUCCESS,
        project
    };
};

export const putProjectSuccess = (project: Project) => {
    return {
        type: PUT_PROJECTS_SUCCESS,
        project
    };
};

export const deleteProjectSuccess = (projectId: number) => {
    return {
        type: DELETE_PROJECTS_SUCCESS,
        projectId
    };
};

export const requestProjectError = (statusCode: number = -1) => {
    return {
        type: REQUEST_PROJECT_ERROR,
        statusCode
    };
};

export const requestToolContentError = (statusCode: number = -1) => {
    return {
        type: REQUEST_LATEST_TOOL_CONTENT_ERROR,
        statusCode
    };
};

const convertApiProject = (apiData: Object): Project => ({
    ...apiData,
    content: apiData.content ? JSON.parse(apiData.content) : {},
});

export const loadProjects = () => (dispatch: Function) => {
    dispatch(requestProject('get'));

    request.execute(projectUrl(), {credentials: 'include'}).then(
        (response: HandledResponse) => {
            dispatch(getProjectsSuccess(response.body.data.map(convertApiProject)));
        },
        (response: HandledResponse) => {
            dispatch(requestProjectError(response.response.status));
        }
    )
};

export const loadProject = (projectId: number) => (dispatch: Function) => {
    dispatch(requestProject('get'));

    request.execute(projectUrl(projectId), {credentials: 'include'}).then(
        (response: HandledResponse) => {
            dispatch(getProjectSuccess(convertApiProject(response.body.data)));
        },
        (response: HandledResponse) => {
            dispatch(requestProjectError(response.response.status));
        }
    )
};

export const loadLatestToolContent = () => (dispatch: Function) => {
    dispatch(requestToolContent('get'));
    return request.execute(latestToolContentUrl(), {credentials: 'include'}).then(
        (response: HandledResponse) => {
            dispatch(loadLatestToolContentSuccess(convertApiProject(response.body.data)));
            return response.body.data;
        },
        (response: HandledResponse) => {
            dispatch(requestToolContentError(response.response.status));
        }
    )
};

export const createDemoProject = (projectData) => (dispatch: Function) => {
    loadLatestToolContent()(dispatch).then((latestToolContent) => {
        projectData.content = {
            answers: createContentArray(latestToolContent.data),
            notes: createContentArray(latestToolContent.data)
        }
        projectData.toolContent = latestToolContent;
        dispatch(postProjectSuccess(projectData));
        dispatch(closeTutorial());
    }, (e) => {
        // TODO handle error!
        console.error(e);
    });

    //     .then((data) => {
    //     console.log('data', data);
    //
    // }, (e) => {console.error('error', e)})

}

export const createProject = (project: Project) => (dispatch: Function) => {
    dispatch(requestProject('post'));

    request.execute(projectUrl(), {
        method: 'POST',
        body: {
            ...project,
            content: JSON.stringify(project.content),
            hideTutorial: 0
        },
        credentials: 'include'
    }).then(
        (response: HandledResponse) => {
            const id = response.body.data.id;
            dispatch(postProjectSuccess({
                ...project,
                id
            }));
            browserHistory.push(`/game/${id}`);
        },
        (response: HandledResponse) => {
            dispatch(requestProjectError(response.response.status));
        }
    );
};

const getDuplicationTitle = (title: string, existingTitles: Array<string>) => {
    const regex = /^(.+?)(?:\((\d+)\))?$/; // matches filename(1) -> filename, 1
    const matches = title.match(regex) || [];
    const baseTitle = matches[1] || '';

    const highestExistingCopyNumber = existingTitles.reduce((highest, title) => {
        const matches = title.match(regex) || [];
        const numberVal = Number(matches[2]) || 0;
        return matches[1] === baseTitle && numberVal > highest ? numberVal : highest;
    }, 0);

    return `${baseTitle}(${highestExistingCopyNumber + 1})`;
};

export const saveProject = (project: Project) => (dispatch: Function) => {
    dispatch(requestProject('put'));

    let total = 0;
    let answered = 0;

    for(let domain in project.content.answers) {
        if(project.content.answers.hasOwnProperty(domain)) {
            for(let question in project.content.answers[domain]) {
                if(project.content.answers[domain].hasOwnProperty(question)) {
                    if(project.content.answers[domain][question] > -1){
                        answered ++;
                    }
                    total ++;
                }
            }
        }
    }

    request.execute(projectUrl(project.id), {
        method: 'PUT',
        body: {
            ...(removeKey('toolContent', project)),
            content: JSON.stringify(project.content),
            isCompleted: answered === total,
            toolContent: project.toolContent ? project.toolContent.id : null // TODO null is an error!
        },
        credentials: 'include'
    }).then(
        (response: HandledResponse) => {
            const id = response.body.data.id;
            dispatch(putProjectSuccess({
                ...project,
                id
            }));
        },
        (response: HandledResponse) => {
            dispatch(requestProjectError(response.response.status));
        }
    );
};

export const getProjectAndDuplicate = (project: Project, redirect: boolean) => (dispatch: Function, getState: Function) => {
    dispatch(requestProject('post'));
    
    request.execute(projectUrl(project.id), {credentials: 'include'}).then(
        (response: HandledResponse) => {

            request.execute(projectUrl(), {
                method: 'POST',
                body: {
                    ...(removeKey('id', response.body.data)),
                    content: response.body.data.content,
                    title: getDuplicationTitle(response.body.data.title, getState().project.projects.map(project => project.title)),
                    acronym: getDuplicationTitle(response.body.data.acronym, getState().project.projects.map(project => project.acronym)),
                    date: moment(),
                    toolContent: project.toolContent ? project.toolContent.id : null // TODO null is an error!
                },
                credentials: 'include'
            }).then(
                (response: HandledResponse) => {
                    const id = response.body.data.id;
                    dispatch(postProjectSuccess(convertApiProject(response.body.data)));
                    if(redirect){
                        browserHistory.push(`/game/${id}`);
                    }
                },
                (response: HandledResponse) => {
                    dispatch(requestProjectError(response.response.status));
                }
            );
        },
        (response: HandledResponse) => {
            dispatch(requestProjectError(response.response.status));
        }
    )
}

export const duplicateProject = (project: Project, redirect: boolean) => (dispatch: Function, getState: Function) => {
    dispatch(requestProject('post'));
    request.execute(projectUrl(), {
        method: 'POST',
        body: {
            ...project,
            content: JSON.stringify(project.content),
            title: getDuplicationTitle(project.title, getState().project.projects.map(project => project.title)),
            acronym: getDuplicationTitle(project.acronym, getState().project.projects.map(project => project.acronym)),
            date: moment(),
            toolContent: project.toolContent ? project.toolContent.id : null // TODO null is an error!
        },
        credentials: 'include'
    }).then(
        (response: HandledResponse) => {
            const id = response.body.data.id;

            dispatch(postProjectSuccess(convertApiProject(response.body.data)));

            if(redirect){
                browserHistory.push(`/game/${id}`);
            }
        },
        (response: HandledResponse) => {
            dispatch(requestProjectError(response.response.status));
        }
    );
};

export const deleteProject = (projectId: number) => (dispatch: Function) => {
    dispatch(requestProject('delete'));

    request.execute(projectUrl(projectId), {
        method: 'DELETE',
        credentials: 'include'
    }).then(
        () => {
            dispatch(deleteProjectSuccess(projectId))
        },
        (response: HandledResponse) => {
            dispatch(requestProjectError(response.response.status));
        }
    );
};

export const selectAnswer = (project: Project, domainIndex: number, questionIndex: number, answerIndex: number) => {
    return {
        type: SELECT_ANSWER,
        project: project,
        domainIndex: domainIndex,
        questionIndex: questionIndex,
        answerIndex: answerIndex
    };
};

export const saveNote = (project: Project, domainIndex: number, questionIndex: number, label:number, note:string) => {
    return {
        type: SAVE_NOTE,
        project: project,
        domainIndex: domainIndex,
        questionIndex: questionIndex,
        label: label,
        note: note
    };
};

export const cleanUp = () => {
    return {
        type: CLEAN_UP
    };
};

export const hideTutorial = (project: Project) => {
    return {
        type: HIDE_TUTORIAL,
        project: project
    };
};