exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css"), "");
// Module
exports.push([module.id, ".src-components-Sources-Sources__sources--36YqM{font-family:Quicksand,sans-serif;font-weight:700;font-size:11rem;margin-top:2rem}.src-components-Sources-Sources__link--1-FLg{color:" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["gameBackgroundColor"] + ";text-decoration:underline}", ""]);
// Exports
exports.locals = {
	"gameBackgroundColor": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["gameBackgroundColor"] + "",
	"sources": "src-components-Sources-Sources__sources--36YqM",
	"link": "src-components-Sources-Sources__link--1-FLg"
};