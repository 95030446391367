// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { Project } from '../../redux/project';
import { openTerminology, openTrialInfo } from '../../redux/game';
import styles from './GameHeader.css';
import { Icon } from '../';
import logoImage from '../../assets/logo.svg';
import info from '../../assets/i_button.svg';

type GameHeaderProps = {
    project: Project,
    email: string,
    openTerminology: void,
    openTrialInfo: void,
    legend: boolean
};

const GameHeader = (props: GameHeaderProps) => (
    <div className={styles.gameHeader}>
        <div className={styles.bgContainer} >
            <button className={styles.infoIcon} onClick={() => props.openTerminology()}>
                <Icon glyph={info} height={23} width={23} />
            </button>
        </div>
        <div className={styles.projectName} onClick={() => props.openTrialInfo()}>
            <h1>{props.project.acronym || props.project.title}</h1>
            <div>{props.email}</div>
        </div>
        <div className={styles.headerContent}>
            <img src={logoImage} className={styles.logoImage} />
        </div>
        {
            props.legend ?
                <div className={styles.indexColors}>
                    {/*<Icon glyph={bg} className={styles.bg}/>*/}
                    {/*<div className={styles.mostOptimal}>Optimal</div>*/}
                    {/*<div className={styles.improvements}>Opportunity for improvement</div>*/}
                    {/*<div className={styles.moreImprovements}>More opportunity for improvement</div>*/}
                    {/*<div className={styles.noImplications}>No impact</div>*/}
                </div>
                :
                null
        }

    </div>
);

const mapStateToProps = state => ({
    email: state.login.email
});

const mapDispatchToProps = dispatch => ({
    openTerminology: () => {
        dispatch(openTerminology());
    },
    openTrialInfo: () => {
        dispatch(openTrialInfo());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(GameHeader);