// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { Project } from '../../redux/project';
import { saveNote } from '../../redux/project';
import { openNote, closeNote } from '../../redux/game';
import styles from './Notes.css';
import styleGuide from '../../styleGuide.css';
import { Icon } from '../';
import noteDarkPurple from '../../assets/Note_icon_donker_paars.svg';
import noteYellow from '../../assets/Note_icon_geel.svg';
import noteOrange from '../../assets/Note_icon_oranje.svg';
import IconNoteButton from '../Icon/IconNoteButton';
import editNoteYellow from '../../assets/change_button_regular_geel.svg';
import editNoteOrange from '../../assets/change_button_regular_oranje.svg';

type NoteProps = {
    questionIndex: number,
    domainIndex: number,
    onSave: Function,
    project: Project,
    label: number,
    openNote: Function,
    closeNote: Function,
    noteOpened: boolean
};

class Notes extends Component {

    hasNote = () => {
        return (this.props.project.content.notes[this.props.domainIndex][this.props.questionIndex] !== -1 && this.props.project.content.notes[this.props.domainIndex][this.props.questionIndex].note !== '');
    };

    getLabel = () => {
        return this.hasNote() ? +this.props.project.content.notes[this.props.domainIndex][this.props.questionIndex].label : 1;
    };

    getNote = () => {
        return this.hasNote() ? this.props.project.content.notes[this.props.domainIndex][this.props.questionIndex].note : '';
    };

    state = {
        label: this.getLabel(),
        note: this.getNote()
    };

    props: NoteProps;

    onSubmit(event: Event) {
        event.preventDefault();
        this.props.onSave(this.props.project, this.props.domainIndex, this.props.questionIndex, this.state.label, this.state.note);
        this.setState({label: -1, note: ''});
    }

    componentWillReceiveProps(nextProps: NoteProps) {
        this.setState({label: this.getLabel(), note: this.getNote()});
    }

    render() {
        return (
            <div className={styles.noteIcon}>
                {!this.props.noteOpened ?
                    <div>
                        {this.props.label ?
                            <span>
                                <button onClick={() => this.props.openNote(this.props.domainIndex, this.props.questionIndex)}>
                                    {!this.hasNote() ?
                                        <IconNoteButton /> :
                                        <span>
                                            {this.getLabel() === 1 && this.getNote() !== '' && (
                                                <Icon glyph={editNoteYellow} height={36} width={103} />
                                            )}
                                            {this.getLabel() === 2 && this.getNote() !== '' && (
                                                <Icon glyph={editNoteOrange} height={36} width={103} />
                                            )}
                                        </span>
                                    }
                                </button>
                            </span> :
                            <span>
                                <button onClick={() => this.props.openNote(this.props.domainIndex, this.props.questionIndex)}>
                                    {!this.hasNote() ?
                                        <Icon glyph={noteDarkPurple} height={36} width={36} /> :
                                        <span>
                                            {this.getLabel() === 1 && this.getNote() !== '' && (
                                                <Icon glyph={noteYellow} height={36} width={36} />
                                            )}
                                            {this.getLabel() === 2 && this.getNote() !== '' && (
                                                <Icon glyph={noteOrange} height={36} width={36} />
                                            )}
                                        </span>
                                    }
                                </button>
                            </span>
                        }
                    </div>
                    :
                    <div>
                        <div className={styles.notePopup}>
                            <div className={styles.header}>
                                {!this.props.noteOpened &&
                                    <div className={styles.alignRight}>
                                        {this.props.label ?
                                            <span>
                                                <button onClick={() => this.props.closeNote()}>
                                                    {!this.hasNote() ?
                                                        <IconNoteButton /> :
                                                        <span>
                                                            {this.getLabel() === 1 && this.getNote() !== '' && (
                                                                <Icon glyph={editNoteYellow} height={36} width={103} />
                                                            )}
                                                            {this.getLabel() === 2 && this.getNote() !== '' && (
                                                                <Icon glyph={editNoteOrange} height={36} width={103} />
                                                            )}
                                                        </span>
                                                    }
                                                </button>
                                            </span> :
                                            <span>
                                                <button onClick={() => this.props.closeNote()}>
                                                    {!this.hasNote() ?
                                                        <Icon glyph={noteDarkPurple} height={36} width={36} /> :
                                                        <span>
                                                            {this.getLabel() === 1 && this.getNote() !== '' && (
                                                                <Icon glyph={noteYellow} height={36} width={36} />
                                                            )}
                                                            {this.getLabel() === 2 && this.getNote() !== '' && (
                                                                <Icon glyph={noteOrange} height={36} width={36} />
                                                            )}
                                                        </span>
                                                    }
                                                </button>
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                            <form onSubmit={event => this.onSubmit(event)} className={styles.form}>
                                <div>
                                    <span>
                                        <input onChange={event => this.setState({label: +event.target.value})} checked={this.state.label === 1} type="radio" name="group1" id="r1" value="1" />
                                        <label htmlFor="r1" className={styles.label + ' ' + styles.yellow}>Note</label>
                                    </span>
                                    <span>
                                        <input onChange={event => this.setState({label: +event.target.value})} checked={this.state.label === 2} type="radio" name="group1" id="r2" value="2" />
                                        <label htmlFor="r2" className={styles.label + ' ' + styles.orange}>Action</label>
                                    </span>
                                </div>
                                <textarea
                                    onChange={event => this.setState({note: event.target.value})}
                                    rows="4"
                                    placeholder="Add note..."
                                    defaultValue={this.state.note}
                                    className={styles.textArea}
                                >
                                </textarea>
                                <div className={styles.buttons}>
                                    <button className={styleGuide.buttonSecondary} onClick={(e) => {e.preventDefault(); this.props.closeNote()}}>Cancel</button>
                                    <button type="submit" className={styles.saveButton} disabled={this.state.label === -1}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    noteOpened: state.game.noteOpened,
});

const mapDispatchToProps = dispatch => ({
    openNote: (domainIndex: number, questionIndex: number) => {
        dispatch(openNote(domainIndex, questionIndex));
    },
    closeNote: () => {
        dispatch(closeNote());
    },
    onSave: (project: Project, domainIndex: number, questionIndex: number, label:number, note:string) => {
        dispatch(saveNote(project, domainIndex, questionIndex, label, note));
        dispatch(closeNote());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Notes);