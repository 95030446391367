exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css"), "");
// Module
exports.push([module.id, ".src-components-GameOptions-GameOptions__options--1yJbY{position:absolute;left:0;top:100rem}.src-components-GameOptions-GameOptions__options--1yJbY,.src-components-GameOptions-GameOptions__options--1yJbY ul{padding:0}.src-components-GameOptions-GameOptions__button--3ecsL{position:absolute;top:0;left:0;background-color:#000;height:36rem;width:160rem;text-align:left;color:#fff;border:none;margin-bottom:4rem;cursor:pointer;display:table}.src-components-GameOptions-GameOptions__demoButton--7zHQo{background-color:" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["demoBgMedium"] + "}.src-components-GameOptions-GameOptions__button--3ecsL p{display:table-cell;vertical-align:middle;padding-left:24rem;padding-top:3rem;padding-bottom:3rem}.src-components-GameOptions-GameOptions__button--3ecsL.src-components-GameOptions-GameOptions__active--MSB0H{background-color:#a1a8aa}.src-components-GameOptions-GameOptions__button--3ecsL:hover{background-color:#445155}.src-components-GameOptions-GameOptions__button--3ecsL.src-components-GameOptions-GameOptions__active--MSB0H:hover{background-color:#a1a8aa}.src-components-GameOptions-GameOptions__disabled--nQ71L{cursor:auto}.src-components-GameOptions-GameOptions__children--DDJ8q .src-components-GameOptions-GameOptions__button--3ecsL{background-color:#445155}.src-components-GameOptions-GameOptions__children--DDJ8q .src-components-GameOptions-GameOptions__button--3ecsL p:before{content:\"\";display:inline-block;border-radius:50%;border:2rem solid #fff;vertical-align:middle;margin-right:5rem;width:0;height:0}.src-components-GameOptions-GameOptions__children--DDJ8q .src-components-GameOptions-GameOptions__button--3ecsL:hover{background-color:#737d7f}", ""]);
// Exports
exports.locals = {
	"demoBgMedium": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["demoBgMedium"] + "",
	"options": "src-components-GameOptions-GameOptions__options--1yJbY",
	"button": "src-components-GameOptions-GameOptions__button--3ecsL",
	"demoButton": "src-components-GameOptions-GameOptions__demoButton--7zHQo",
	"active": "src-components-GameOptions-GameOptions__active--MSB0H",
	"disabled": "src-components-GameOptions-GameOptions__disabled--nQ71L",
	"children": "src-components-GameOptions-GameOptions__children--DDJ8q"
};