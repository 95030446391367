// @flow

import React, { Component } from 'react';
import { Motion, spring } from 'react-motion';
import { connect } from 'react-redux';
import styles from './GameApiActivity.css';
import type { FetchType } from '../../redux/project';

type GameApiActivityProps = {
    isFetching: boolean,
    fetchType: FetchType,
    statusCode: number
};

const mapStateToProps = state => ({
    isFetching: state.project.isFetching,
    fetchType: state.project.fetchType,
    statusCode: state.project.statusCode
});

class GameApiActivity extends Component {
    props: GameApiActivityProps;

    SAVED_DURATION = 5000;
    state = {
        message: '',
        show: false
    };
    updateTimeout: number;

    componentWillReceiveProps(newProps: GameApiActivityProps) {
        if(newProps.isFetching !== this.props.isFetching) {
            clearTimeout(this.updateTimeout);
            const isSaving = newProps.isFetching && ['put', 'post'].indexOf(newProps.fetchType) !== -1;
            const wasSaving = !newProps.isFetching && ['put', 'post'].indexOf(this.props.fetchType) !== -1;

            if(isSaving) {
                this.show('Saving...');
            } else if(wasSaving) {
                if(newProps.statusCode >= 200 && newProps.statusCode < 300) {
                    this.show('Changes saved!');
                    this.updateTimeout = setTimeout(this.hide, this.SAVED_DURATION);
                } else {
                    this.show('Error, changes not saved!');
                }
            }
        }
    }

    hide = () => this.setState({show: false});
    show = (message: string) => this.setState({message, show: true});

    render() {
        return (
            <Motion
                style={{
                    x: spring(this.state.show ? 0 : 100, {stiffness: 160, damping: 20})
                }}
            >
                {({ x }) => {

                    return (
                        <div className={styles.view} style={{transform: `translateX(${x}%)`}}>
                            {this.state.message}
                        </div>
                    );
                }}
            </Motion>
        );
    }
}

export default connect(mapStateToProps)(GameApiActivity);