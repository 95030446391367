import React from 'react';
import styles from './IconNoteButton.css';

const IconNoteButton = () => (
    <svg x="0px" y="0px" width="82rem" height="36rem" viewBox="0 0 82 36" className={styles.svg}>
        <g>
            <g>
                <g>
                    <path d="M77.1,22.8V5.2H5v26.2h63.6L77.1,22.8z M69,23.1h6.2L69,29.3V23.1z M6.2,6.4h69.7v15.5h-8.2v8.2H6.2V6.4L6.2,6.4z"/>
                    <path d="M77.1,22.8V5.2H5v26.2h63.6L77.1,22.8z"/>
                    <polygon style={{fill: "#FFF7B5"}} points="6.2,6.4 75.9,6.4 75.9,21.9 67.7,21.9 67.7,30.2 6.2,30.2 6.2,6.4 			"/>
                    <polygon style={{fill: "#F8E135"}} points="69,23.1 75.2,23.1 69,29.3 			"/>
                </g>
                <g>
                    <path d="M77.1,22.8V5.2H5v26.2h63.6L77.1,22.8z M69,23.1h6.2L69,29.3V23.1z M6.2,6.4h69.7v15.5h-8.2v8.2H6.2V6.4L6.2,6.4z"/>
                    <path d="M77.1,22.8V5.2H5v26.2h63.6L77.1,22.8z"/>
                    <polygon className={styles.background} points="6.2,6.4 75.9,6.4 75.9,21.9 67.7,21.9 67.7,30.2 6.2,30.2 6.2,6.4 			"/>
                    <polygon style={{fill: "#A089B6"}} points="69,23.1 75.2,23.1 69,29.3 			"/>
                </g>
            </g>
        </g>
        <g>
            <path fill="#FFFFFF" d="M17.8,20.9l-0.5-1.4h-2.7l-0.5,1.4c-0.1,0.3-0.3,0.4-0.5,0.4c-0.3,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.1-0.4-0.1-0.6l2.4-6.5c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.3,0,0.5,0.1,0.6,0.4l2.4,6.5c0.1,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.3-0.5,0.3C18.1,21.3,17.9,21.1,17.8,20.9z M15.1,18.2h1.8L16,15.9L15.1,18.2z"/>
            <path fill="#FFFFFF" d="M25.3,20.7c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3,0-0.4-0.1s-0.2-0.2-0.2-0.3c-0.5,0.3-1,0.5-1.5,0.5c-0.7,0-1.4-0.3-1.9-0.8S20,19.3,20,18.5c0-0.8,0.3-1.4,0.8-1.9c0.5-0.5,1.2-0.8,1.9-0.8c0.5,0,1,0.2,1.5,0.5v-2.1c0-0.2,0.1-0.3,0.2-0.4s0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4V20.7zM22.7,20.1c0.4,0,0.8-0.2,1-0.5c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.6-0.5-1-0.5c-0.4,0-0.8,0.2-1,0.5c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1C21.9,19.9,22.3,20.1,22.7,20.1z"/>
            <path fill="#FFFFFF" d="M32.6,20.7c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3,0-0.4-0.1s-0.2-0.2-0.2-0.3c-0.5,0.3-1,0.5-1.5,0.5c-0.7,0-1.4-0.3-1.9-0.8s-0.8-1.2-0.8-1.9c0-0.8,0.3-1.4,0.8-1.9c0.5-0.5,1.2-0.8,1.9-0.8c0.5,0,1,0.2,1.5,0.5v-2.1c0-0.2,0.1-0.3,0.2-0.4s0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4V20.7z M29.9,20.1c0.4,0,0.8-0.2,1-0.5c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.6-0.5-1-0.5c-0.4,0-0.8,0.2-1,0.5c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1C29.2,19.9,29.5,20.1,29.9,20.1z"/>
            <path fill="#FFFFFF" d="M39.4,20.7c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2s-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4v-4.3c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.4,0.1,0.5,0.3c0.4-0.2,0.7-0.3,1.2-0.3c0.6,0,1.2,0.2,1.6,0.7c0.4,0.5,0.7,1,0.7,1.6v2.6c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4v-2.6c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8V20.7z"/>
            <path fill="#FFFFFF" d="M47.3,21.3c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.2-0.8-1.9c0-0.8,0.3-1.4,0.8-2c0.5-0.5,1.1-0.8,1.9-0.8c0.7,0,1.3,0.3,1.9,0.8s0.8,1.2,0.8,2s-0.3,1.4-0.8,1.9C48.7,21,48.1,21.3,47.3,21.3z M46.3,17.4c-0.3,0.3-0.4,0.7-0.4,1.1s0.1,0.8,0.4,1.2c0.3,0.3,0.6,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.7,0.4-1.2s-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1-0.4S46.6,17.1,46.3,17.4z"/>
            <path fill="#FFFFFF" d="M53.7,15.8c0.2,0,0.3,0.1,0.4,0.2s0.2,0.3,0.2,0.4s-0.1,0.3-0.2,0.4S53.8,17,53.7,17h-0.3V20c0.2,0,0.3,0.1,0.4,0.2s0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.3,0-0.6-0.1-0.9-0.4s-0.4-0.5-0.4-0.9V17h-0.3c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4s0.3-0.2,0.4-0.2h0.3v-1.6c0-0.2,0.1-0.3,0.2-0.4s0.3-0.2,0.4-0.2s0.3,0.1,0.4,0.2s0.2,0.3,0.2,0.4v1.6H53.7z"/>
            <path fill="#FFFFFF" d="M61.1,18.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1h-3.5c0.1,0.3,0.2,0.6,0.4,0.7c0.2,0.3,0.6,0.4,1,0.5c0.5,0.1,0.8,0,1.2-0.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0,0.4,0.1c0.2,0.2,0.2,0.5,0,0.7c-0.5,0.5-1.2,0.7-1.9,0.7c-0.8,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.2-0.8-1.9c0-0.8,0.3-1.4,0.8-1.9c0.5-0.5,1.1-0.8,1.9-0.8s1.4,0.2,1.9,0.7C60.8,17,61.1,17.6,61.1,18.4L61.1,18.4z M58.5,16.8c-0.4,0-0.8,0.2-1,0.4c-0.3,0.2-0.4,0.5-0.5,0.8h3.1c-0.1-0.3-0.2-0.5-0.5-0.7C59.3,17,58.9,16.9,58.5,16.8z"/>
        </g>
    </svg>

);

export default IconNoteButton;