exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css"), "");
// Module
exports.push([module.id, ".src-components-ImplicationLabels-ImplicationLabels__implicationWrapper--1PhxA{height:94rem;margin-left:2rem;position:relative;overflow:hidden}.src-components-ImplicationLabels-ImplicationLabels__labelWrapper--3o78L{position:absolute;margin-top:94rem;-webkit-transform:translateZ(0);transform:translateZ(0)}.src-components-ImplicationLabels-ImplicationLabels__labelParent--OG3rP{width:34rem;margin:2rem;position:relative}.src-components-ImplicationLabels-ImplicationLabels__label--2VgER{position:absolute;-webkit-transform:translate(-37rem,-87rem) rotate(313deg);transform:translate(-37rem,-87rem) rotate(313deg);white-space:nowrap;width:172rem;background-color:#fff;padding-left:33rem;padding-right:30rem;line-height:27rem;color:#000;font-size:14rem;cursor:pointer}.src-components-ImplicationLabels-ImplicationLabels__label--2VgER:hover,.src-components-ImplicationLabels-ImplicationLabels__labelHover--3LxUQ{background-color:" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["lightYellowColor"] + "}.src-components-ImplicationLabels-ImplicationLabels__activeLabel--3sUEG,.src-components-ImplicationLabels-ImplicationLabels__labelHover--3LxUQ{}.src-components-ImplicationLabels-ImplicationLabels__activeLabel--3sUEG{background-color:grey;-webkit-transform:translate(-34rem,-85rem) rotate(313deg);transform:translate(-34rem,-85rem) rotate(313deg)}.src-components-ImplicationLabels-ImplicationLabels__expandButton--3LV49{position:absolute;-webkit-transform:translate(50rem,46rem);transform:translate(50rem,46rem);background-color:#6a5a77;color:#fff;font-size:22rem;line-height:24rem;padding:0 9rem 4rem 30rem}", ""]);
// Exports
exports.locals = {
	"lightYellowColor": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../../node_modules/postcss-loader/src/index.js!../../styleGuide.css").locals["lightYellowColor"] + "",
	"implicationWrapper": "src-components-ImplicationLabels-ImplicationLabels__implicationWrapper--1PhxA",
	"labelWrapper": "src-components-ImplicationLabels-ImplicationLabels__labelWrapper--3o78L",
	"labelParent": "src-components-ImplicationLabels-ImplicationLabels__labelParent--OG3rP",
	"label": "src-components-ImplicationLabels-ImplicationLabels__label--2VgER",
	"labelHover": "src-components-ImplicationLabels-ImplicationLabels__labelHover--3LxUQ src-components-ImplicationLabels-ImplicationLabels__label--2VgER",
	"activeLabel": "src-components-ImplicationLabels-ImplicationLabels__activeLabel--3sUEG src-components-ImplicationLabels-ImplicationLabels__label--2VgER",
	"expandButton": "src-components-ImplicationLabels-ImplicationLabels__expandButton--3LV49"
};