// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectDomain } from '../../redux/game';
import type { Domain } from '../../types';
import styles from './DomainSelection.css';
import { Icon } from '../';
import spinWheelSvg from '../../assets/spin-control-active.svg';
import spinWheelInactiveSvg from '../../assets/spin-control-off.svg';
import maskSvg from '../../assets/spin-control-overlay.svg';
import { Motion, spring } from 'react-motion';

type DomainSelectionProps = {
    selectedDomainIndex: number,
    showTutorial: boolean,
    domains: Array<Domain>,
    onSelect: (domainIndex: number) => void,
    hideTutorial: boolean
};

const getOffsetAngle = (currentIndex, newIndex) => (360 / 7) * (
    Math.abs(newIndex - currentIndex) < 3.5 ?
        //go directly
        newIndex - currentIndex :
        //go the other way
        (newIndex > currentIndex ? -7 : 7) + newIndex - currentIndex
);

class DomainSelection extends Component {
    props: DomainSelectionProps;

    constructor(props:any) {
        super(props);
        this.state = {
            currentAngle: getOffsetAngle(0, props.selectedDomainIndex),
            currentIndex: props.selectedDomainIndex,
            tutorialChanged: false
        };
    }

    componentWillReceiveProps(newProps: DomainSelectionProps) {
        if(newProps.selectedDomainIndex === -1) {
            this.setState({
                currentIndex: -1,
                currentAngle: getOffsetAngle(0, newProps.selectedDomainIndex)
            });
        }

        const tutorialChanged = this.props.showTutorial !== newProps.showTutorial;

        this.setState({
            tutorialChanged: tutorialChanged,
        });

        if(tutorialChanged){
            setTimeout(function() {
                if(this.refs.mounted){
                    this.setState({
                        tutorialChanged: false
                    });
                }
            }.bind(this), 100);
        }
    }

    hoverOnSpinWheel(index){
        this.setState({
            currentAngle: this.state.currentAngle + getOffsetAngle(this.state.currentIndex, index),
            currentIndex: index
        });
    }

    hoverOffSpinWheel() {
        this.setState({
            currentIndex: this.props.selectedDomainIndex,
            currentAngle: this.state.currentAngle +
                getOffsetAngle(this.state.currentIndex, this.props.selectedDomainIndex)
        });
    }

    render() {
        const props = this.props;
        const { hideTutorial, showTutorial } = this.props;
        const showMain = hideTutorial || !showTutorial;

        const spinWheelTriangles = ["50,45,50,3,72,1,89,17", "52,48,90,19,99,34,97,61", "48,49,99,63,97,89,70,95", "49,55,28,96,46,99,69,97", "46,52,0,64,4,81,24,93", "44,49,2,61,3,35,9,23", "46,44,12,18,23,5,46,4"];

        return (
            <div
                className={styles.selection}
                onMouseLeave={() => this.hoverOffSpinWheel()}
                ref="mounted"
            >
                <Motion
                    style={{
                            //opacity: spring(showMain ? 1 : 0, {stiffness: 220, damping: 20}),
                            scale: spring(!this.state.tutorialChanged ? 1 : 0.9, {stiffness: 300, damping: 8})
                        }}
                >
                    {({ opacity, scale }) => {

                        return (
                            <div style={{
                                            transform: `scale3d(${scale}, ${scale}, 1)`,
                                            opacity,
                                            pointerEvents: showMain ? 'auto' : 'none',
                                            transformOrigin: 'bottom center'
                                        }}>
                                <div className={styles.svgs}>
                                    {this.state.currentIndex === -1 ? (
                                        <Icon
                                            key="inactiveWheel"
                                            glyph={spinWheelInactiveSvg}
                                            width={96}
                                            height={96}
                                            style={{
                                margin: '2rem'
                            }}
                                        />
                                    ) : (
                                        <Icon
                                            key="activeWheel"
                                            glyph={spinWheelSvg}
                                            width={96}
                                            height={96}
                                            style={{
                                margin: '2rem',
                                transform: `rotateZ(${this.state.currentAngle}deg)`
                            }}
                                        />
                                    )}
                                    <Icon glyph={maskSvg} width={100} height={100}/>
                                    <svg viewBox="0 0 100 100">
                                        { spinWheelTriangles.map((points, index) =>
                                            <a key={index}  onClick={() => props.onSelect(index)} onMouseEnter={() => this.hoverOnSpinWheel(index)}>
                                                <polygon points={points} style={{fill: 'transparent'}} />
                                            </a>
                                        )}
                                    </svg>
                                </div>
                                {props.domains.map((domain, index) => {
                                    let classNames = [
                                        styles[`domain${index}`],
                                        props.selectedDomainIndex === index ? styles.isSelected : '',
                                        index === this.state.currentIndex ? styles.isActive : ''
                                    ];

                                    return (
                                        <button
                                            key={index}
                                            className={classNames.join(' ')}
                                            onClick={() => props.onSelect(index)}
                                            onMouseEnter={() => this.hoverOnSpinWheel(index)}
                                        >{domain.domain}</button>
                                    );
                                })}
                            </div>

                        )
                    }}
                </Motion>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedDomainIndex: state.game.selectedDomainIndex,
    showTutorial: state.game.showTutorial === 1,
});

const mapDispatchToProps = dispatch => ({
    onSelect: (domainIndex: number) => {dispatch(selectDomain(domainIndex))}
});

export default connect(mapStateToProps, mapDispatchToProps)(DomainSelection);