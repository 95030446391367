// @flow

import React from 'react';
import styles from './Spinner.css';

type SpinnerProps = {
    className: string,
    color: string
};

const Spinner = (props: SpinnerProps) => {
    const className = props.className + ' ' + styles.spinner;
    const dynamicStyle = {
        backgroundColor: props.color
    };

    return (
        <div className={className}>
            <div className={styles.bounce1} style={dynamicStyle}></div>
            <div className={styles.bounce2} style={dynamicStyle}></div>
            <div className={styles.bounce3} style={dynamicStyle}></div>
        </div>
    );
};

Spinner.defaultProps = {
    className: '',
    color: '#fff'
};

export default Spinner;