// @flow

import React from 'react';
import type { ISource } from '../../types';
import styles from './Sources.css';
import { intersperse } from 'ramda';

type SourceProps = {
    sources: Array<ISource>
};

const Sources = ({ sources }: SourceProps) => (
    <div className={styles.sources}>
        {intersperse(' / ', sources.map((source, index) =>
            source.url
                ? <a key={index} target="_blank" href={source.url} className={styles.link}>{source.text}</a>
                : <span key={index}>{source.text}</span>
        ))}
    </div>
);

export default Sources;